import '../styles/pool.css';
import React, { Component } from 'react';
import { Modal,Button} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import bamboo from '../img/graphics/Bamboo3.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Gear } from 'react-bootstrap-icons';
import {TransactionButton} from '../functions/Trx'; 
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import assetIcon from '../img/icons/Avatar.png';
import honeyIcon from '../img/icons/HoneyIcon.png';


class Pool extends Component {
    
    constructor(props){
        super(props)
        this.state = { 
            assets: [],
            filteredAssets: [],
            filteredLPAssets: [],
            selectedAsset: "Select Asset",
            selectedAssetAddress: "",
            selectedAssetPrice: 0,
            selectedAssetBalance: 0,

            selectedLPToken: "Select Asset",

            selectedLPTokenBalance: 0,
            estimatedOutput1: 0,
            estimatedOutput2: 0,    

            chooseAssetModalOpen: false,
            USDCBalance:0,
            showAddLiquidyPart: true,
            showRemoveLiquidityPart: false,
            style1: "selectButtonSelected",
            style2: "selectButtonUnselected",
            approvedUSDCAmount: 0,
            approvedTokenAmount: 0,
            approvalButtonLPTokenVisible: false,
            approvalButtonUSDCVisible: false,
            approvalButtonTokenVisible: false,
            addLiquidityButtonVisible: false,
            removeLiquidityButtonVisible: false,
            buttonMessage: "Select an asset",
            errorButtonVisible: true,
            expectedOutputVisible: false,

        }
        
    }

    async componentDidMount() {
        this.setState({
            //assets: ['wait'],
            USDCBalance: this.props.USDCBalance,
            //INTAddress: this.props.GovernanceToken_Address,
        });
        let locationUnSplit = this.props.location['pathname']
        if (typeof(locationUnSplit) !== 'undefined'){
            console.log(typeof(locationUnSplit))
            let locationSplit = locationUnSplit.split("/")
            let buyOrSellVisible = locationSplit[2]
            let pairAddress = locationSplit[3]
            if (buyOrSellVisible === 'remove'){
                this.showRemoveLiquidity()
            }
            this.initiatiatePage(pairAddress,buyOrSellVisible)
        }
        
    };

    onlyNumberKey(e) {
        const re = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({value: e.target.value})
      }
    }

    initiatiatePage = async(pairAddress,buyOrSellVisible) =>{
        console.log(pairAddress)
        try{
            let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pairAddress)
            let token0 = await MarketPair.methods.token0().call();
            let token1 = await MarketPair.methods.token1().call();
            let TokenContract0 = new this.props.web3.eth.Contract(this.props.ERC20_ABI,token0)
            let tokenSymbol0 = await TokenContract0.methods.symbol().call()
            let TokenContract1 = new this.props.web3.eth.Contract(this.props.ERC20_ABI,token1)
            let tokenSymbol1 = await TokenContract1.methods.symbol().call()
            let selectedAsset
            let assetAddress
            if (tokenSymbol0 === 'USDC'){
                selectedAsset = tokenSymbol1
                assetAddress = token1
            }
            else{
                selectedAsset = tokenSymbol0
                assetAddress = token0
            }
            console.log(selectedAsset)
            if (buyOrSellVisible === 'remove'){
                this.selectLPInitial(selectedAsset, assetAddress, pairAddress)
            }
            else{
                this.selectAssetInitial(selectedAsset, assetAddress, pairAddress)
            }
            }
        catch(err){
            console.log(err.message)
            return
        }
        
    }

    loadAssets=async()=>{
        let assets = [];
        let assetBalances = {};
        let assetAddresses = {};
        console.log(this.props)

        assets.push(["TWIN",this.props.GovernanceTokenBalance,"TWIN Protocol Token",this.props.pools[0][4]]);
        assetBalances["TWIN"]= this.props.GovernanceTokenBalance;
        assetAddresses["TWIN"] = this.props.GovernanceToken_Address;
        console.log("DEBUG")
        console.log(this.props.assetDetails)
        for (let key in this.props.assetDetails) {
            console.log(key)
            console.log(this.props.assetDetails[key])
            assets.push([key,this.props.assetDetails[key]['tokenBalance1'],this.props.assetDetails[key]['name'],this.props.assetDetails[key]['poolBalanceLong']])
            assetBalances[key] = this.props.assetDetails[key]['tokenBalance1']
            assets.push(["i"+key,this.props.assetDetails[key]['tokenBalance2'],"short".concat(this.props.assetDetails[key]['name']),this.props.assetDetails[key]['poolBalanceLong']])
            assetBalances["i"+key] = this.props.assetDetails[key]['tokenBalance2']

            assetAddresses[key] = this.props.assetDetails[key]['Token1']
            assetAddresses["i"+key] = this.props.assetDetails[key]['Token2']
        }
        await this.setState({assets:assets})
        let filteredAssets =[];
        for (let i = 0; i < this.state.assets.length; ++i) {
            console.log(this.state.assets[i])
            if (this.state.assets[i][1] >0){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        this.setState({filteredAssets:filteredAssets})
        this.setState({assetBalances:assetBalances})
        this.setState({assetAddresses})
        console.log(this.state.assets)   
    };


    openChooseAssetModal=async()=>{
        this.setState({ chooseAssetModalOpen: true }) 
        this.loadAssets() 
    };
    convertBigInt = (number) => {
        const regularNumber = parseFloat(number);
        // Round the regular number to an integer
        const integerValue = Math.round(regularNumber);
        const bigIntValue = this.props.web3.utils.toBigInt(integerValue)
        return bigIntValue;
    }
    
    closeChooseAssetModal = () => this.setState({ chooseAssetModalOpen: false });

    openSettingsModal = () => this.setState({settingsModalOpen: true})
    closeSettingsModal = () => this.setState({settingsModalOpen: false})
    checkSlippageInput = () => {
        console.log("Checking slippage input")
        try{
            let slippage = document.getElementById('slippage').value * 100
            console.log(slippage)
            if (isNaN(slippage)) {
                this.setState({wrongSlippageInputMessage: true})        
                return false
            }
            if (slippage < 0 || slippage > 5000) {
                this.setState({wrongSlippageInputMessage: true})
                this.setState({highSlippageInputMessage: false})         
                return false
            }
            if (slippage > 500 && slippage < 5000) {
                this.setState({wrongSlippageInputMessage: false})
                this.setState({highSlippageInputMessage: true})        
                return true
            }
            this.setState({wrongSlippageInputMessage: false})
            this.setState({highSlippageInputMessage: false})  
            return (true)
            }
            catch{
                return (false)
            }
    }

    saveSettingsSlippage=async()=>{
        let check = this.checkSlippageInput()
        if (check) {
            let slippage = document.getElementById('slippage').value * 100
            await this.props.saveSlippagePreference(slippage)
                       
        }
    }

    checkTrxTimeInput = () => {
        console.log("Checking trx time input")
        try{
            let trxTime = document.getElementById('trxTime').value
            console.log(trxTime)
            if (isNaN(trxTime)) {
                this.setState({wrongTrxTimeInputMessage: true})        
                return false
            }
            if (trxTime <0 || trxTime > 600) {
                this.setState({wrongTrxTimeInputMessage: true})    
                return true
            }
            this.setState({wrongTrxTimeInputMessage: false})
            return (true)
            }
            catch{
                return (false)
            }
    }

    

    saveSettingsTrxTime=async()=>{
        let check = this.checkTrxTimeInput()
        console.log(check)
        if (check) {
            let trxTime = document.getElementById('trxTime').value
            await this.props.saveTrxTimePreference(trxTime)
            
        }
    }

    openChooseAssetModalAdd=async()=>{
        let assets = [];
        let assetBalances = {};
        let assetAddresses = {};
        if (this.props.GovernanceTokenBalance>0.0001){
            assets.push(["TWIN",this.props.GovernanceTokenBalance,"TWIN Protocol Token",this.props.pools[0][4]]);
            assetBalances["TWIN"]= this.props.GovernanceTokenBalance;
            assetAddresses["TWIN"] = this.props.GovernanceToken_Address;
        }
        
        for (let key in this.props.assetDetails) {
            console.log(key)
            if (this.props.assetDetails[key]['tokenBalance1'] >0.001){
                assets.push([key,this.props.assetDetails[key]['tokenBalance1'],this.props.assetDetails[key]['name'],this.props.assetDetails[key]['poolBalanceLong']])
                assetBalances[key] = this.props.assetDetails[key]['tokenBalance1']
                console.log(this.props.assetDetails[key]['tokenBalance1'])
            }
            if (this.props.assetDetails[key]['tokenBalance2'] >0.001){
                assets.push(["i"+key,this.props.assetDetails[key]['tokenBalance2'],"short".concat(this.props.assetDetails[key]['name']),this.props.assetDetails[key]['poolBalanceLong']])
                assetBalances["i"+key] = this.props.assetDetails[key]['tokenBalance2']
            }
            assetAddresses[key] = this.props.assetDetails[key]['Token1']
            assetAddresses["i"+key] = this.props.assetDetails[key]['Token2']
        }
        

        await this.setState({assets:assets})
        let filteredAssets =[];
        for (let i = 0; i < this.state.assets.length; ++i) {
            console.log(this.state.assets[i])
            if (this.state.assets[i][1] >0){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        this.setState({filteredAssets:filteredAssets})
        this.setState({assetBalances:assetBalances})
        this.setState({assetAddresses})
        this.setState({ chooseAssetModalOpen: true })  
        console.log(this.state.assets)   
    };


    

    filterAssets(){
        let availableAssets = []
        for (let i = 0; i < this.state.assets.length; ++i) {
            console.log(this.state.assets[i])
            if (this.state.assets[i][1] >0){
                availableAssets.push(this.state.assets[i])
            }
            
        }

        let filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < availableAssets.length; ++i) {
            if (availableAssets[i][2].toLowerCase().includes(searchTerm) || availableAssets[i][0].toLowerCase().includes(searchTerm)){
                filteredAssets.push(availableAssets[i])
            }
            
        }
        this.setState({filteredAssets})

    }

    filterLPAssets(){
        let availableAssets = []
        for (let i = 0; i < this.state.assets.length; ++i) {
            console.log(this.state.assets[i])
            if (this.state.assets[i][1] >0){
                availableAssets.push(this.state.assets[i])
            }
        }

        let filteredLPAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < availableAssets.length; ++i) {
            if (availableAssets[i][2].toLowerCase().includes(searchTerm) || availableAssets[i][0].toLowerCase().includes(searchTerm)){
                filteredLPAssets.push(availableAssets[i])
            }
            
        }
        this.setState({filteredLPAssets})

    }

    roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }



    openChooseLPTokenModal= async()=>{
        let assets = [];
        let assetBalances = {};
        let assetAddresses = {};
        console.log("Debug")
        console.log(this.props.pools)
        console.log(assets)
        console.log(this.props.assetDetails)
        for (let i = 0; i < this.props.pools.length; ++i) {
            let _ticker = this.props.pools[i][0]
            let _balance
            try {_balance = this.props.assetDetails[_ticker]['tokenBalance1']}
            catch{
                try{_balance=this.props.assetDetails[_ticker.slice(1)]['tokenBalance2']}
                catch{_balance = this.props.GovernanceTokenBalance}
            }
            let _name = this.props.pools[i][3]
            let _lpBalance = this.props.pools[i][4]
            assets.push([_ticker,_balance,_name,_lpBalance])
            assetBalances[_ticker] = _balance
            assetAddresses[_ticker] = this.props.pools[i][11]
        }
        
        await this.setState({assets:assets})
        console.log(this.state.asset)
        
        let filteredAssets =[];
        for (let i = 0; i < this.state.assets.length; ++i) {
            console.log(this.state.assets[i])
            if (this.state.assets[i][3] >9999){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        
        this.setState({filteredLPAssets:filteredAssets})
        this.setState({assetBalances:assetBalances})
        this.setState({assetAddresses})
        this.setState({chooseLPTokenModalOpen: true })  
        console.log(this.state.assets)   
    };

    closeChooseLPTokenModal = () => this.setState({ chooseLPTokenModalOpen: false });

    listLPTokens() {
        if (this.state.filteredLPAssets.length === 0) {
            return (<div className="row"><div className="col p-4">You currently own no LP tokens.</div></div>)
        }

        let assetOptions = this.state.filteredLPAssets.map((element,index) =>
                <div key={index} className="assetSelectList"  role="button" onClick={()=>this.selectLPToken(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {this.props.outputNumber(element[1],4)}</div>
                        <div className="col text-right">LP Balance: {this.props.outputNumber(Number(element[3])/(10**18),6)}</div>
                    </div>

                </div>
        );
        return(assetOptions)
    }
    
    listAssets() {
        if (this.state.filteredAssets.length === 0) {
            return (<div className="row"><div className="col p-4">You currently own no assets.</div></div>)
        }
        let assetOptions = this.state.filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAsset(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {this.props.outputNumber(element[1],4)}</div>
                        <div className="col text-right">LP Balance: {element[3] > 0 ? this.props.outputNumber(Number(element[3])/(10**18),8):this.props.outputNumber(0,8)}</div>
                    </div>
                </div>
        );
        return(assetOptions)
    }
    
    checkAllowances = async(_tokenAddress) =>{
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,_tokenAddress)
        let allowanceToken = await tokenContract.methods.allowance(this.props.address, this.props.MarketRouter_Address).call()
        console.log(allowanceToken)
        console.log(parseInt(allowanceToken))
        this.setState({allowanceToken: parseInt(allowanceToken)})
        
        let USDCContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,this.props.USDC_Address)
        let allowanceUSDC = await USDCContract.methods.allowance(this.props.address, this.props.MarketRouter_Address).call()
        this.setState({allowanceUSDC: parseInt(allowanceUSDC)})
    }

    checkAllowancesLP = async(_tokenAddress) =>{
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,_tokenAddress)
        let allowanceToken = await tokenContract.methods.allowance(this.props.address, this.props.MarketRouter_Address).call()
        this.setState({allowanceLPToken: parseInt(allowanceToken)})
    }
    selectAsset = async(asset) =>{
        document.getElementById('tokenAmountA').value = 0;
        document.getElementById('tokenAmountB').value = 0;
        console.log(asset)
        await this.setState({"selectedAsset":asset});
        console.log(this.state.assetAddresses)
        await this.setState({"selectedAssetAddress":this.state.assetAddresses[asset]})
        // Get the allowance amounts
        await this.checkAllowances(this.state.assetAddresses[asset])

        console.log(this.state.selectedAssetAddress)
        console.log(this.props.USDC_Address)
        await this.setState({"selectedAssetBalance": this.state.assetBalances[asset]});
        console.log(this.state)
        
        
        this.closeChooseAssetModal();
        console.log(this.state.selectedAssetAddress)
        console.log(this.props.USDC_Address)
        
        try {
            // THIS part only when the pair exists
            let pair = await this.props.MarketFactory.methods.getPair(this.state.selectedAssetAddress,this.props.USDC_Address).call()
            console.log(pair)
            let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair)
            let token0 = await MarketPair.methods.token0().call();
            console.log(token0)
            this.setState({token0})

            let balanceWEI = await MarketPair.methods.balanceOf(this.props.address).call()
            var balance = parseFloat(this.props.web3.utils.fromWei(balanceWEI.toString(), 'ether'))
            console.log(balance)
            await this.setState({"selectedLPTokenBalance": balance})
            console.log(this.state.selectedLPTokenBalance)
            
            let reserves = await MarketPair.methods.getReserves().call()
            console.log(reserves)
            let price
            if (this.state.token0 === this.props.USDC_Address) {
                price = parseInt(reserves[0])/parseInt(reserves[1])
            }
            else{
                price = parseInt(reserves[1])/parseInt(reserves[0])
            }
            this.setState({selectedAssetPrice:price})
            console.log(price)
        }
        catch{
            this.setState({selectedAssetPrice:0})
            console.log("Pair does not yet exist")
        }

        //check if the Approval button needs to be shown for token 1
        let approvalGivenToken = await this.checkApproval(this.state.selectedAssetAddress, this.props.MarketRouter_Address)
        console.log(approvalGivenToken)
        if (approvalGivenToken === true) {
            this.setState({"approvalButtonTokenVisible":false})

        }
        else {
            this.setState({"approvalButtonTokenVisible":true})
        };

        //check if the Approval button needs to be shown for USDC
        let approvalGivenUSDC = await this.checkApproval(this.props.USDC_Address, this.props.MarketRouter_Address)
        console.log(approvalGivenUSDC)
        if (approvalGivenUSDC === true) {
            this.setState({"approvalButtonUSDCVisible":false})
        }
        else{
            this.setState({"approvalButtonUSDCVisible":true})
        };
        
        //check if the Add liquidity button is shown
        if (approvalGivenUSDC === true && approvalGivenToken === true){
            this.setState({"addLiquidityButtonVisible": true})
        }
        else {
            this.setState({
                "addLiquidityButtonVisible": false,
                "buttonMessage": "Waiting for approval..."
            })
        };
        this.checkButtons();
        
        
    }

    selectAssetInitial = async(asset, assetAddress,pair) =>{
        await this.setState({"selectedAsset":asset});
        await this.setState({"selectedAssetAddress":assetAddress})
        // Get the allowance amounts
        await this.checkAllowances(assetAddress)

        // get the balance
        let tokenContract = await new this.props.web3.eth.Contract(this.props.ERC20_ABI,assetAddress)
        let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(this.props.address).call()
        let selectedAssetBalance = parseInt(selectedAssetBalanceRaw)/1e18
        console.log(selectedAssetBalance)
        await this.setState({selectedAssetBalance});
        
        try {
            // THIS part only when the pair exists
            let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair)
            let token0 = await MarketPair.methods.token0().call();
            console.log(token0)
            this.setState({token0})

            let balanceWEI = await MarketPair.methods.balanceOf(this.props.address).call()
            var balance = parseFloat(this.props.web3.utils.fromWei(balanceWEI.toString(), 'ether'))
            console.log(balance)
            await this.setState({"selectedLPTokenBalance": balance})
            console.log(this.state.selectedLPTokenBalance)
            
            let reserves = await MarketPair.methods.getReserves().call()
            console.log(reserves)
            let price
            if (this.state.token0 === this.props.USDC_Address) {
                price = parseInt(reserves[0])/parseInt(reserves[1])
            }
            else{
                price = parseInt(reserves[1])/parseInt(reserves[0])
            }
            this.setState({selectedAssetPrice:price})
            console.log(price)
        }
        catch{
            this.setState({selectedAssetPrice:0})
            console.log("Pair does not yet exist")
        }

        //check if the Approval button needs to be shown for token 1
        let approvalGivenToken = await this.checkApproval(this.state.selectedAssetAddress, this.props.MarketRouter_Address)
        console.log(approvalGivenToken)
        if (approvalGivenToken === true) {
            this.setState({"approvalButtonTokenVisible":false})

        }
        else {
            this.setState({"approvalButtonTokenVisible":true})
        };

        //check if the Approval button needs to be shown for USDC
        let approvalGivenUSDC = await this.checkApproval(this.props.USDC_Address, this.props.MarketRouter_Address)
        console.log(approvalGivenUSDC)
        if (approvalGivenUSDC === true) {
            this.setState({"approvalButtonUSDCVisible":false})
        }
        else{
            this.setState({"approvalButtonUSDCVisible":true})
        };
        
        //check if the Add liquidity button is shown
        if (approvalGivenUSDC === true && approvalGivenToken === true){
            this.setState({"addLiquidityButtonVisible": true})
        }
        else {
            this.setState({
                "addLiquidityButtonVisible": false,
                "buttonMessage": "Waiting for approval..."
            })
        };
        this.checkButtons();
        
        
    }

    selectLPToken = async(asset) =>{
        console.log(asset)
        document.getElementById('LPTokenAmount').value = 0
        await this.setState({"selectedLPToken":asset});
        await this.setState({"selectedAsset":asset});
        await this.setState({"selectedAssetAddress":this.state.assetAddresses[asset]})
        console.log(this.state.assetAddresses)
        await this.setState({"selectedLPTokenAddress":this.state.assetAddresses[asset]})
        this.checkAllowancesLP(this.state.assetAddresses[asset])
        let pair = await this.props.MarketFactory.methods.getPair(this.state.selectedLPTokenAddress,this.props.USDC_Address).call()
        await this.setState({"selectedLPPairAddress": pair});
        let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair)
        let token0 = await MarketPair.methods.token0().call();
        console.log(token0)
        this.setState({token0})

        let balanceWEI = await MarketPair.methods.balanceOf(this.props.address).call()
        console.log(balanceWEI)
        var balance = parseFloat(this.props.web3.utils.fromWei(balanceWEI.toString(), 'ether'))
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call()
        let reserves = await MarketPair.methods.getReserves().call()
        console.log(reserves[0])
        console.log(totalSupplyWEI)
        let token1Ratio =  parseInt(reserves[0]) / parseInt(totalSupplyWEI)
        let token2Ratio = parseInt(reserves[1]) /parseInt(totalSupplyWEI)
        this.setState({token1Ratio});
        this.setState({token2Ratio});
        console.log(balance)
        console.log(token1Ratio)
        console.log(token2Ratio)
    
        await this.setState({"selectedLPTokenBalance": balance})
        console.log(this.state.selectedLPTokenBalance)
        await this.setState({"selectedAssetBalance": this.state.assetBalances[asset]});
        
        console.log(this.state)
        console.log(pair)
        
        let r = await MarketPair.methods.getReserves().call()
        let price
        if (token0 === this.props.USDC_Address){
            price = r[0]/r[1]
        }
        else {
            price = r[1]/r[0]
        }
        this.setState({selectedAssetPrice:price})

        console.log(r)

        //check if the Approval button needs to be shown
        let approvalGiven = await this.checkApproval(pair, this.props.MarketRouter_Address)
        console.log(approvalGiven)
        if (approvalGiven === true) {
            this.setState({
                "approvalButtonLPTokenVisible":false,
                "removeLiquidityButtonVisible": true,
            })
            
        }
        else {
            this.setState({
                "approvalButtonLPTokenVisible":true,
                "removeLiquidityButtonVisible": false,
                "buttonMessage": "Waiting for approval..."
            })

        };
        this.calculateTokenOutput();
        this.closeChooseLPTokenModal();
    };


    selectLPInitial = async(asset, assetAddress,pair) =>{
        await this.setState({"selectedLPToken":asset});
        await this.setState({"selectedAsset":asset});
        await this.setState({"selectedLPTokenAddress":assetAddress})
        this.checkAllowancesLP(assetAddress)
        await this.setState({"selectedLPPairAddress": pair});
        let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair)
        let token0 = await MarketPair.methods.token0().call();
        console.log(token0)
        this.setState({token0})

        let balanceWEI = await MarketPair.methods.balanceOf(this.props.address).call()
        console.log(balanceWEI)
        var balance = parseFloat(this.props.web3.utils.fromWei(balanceWEI.toString(), 'ether'))
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call()
        let reserves = await MarketPair.methods.getReserves().call()
        console.log(reserves)
        let token1Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[0])
        let token2Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[1])
        this.setState({token1Ratio});
        this.setState({token2Ratio});
        console.log(balance)
        console.log(token1Ratio)
    
        await this.setState({"selectedLPTokenBalance": balance})
        console.log(this.state.selectedLPTokenBalance)
        // get the balance
        let tokenContract = await new this.props.web3.eth.Contract(this.props.ERC20_ABI,assetAddress)
        let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(this.props.address).call()
        let selectedAssetBalance = parseInt(selectedAssetBalanceRaw)/1e18
        console.log(selectedAssetBalance)
        await this.setState({selectedAssetBalance});
        
        console.log(this.state)
        console.log(pair)
        
        let r = await MarketPair.methods.getReserves().call()
        let price
        if (token0 === this.props.USDC_Address){
            price = r[0]/r[1]
        }
        else {
            price = r[1]/r[0]
        }
        this.setState({selectedAssetPrice:price})

        console.log(r)

        //check if the Approval button needs to be shown
        let approvalGiven = await this.checkApproval(pair, this.props.MarketRouter_Address)
        console.log(approvalGiven)
        if (approvalGiven === true) {
            this.setState({
                "approvalButtonLPTokenVisible":false,
                "removeLiquidityButtonVisible": true,
            })
            
        }
        else {
            this.setState({
                "approvalButtonLPTokenVisible":true,
                "removeLiquidityButtonVisible": false,
                "buttonMessage": "Waiting for approval..."
            })

        };
        this.calculateTokenOutput();
        this.closeChooseLPTokenModal();
    };

    onSuccessApproveToken = async() =>{
        await this.setState({approvalButtonTokenVisible: false})
        await this.props.sleep(500)
        await this.checkAllowances(this.state.selectedAssetAddress)
        await this.checkAllowances(this.props.USDC_Address)
        await this.checkButtons()
    }

    onSuccessApproveLP = async() =>{
        await this.checkAllowancesLP(this.state.selectedLPPairAddress)
        this.checkButtons()
    }

    checkButtons = async() => {
        if (this.state.showAddLiquidyPart) {
            console.log(document.getElementById('tokenAmountA').value)
            if (document.getElementById('tokenAmountA').value === ''){
                console.log("DEBUG")
                return
            }
            let tokenVolume = parseFloat(document.getElementById('tokenAmountA').value)
            console.log(tokenVolume)
            if (Number.isNaN(tokenVolume)){tokenVolume = 0};
            let USDVolume = parseFloat(document.getElementById('tokenAmountB').value)
            if (Number.isNaN(USDVolume)){USDVolume = 0};
            console.log(this.state.approvalButtonTokenVisible)
            if (Number.isNaN(tokenVolume) === true || 
                Number.isNaN(USDVolume) === true ||
                tokenVolume === 0 ||
                USDVolume === 0){
                console.log(tokenVolume)
                this.setState({
                    errorButtonVisible:true,
                    "buttonMessage": "Enter valid amount"
                })
            }
            else if (this.state.selectedAssetBalance < tokenVolume) {
                this.setState({
                    errorButtonVisible:true,
                    "buttonMessage": "Balance too low"
                })
            }
            else if (this.props.USDCBalance < USDVolume) {
                console.log(USDVolume * 10**this.props.USDDecimals)
                console.log(this.props.USDCBalance)
                this.setState({
                    errorButtonVisible:true,
                    "buttonMessage": this.props.stableCoinName+" Balance too low"
                })
            }

            else {
                this.setState({
                    errorButtonVisible:false,                
                })
            };
        }
        else {
            var LPTokenAmount = parseFloat(document.getElementById('LPTokenAmount').value)
            if (Number.isNaN(LPTokenAmount)){LPTokenAmount = 0};
            console.log(LPTokenAmount)
            console.log(this.state.selectedLPTokenBalance)
            
            if (this.state.selectedLPTokenBalance < LPTokenAmount) {
                this.setState({
                    errorButtonVisible:true,
                    "buttonMessage": "Balance too low"
                })
            }
            else if(Number.isNaN(LPTokenAmount) === true ||
                LPTokenAmount === 0){
                this.setState({
                    errorButtonVisible:true,
                    "buttonMessage": "Enter valid amount"
                })
            }
            else {
                this.setState({
                    "errorButtonVisible": false,                
                })
            };

        }
    }

    calculateUSDVolume = async() =>{
        if (this.state.selectedAssetAddress === ''){return} // If no asset is selected
        if (document.getElementById('tokenAmountA').value === ''){
            document.getElementById('tokenAmountB').value = ''
            return
        } //If the token amount is empty
        if (document.getElementById('tokenAmountA').value === 0){return} // If the token amount is zero
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('tokenAmountA').value);
        if (isPositiveNumber === false){
            if(Number.isNaN(this.state.tokenAmountA) || typeof(this.state.tokenAmountA) == 'undefined'){
                document.getElementById('tokenAmountA').value = ''
            }
            else{
                console.log(this.state.tokenAmountA)
                document.getElementById('tokenAmountA').value = this.state.tokenAmountA
            }
            return
        }

        this.checkAllowances(this.state.selectedAssetAddress)
        let tokenVolume = parseFloat(document.getElementById('tokenAmountA').value)
        if (tokenVolume < 0){
            tokenVolume = this.state.tokenAmountA
            document.getElementById('tokenAmountA').value = tokenVolume
        }

        let USDCVolume
        if (this.state.selectedAssetAddress === ''){
            return
        }
        console.log(this.state.selectedAssetPrice)
        if(Number.isNaN(this.state.selectedAssetPrice) !== true & this.state.selectedAssetPrice !== 0){
            this.setState({expectedOutputVisible: true})
            if (parseInt(this.props.USDDecimals) === 6){
                USDCVolume = parseFloat(tokenVolume * parseFloat(this.state.selectedAssetPrice)*(10**12)).toFixed(6)
            }
            else{
                USDCVolume = parseFloat(tokenVolume * parseFloat(this.state.selectedAssetPrice)).toFixed(13)
            }
            
            document.getElementById('tokenAmountB').value = USDCVolume
        }
        else if( Number.isNaN(this.state.selectedAssetPrice) || this.state.selectedAssetPrice === 0){
            USDCVolume = document.getElementById('tokenAmountB').value
        }
        
        this.setState({"tokenAmountA":tokenVolume})
        this.setState({"tokenAmountB":USDCVolume})
        this.checkButtons()
        this.calculateAddLiquidity()

    }

    calculateTokenVolume = async() =>{
        if (this.state.selectedAssetAddress === ''){return} // If no asset is selected
        if (document.getElementById('tokenAmountB').value === ''){
            document.getElementById('tokenAmountA').value = ''
            return
        } //If the token amount is empty
        if (document.getElementById('tokenAmountB').value === 0){return} // If the token amount is zero
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('tokenAmountB').value);
        if (isPositiveNumber === false){
            if(Number.isNaN(this.state.tokenAmountB) || typeof(this.state.tokenAmountB) == 'undefined'){
                document.getElementById('tokenAmountB').value = ''
            }
            else{
                console.log(this.state.tokenAmountA)
                document.getElementById('tokenAmountB').value = this.state.tokenAmountB
            }
            return
        }


        
        this.checkAllowances(this.state.selectedAssetAddress)
        let USDCVolume = parseFloat(document.getElementById('tokenAmountB').value)
        console.log(USDCVolume)
        let TokenVolume
        if(Number.isNaN(this.state.selectedAssetPrice) !== true & this.state.selectedAssetPrice !== 0){
            TokenVolume = USDCVolume / this.state.selectedAssetPrice / (10**(18-this.props.USDDecimals))
            document.getElementById('tokenAmountA').value = TokenVolume
        }
        else if( Number.isNaN(this.state.selectedAssetPrice) || this.state.selectedAssetPrice === 0){
            TokenVolume = document.getElementById('tokenAmountA').value
        }
        else(
            console.log("DEBUG")
        )
        this.setState({"tokenAmountA":TokenVolume})
        this.setState({"tokenAmountB":USDCVolume})
        this.checkButtons()
        console.log(this.state.tokenAmountA)
        console.log(this.state.allowanceToken)
        this.calculateAddLiquidity()
    }
    
    calculateTokenOutput = async() =>{
        if (this.state.selectedLPPairAddress === ''){return} // If no asset is selected
        if (document.getElementById('LPTokenAmount').value === ''){
            return
        } //If the token amount is empty
        if (document.getElementById('LPTokenAmount').value === 0){return} // If the token amount is zero
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('LPTokenAmount').value);
        if (isPositiveNumber === false){
            if(Number.isNaN(this.state.selectedLPTokenAmount) || typeof(this.state.selectedLPTokenAmount) == 'undefined'){
                document.getElementById('LPTokenAmount').value = ''
            }
            else{
                console.log(this.state.tokenAmountA)
                document.getElementById('LPTokenAmount').value = this.state.selectedLPTokenAmount
            }
            return
        }
        
        
        this.checkAllowancesLP(this.state.selectedLPPairAddress)
        let LPTokenAmount = parseFloat(document.getElementById('LPTokenAmount').value)
        await this.setState({"selectedLPTokenAmount":LPTokenAmount})
        console.log(this.state.token0)
        console.log(this.props.USDC_Address)
        let tokenVolume
        let USDCVolume

        if (this.state.token0 === this.props.USDC_Address) {
            tokenVolume = LPTokenAmount * this.state.token2Ratio
            USDCVolume = LPTokenAmount * this.state.token1Ratio *(10**(18-this.props.USDDecimals))
        } 
        else {
            tokenVolume = LPTokenAmount * this.state.token1Ratio
            USDCVolume = LPTokenAmount * this.state.token2Ratio *(10**(18-this.props.USDDecimals))
        }
        console.log(tokenVolume)
        console.log(USDCVolume)
        await this.setState({estimatedOutput1: tokenVolume});
        await this.setState({estimatedOutput2: USDCVolume});
        if (Number.isNaN(USDCVolume) || Number.isNaN(tokenVolume)){
            this.setState({expectedOutputVisible: false})
        }
        else {
            this.setState({expectedOutputVisible: true})
        }
        this.checkButtons();
        this.calculateRemoveLiquidity()
    }

    onSuccessAddLiquidity = async () =>{
        let selectedAssetAddressOld = this.state.selectedAssetAddress

        // RESET THE VIEW    
        await this.setState({selectedAsset: "Select Asset"})
        await this.setState({selectedAssetBalance: 0})
        await this.setState({selectedAssetPrice:0})
        await this.setState({errorButtonVisible: true})
        await this.setState({buttonMessage: "Select an asset"})
        await this.setState({selectedAssetAddress: ''})
        document.getElementById('tokenAmountA').value = 0
        document.getElementById('tokenAmountB').value = 0

        // LOAD Balances etc
        await this.props.updateAssetBalanceWithAddress(selectedAssetAddressOld);
        console.log("Balance updated")
        await this.props.loadUSDBalance()
        await this.props.updatePortfolioValue()
        console.log("Portfolio value updated")
        console.log(selectedAssetAddressOld)
        await this.props.updateLPPair(selectedAssetAddressOld)
        console.log("LP pair updated")
        
    }
    
    calculateAddLiquidity = async () =>{
        let tokenAmtA = parseFloat(document.getElementById('tokenAmountA').value).toFixed(15)
        let tokenAmtB = parseFloat(document.getElementById('tokenAmountB').value).toFixed(15)
        let amountADesiredRaw = tokenAmtA * 1e18
        let amountBDesiredRaw = tokenAmtB * (10**parseFloat(this.props.USDDecimals)-1)
        let amountADesired = this.convertBigInt(amountADesiredRaw)
        let amountBDesired = this.convertBigInt(amountBDesiredRaw)
        let amountAMinRaw = amountADesiredRaw * (10000 - parseInt(this.props.slippage)) / 10000
        let amountBMinRaw = amountBDesiredRaw * (10000 - parseInt(this.props.slippage)) / 10000
        let amountAMin = this.convertBigInt(amountAMinRaw)
        let amountBMin = this.convertBigInt(amountBMinRaw)
        this.setState({amountADesired})
        this.setState({amountBDesired})
        this.setState({amountAMin})
        this.setState({amountBMin})
        let deadline = Math.round(+new Date()/1000) + this.props.trxTime * 60
        this.setState({deadline})
    }

    calculateRemoveLiquidity = async () =>{        
        let liquidityToRemove = parseInt(document.getElementById('LPTokenAmount').value * 10**18)
        console.log(liquidityToRemove)
        this.setState({liquidityToRemove})
        let amount1Raw
        let amount2Raw
        if (parseInt(this.props.USDDecimals) === 6){
            amount2Raw = this.roundDown(this.state.estimatedOutput2,6) * 10**6
        }
        else {
            console.log("debug")
            console.log(this.state.estimatedOutput2)
            console.log(this.roundDown(this.state.estimatedOutput2,6))
            amount2Raw = this.roundDown(this.state.estimatedOutput2,6) * 10**18
        }
        console.log(amount2Raw)
        amount1Raw = parseFloat(this.state.estimatedOutput1).toFixed(11) * 10**18
        let amount1 = this.props.web3.utils.toBigInt(parseInt(amount1Raw))
        let amount2 = this.props.web3.utils.toBigInt(parseInt(amount2Raw))
        console.log(amount1)
        console.log(amount2)
        let amountAMin = parseInt(amount1 * (this.props.web3.utils.toBigInt(10000-this.props.slippage)) / (this.props.web3.utils.toBigInt(10000)))
        let amountBMin = parseInt(amount2 * (this.props.web3.utils.toBigInt(10000-this.props.slippage)) / (this.props.web3.utils.toBigInt(10000)))
        let deadline = Math.round(+new Date()/1000) + this.props.trxTime * 60
        
        this.setState({amountAMin})
        this.setState({amountBMin})
        this.setState({deadline})
    }
    onSuccessRemoveLiquidity = async () =>{
        let selectedAssetAddressOld = this.state.selectedAssetAddress
        console.log(selectedAssetAddressOld)
        await this.setState({"selectedLPTokenBalance": 0})
        await this.setState({"selectedLPToken": "Select Asset"})
        await this.setState({"selectedLPAddress": ""})
        await this.setState({"expectedOutputVisible": false})

        await this.setState({errorButtonVisible: true})
        await this.setState({buttonMessage: "Select an asset"})
        await this.setState({selectedLPPairAddress: ''})
        document.getElementById('LPTokenAmount').value = 0

        // LOAD Balances etc
        await this.props.updateAssetBalanceWithAddress(selectedAssetAddressOld);
        console.log("Balance updated")
        await this.props.loadUSDBalance()
        await this.props.updateLPPair(selectedAssetAddressOld)
        await this.props.updatePortfolioValue()
        console.log("Portfolio value updated")
        console.log("LP pair updated")
        
    }

    showRemoveLiquidity = async() =>{
        this.setState({selectedLPToken: "Select Asset"})
        this.setState({selectedAssetAddress: ''})
        this.setState({expectedOutputVisible:false})
        await this.setState({selectedLPPairAddress: ''})
        await this.setState({errorButtonVisible: true})
        this.setState({buttonMessage:"Select an asset"});
        this.setState({showAddLiquidyPart: false});
        this.setState({showRemoveLiquidyPart: true});
        this.setState({approvalButtonLPTokenVisible: false});
        this.setState({style2: "selectButtonSelected"});
        this.setState({style1: "selectButtonUnselected"});
        await this.setState({"selectedLPTokenBalance": 0})

    }

    showAddLiquidity = async() =>{
        this.setState({selectedAsset: "Select Asset"})
        this.setState({selectedAssetAddress: ''})
        this.setState({showAddLiquidyPart: true});
        this.setState({selectedLPToken:"Select Asset"});
        this.setState({buttonMessage:"Select an asset"});
        this.setState({showRemoveLiquidyPart: false});
        this.setState({style2: "selectButtonUnselected"});
        this.setState({style1: "selectButtonSelected"});


    }

    setMaxBalanceToken = async() =>{
        document.getElementById('tokenAmountA').value = this.roundDown(this.state.selectedAssetBalance,14).toFixed(14).replace(/\.?0+$/,"");
        this.calculateUSDVolume()
    }
    setMaxBalanceUSD = async() =>{
        document.getElementById('tokenAmountB').value = this.roundDown(this.props.USDCBalance,6);
        this.calculateTokenVolume()
    }
    setMaxBalanceLPToken = async() =>{
        document.getElementById('LPTokenAmount').value = this.roundDown(this.state.selectedLPTokenBalance,14).toFixed(14).replace(/\.?0+$/,"");
        this.calculateTokenOutput()
    }

    setPercentOfBalanceLPToken = async(_percentage) =>{
        document.getElementById('LPTokenAmount').value = this.roundDown(this.state.selectedLPTokenBalance*_percentage/100,14).toFixed(14).replace(/\.?0+$/,"");
        this.calculateTokenOutput()
    }

    setPercentOfAsset = async(_percentage) =>{
        document.getElementById('tokenAmountA').value = this.roundDown(this.state.selectedAssetBalance*_percentage/100,14).toFixed(14).replace(/\.?0+$/,"");
        this.calculateUSDVolume()
    }


    // STILL needed?
    checkApproval = async(tokenAddress, approvalAddress) =>{  
        console.log(approvalAddress)
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,tokenAddress)
        var amountRaw = "100000000000000000000000000000"
        var amount = this.props.web3.utils.toBigInt(amountRaw)
        console.log(this.props.address)
        console.log(approvalAddress)
        let allowance = await tokenContract.methods.allowance(this.props.address, approvalAddress).call()

        if (parseInt(allowance) < parseInt(amount)){
            return(false)
        }
        else {return (true)}
    };

    render() { 
        
        const tooltip1 = props => (
            <Tooltip {...props}>
            Each TWIN Asset has it´s own pool, so there are seperate pools for each TWIN long and each TWIN short Asset. For providing liquidity of long and short TWIN Asset (pair) you need to provide liquidity (TWIN Asset tokens and equal amount of {this.props.stableCoinName} stable coin) seperately in the corresponding pools.
            </Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
            You can always remove liquidity (TWIN Assets and {this.props.stableCoinName} stable coins) you provided earlier in the TWIN pools. Please note that the number of your liquidity provider tokens is shown in the balance (as also in your LP tokens portfolio section) and that it is this number (or part of this amount) which needs to be input in the Pool tokens field to remove liquidity.
            </Tooltip>
        );
        
        return ( 
            <div className="mainContainer">
                <Modal show={this.state.chooseAssetModalOpen} onHide={this.closeChooseAssetModal}>
                    <Modal.Header className="" closeButton>
                        <Modal.Title>Select a token</Modal.Title>   
                    </Modal.Header>
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                            
                        </div>
                        <div className="list-group border-nav">
                            {this.listAssets()}
                        </div>
                    </Modal.Body>
                        
                    
                </Modal>

                <Modal show={this.state.settingsModalOpen} onHide={this.closeSettingsModal}>
                    <Modal.Header className="border" closeButton>
                        <Modal.Title>Choose your preferences</Modal.Title>   
                    </Modal.Header>
                    <Modal.Body className="bg-tgrey" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="form-row align-items-center mx-3">
                            <div className="col">Slippage tolerance</div>
                            <div className="col col-3 bg-light">
                                <input className="px-2 form-control input-sm pull-right" type="decimals" id="slippage" onChange={this.saveSettingsSlippage} placeholder={this.props.slippage/100}></input>
                            </div>
                            <div className="col col-2">%</div>
                        </div>
                        {this.state.wrongSlippageInputMessage
                            ?
                            <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid slippage percentage</div>
                            :
                            ''
                        }
                        
                        {this.state.highSlippageInputMessage
                            ?
                            <div className="row pl-3 pt-3 w-100 text-danger ">Warning. Your transaction may be frontrun</div>
                            :
                            ''
                        }
                        
                        <div>&nbsp;</div>

                        <div className="form-row align-items-center mx-3">
                            <div className="col">Max transaction time</div>
                            <div className="col col-3 bg-light">
                                <input className="px-2 form-control input-sm pull-right" type="decimals" id="trxTime" onChange={this.saveSettingsTrxTime} placeholder={this.props.trxTime}></input>
                            </div>
                            <div className="col col-2">minutes</div>
                        </div>
                        {this.state.wrongTrxTimeInputMessage
                            ?
                            <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid transaction time</div>
                            :
                            ''
                        }                           
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.chooseLPTokenModalOpen} onHide={this.closeChooseLPTokenModal}>
                    <Modal.Header className="" closeButton>
                        <Modal.Title>Select a liquidity pool</Modal.Title>   
                    </Modal.Header>
                    
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                            
                        </div>
                        <div className="list-group">
                            {this.listLPTokens()}
                        </div>
                    </Modal.Body>
                    
                </Modal>

                <div className="middleBoxSmall">
                    
                        <div className="mainBox">
                            <div className="selectButtonRow">
                                <div  className={this.state.style1} onClick={this.showAddLiquidity} role="button">
                                    <b>Add liquidity</b>
                                </div>
                                <div  className={this.state.style2} onClick={this.showRemoveLiquidity} role="button">
                                    <b>Remove liquidity</b>
                                </div>
                            </div>
                            <div className="selectSettingsRow">
                                <Gear className="h4" role="button" onClick={this.openSettingsModal}/>
                                
                            </div>

                                {this.state.showAddLiquidyPart 
                                ?
                                    <div className="">
                                        <div className="subBox">
                                            <div className="selectAmountRow">
                                                {this.state.selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfAsset(25)} role="button" >25%</div>:''}
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                {this.state.selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfAsset(50)} role="button" >50%</div>:''}
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                {this.state.selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfAsset(75)} role="button" >75%</div>:''}
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                {this.state.selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfAsset(100)} role="button" >100%</div>:''}
                                            </div>
                                            <div className="tradingBoxRow">
                                                <div className="">
                                                    <input id="tokenAmountA" onChange={() =>this.calculateUSDVolume()} className="inputCustom" type="text" lang="en" placeholder="0.00"/>
                                                </div>
                                                <div className="">
                                                <div role="button" className="selectAssetButton" onClick={this.openChooseAssetModal}>
                                                    <div className='assetBoxIcon'>
                                                        <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                    </div>
                                                    <div>{this.state.selectedAsset} <KeyboardArrowDownOutlinedIcon />   </div>
                                                    </div>
                                                </div>

                                            </div> 
                                            <div className='balanceRow'>
                                                <div onClick={this.setMaxBalanceToken} role="button" className="col align-self-end text-lg-right textBalance">
                                                    {this.state.selectedAsset !== "Select Asset" ? (
                                                        <span>
                                                        Balance: {this.state.selectedAssetBalance > 0.0001 ? (
                                                            <span>{this.props.outputNumber(this.state.selectedAssetBalance, 4)}</span>
                                                        ) : (
                                                            <span>&lt; 0.0001</span>
                                                        )}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>      
                                        </div>
                                        <div>
                                        <div className="tradingBoxMiddleRow">
                                            <ControlPointOutlinedIcon />
                                        </div>
                                        </div>
                                        

                                        <div className="subBox">
                                            <div className="tradingBoxRow">
                                                <input id="tokenAmountB" onChange={() =>this.calculateTokenVolume()} className="inputCustom" type="text" lang="en" placeholder="0.00"/>
                                                <div className='stablecoinAssetBox'>
                                                    <div className='assetBoxIcon'>
                                                        <img className="" src={honeyIcon} alt="honey" width="24px"/>
                                                    </div>
                                                    {this.props.stableCoinName}
                                                </div>
                                            </div>
                                            <div className='balanceRow'>
                                                <div onClick={this.setMaxBalanceUSD} role="button" className="col align-self-end text-lg-right textBalance">Balance: {this.props.outputNumber(this.props.USDCBalance,2)} (Max)</div>
                                            </div>
                                        </div>
                                        <div className="row"></div>                                
                                        <div className="row">
                                            
                                                {this.state.tokenAmountA * 1e18 >= this.state.allowanceToken
                                                ?
                                                <div className="w-100 px-3 py-1">
                                                    <TransactionButton
                                                        contractAddress={this.state.selectedAssetAddress} 
                                                        abi = {this.props.ERC20_ABI}
                                                        functionName= 'approve'
                                                        args =  {[this.props.MarketRouter_Address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                        text="Approve Token"
                                                        onSuccess={this.onSuccessApproveToken}
                                                    />
                                                </div>    
                                                :
                                                    ''
                                                }
                                                {this.state.tokenAmountB * 1e18 >= this.state.allowanceUSDC
                                                ?
                                                <div className="w-100 px-3 py-1">
                                                    <TransactionButton
                                                        contractAddress={this.props.USDC_Address} 
                                                        abi = {this.props.ERC20_ABI}
                                                        functionName= 'approve'
                                                        args =  {[this.props.MarketRouter_Address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                        text={`Approve ${this.props.stableCoinName}`}
                                                        onSuccess={this.onSuccessApproveUSDC}
                                                    />
                                                </div>
                                                :
                                                    ''
                                                }
                                                
                                                {this.state.errorButtonVisible
                                                    ?
                                                    <div className="w-100 px-3 py-1">
                                                        <div className="w-100 issuaaButtonDeactivated" >{this.state.buttonMessage}</div>
                                                    </div>
                                                    :
                                                    ''                                                        
                                                }    

                                                {this.state.tokenAmountA * 1e18 < this.state.allowanceToken &&  this.state.tokenAmountB * 1e6 < this.state.allowanceUSDC && this.state.errorButtonVisible === false
                                                    ?
                                                    <div className="w-100 px-3 py-1">
                                                        <TransactionButton
                                                            abi={this.props.MarketRouter_ABI} 
                                                            contractAddress={this.props.MarketRouter_Address} 
                                                            functionName="addLiquidity" 
                                                            args={[this.state.selectedAssetAddress, this.props.USDC_Address, this.state.amountADesired, this.state.amountBDesired,this.state.amountAMin,this.state.amountBMin, this.props.address, this.state.deadline] } // Example, adjust as needed
                                                            text="Add liquidity"
                                                            onSuccess = {this.onSuccessAddLiquidity}
                                                        />
                                                        
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                
                                            
                                        </div>
                                    </div>
                                    : ''
                                }
                                {this.state.showRemoveLiquidyPart 
                                    ?
                                    <div>
                                        <div className="subBox">
                                            <div className="selectAmountRow">
                                                {this.state.selectedLPToken !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfBalanceLPToken(25)} role="button" >25%</div>:''}
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                {this.state.selectedLPToken !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfBalanceLPToken(50)} role="button" >50%</div>:''}
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                {this.state.selectedLPToken !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfBalanceLPToken(75)} role="button" >75%</div>:''}
                                                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                {this.state.selectedLPToken !== "Select Asset" ? <div className="percentBox" onClick={() =>this.setPercentOfBalanceLPToken(100)} role="button" >100%</div>:''}
                                                
                                            </div>
                                            <div className="tradingBoxRow">
                                                <input id="LPTokenAmount" onChange={() =>this.calculateTokenOutput()} className="inputCustom" type="text" lang="en" placeholder="0.00"/>
                                                
                                                <div role="button" className="selectAssetButton" onClick={this.openChooseLPTokenModal}>
                                                    <div className='assetBoxIcon'>
                                                        <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                    </div>
                                                    <div>{this.state.selectedLPToken} <KeyboardArrowDownOutlinedIcon />  </div>
                                                </div>
                                                
                                            </div>
                                            <div className='balanceRow'>
                                                <div onClick={this.setMaxBalanceLPToken} role="button" className="col   align-self-end text-lg-right textBalance">
                                                    {this.state.selectedLPToken !== "Select Asset" ? (
                                                        <span>
                                                        Balance: {this.state.selectedLPTokenBalance > 0.001 ? (
                                                            <span>{this.props.outputNumber(this.state.selectedLPTokenBalance, 8)}</span>
                                                        ) : (
                                                            <span>&lt; 0.001</span>
                                                        )} (Max)
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-12">&nbsp;</div>                                      

                                            <div className="row"></div>  
                                        </div>
                                                                        
                                        <div className="row"></div>                                
                                        <div className="row">
                                            
                                            {this.state.selectedLPTokenAmount > this.state.allowanceLPToken
                                            ?
                                            <div className="w-100 px-3 py-1 pb-3">
                                                <TransactionButton
                                                    contractAddress={this.state.selectedLPPairAddress} 
                                                    abi = {this.props.ERC20_ABI}
                                                    functionName= 'approve'
                                                    args =  {[this.props.MarketRouter_Address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                    text="Approve"
                                                    onSuccess={this.onSuccessApproveLP}
                                                />
                                            </div>
                                            :
                                                ''
                                            }
                                            
                                            {this.state.errorButtonVisible 
                                                ?
                                                <div className="w-100 px-3 py-1 pb-3">
                                                        <div className="issuaaButtonDeactivated" >{this.state.buttonMessage}</div>
                                                </div>
                                                :''
                                            }
                                            {this.state.errorButtonVisible === false & this.state.selectedLPTokenAmount < this.state.allowanceLPToken
                                                ?
                                                <div className="w-100 px-3 py-1 pb-3">
                                                <TransactionButton
                                                        abi={this.props.MarketRouter_ABI} // Adjust this to where you're storing ABI
                                                        contractAddress={this.props.MarketRouter_Address} 
                                                        functionName="removeLiquidity" 
                                                        args={[this.state.selectedLPTokenAddress, this.props.USDC_Address, this.state.liquidityToRemove, this.state.amountAMin,this.state.amountBMin, this.props.address, this.state.deadline] } // Example, adjust as needed
                                                        text="Remove liquidity"
                                                        onSuccess = {this.onSuccessRemoveLiquidity}
                                                    />
                                                </div>
                                                :
                                                ''
                                            }
                                            
                                        </div>

                                        {this.state.expectedOutputVisible
                                            ?
                                            <div className="subBox">
                                                <div>
                                                    Estimated Output:
                                                </div>
                                                <div>
                                                    {this.props.outputNumber(this.state.estimatedOutput1,4)} {this.state.selectedLPToken} + {this.props.outputNumber(this.state.estimatedOutput2,2)} {this.props.stableCoinName}.
                                                </div>
                                            </div>
                                            :
                                                ''
                                            }  
                                    </div>
                                    : ''
                                }


                           
                            
                                
                        </div>
                    
                    
                </div>
                
                        
                        
            </div>
        );
    }
}
 
export default Pool;