import '../styles/burnAssets.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {TransactionButton} from '../functions/Trx'; 
import assetIcon from '../img/icons/Avatar.png';

class BurnAssets extends Component {
    
    constructor(props){
        super(props)
        this.state = { 
            assets: ['wait'],
            maxBurnableAmount: this.props.maxBurnableAmount,
            
            burnAmount: 0,
            amountToGet:0,
            tokenToBurn: '',
            burnAmountLong: 0,
            burnAmountShort: 0,
            amountToGetExpired:0,
            expiredTokenToBurn: '',
            style1: "selectButtonSelected",
            style2: "selectButtonUnselected",
            chooseAssetModalOpen: false,
            chooseExpiredAssetModalOpen: false,
            selectedAsset: "Select Asset",
            selectedAssetExpired: "Select Asset",
            approvalButtonVisible1:false,
            approvalButtonVisible2:false,
            submitButtonVisible: false,
            burnableBalanceVisible: false,
            burnableBalanceToken1Visible: false,
            burnableBalanceToken2Visible: false,
            burnableBalanceToken1: 0,
            burnableBalanceToken2: 0,
            submitButtonDeactivated: false,
            submitButtonDeactivatedMessage: "",
            filteredAssetBurnOptions: [],
            assetBurnOptions: [],
            filteredAssetBurnOptionsExpired : [],
            AssetBurnOptionsExpired : [],
            
        }
        this.changeBurnableAmount = this.changeBurnableAmount.bind(this)

    }

    async componentDidMount() {
        let maxBurnableAmountLong;
        try {maxBurnableAmountLong = this.props.burnableExpiredAssets[0][1]}
        catch {maxBurnableAmountLong = 0}


            this.setState({
            assets: ['wait'],
            maxBurnableAmount: this.props.maxBurnableAmount,
            maxBurnableAmountLong: maxBurnableAmountLong,
            view: 'live',

        });
        
    };

    switchView = async() =>{
        if (this.state.view === "expired") 
            {this.setState ({view:'live'})} 
        else 
            {this.setState ({view:'expired'})}
    };

    selectLiveView = async() =>{
        this.setState ({view:'live'});
        this.setState({style1: "selectButtonSelected"});
        this.setState({style2: "selectButtonUnselected"});
    }
    selectExpiredView = async() =>{
        this.setState ({view:'expired'});
        this.setState({style2: "selectButtonSelected"});
        this.setState({style1: "selectButtonUnselected"});
    }

    openModal = async () => {
        console.log(this.props)
        
        let amountToBurn = document.getElementById('amountToBurn').value
        this.setState({burnAmount:amountToBurn})
        let AssetDetails  = await this.props.assetFactory.methods.getAsset(this.state.selectedAsset).call()
        let upperLimit = parseFloat(AssetDetails.upperLimit)/1000
        console.log(upperLimit)
        let amountToGet = upperLimit * amountToBurn
        this.setState({amountToGet:amountToGet})
        this.setState({ modalOpen: true })
        
        
    };
    closeModal = () => this.setState({ modalOpen: false });


   openModalBurnExpiredAssets = async () => {
        console.log(this.props)
        let selectedAsset = this.state.selectedAssetExpired
        this.setState({tokenToBurn:selectedAsset})
        let amountToBurnLong = parseFloat(document.getElementById('amountToBurnToken1').value)
        let amountToBurnShort = parseFloat(document.getElementById('amountToBurnToken2').value)
        this.setState({amountToBurnLong:amountToBurnLong})
        this.setState({amountToBurnShort:amountToBurnShort})
        let AssetDetails  = await this.props.assetFactory.methods.getAsset(selectedAsset).call()
        console.log(AssetDetails)
        let upperLimit = parseInt(AssetDetails.upperLimit)/1000
        let expiryValue = parseInt(AssetDetails.endOfLifeValue)/1000
        console.log(expiryValue)
        let amountToGetExpired = expiryValue * amountToBurnLong + (upperLimit - expiryValue)*amountToBurnShort
        this.setState({amountToGetExpired:amountToGetExpired})
        this.setState({ modalBurnExpiredAssetsOpen: true })
        
    };

    closeModalBurnExpiredAssets = () => this.setState({ modalBurnExpiredAssetsOpen: false });

    handleChange(field,value){
        this.setState({[field]:value});
        let maxBurnableAmount = this.getMaxBurnAmount()
        this.setState({maxBurnableAmount});
        console.log(this.state)
    }

    handleChangeExpired(field,value){
        this.setState({[field]:value});
        let maxBurnableAmountLong = this.getMaxBurnAmountLong()
        let maxBurnableAmountShort = this.getMaxBurnAmountShort()
        this.setState({maxBurnableAmountLong});
        this.setState({maxBurnableAmountShort});
        console.log(this.state)
    }
    
    changeBurnableAmount(event) {
        console.log("Click")
        console.log(event)
    }


    roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }
    

    getMaxBurnAmount(){
        let selectedAsset = "";
        let maxBurnableAmount = 0;
        if (document.getElementById('selectAsset') != null){
            selectedAsset = document.getElementById('selectAsset').value
        }

        if (typeof(this.props.burnableAssets)!='undefined'){
            this.props.burnableAssets.forEach(function(element,index){
                if (element[0] === selectedAsset) {maxBurnableAmount=element[1]}
            }
            )
        }
        let stateCopy = JSON.parse(JSON.stringify(this.state))
        stateCopy[maxBurnableAmount] = maxBurnableAmount
        
        return maxBurnableAmount
    };

    getMaxBurnAmountLong(){
        let selectedAsset = "";
        let maxBurnableAmountLong = 0;
        if (document.getElementById('selectAssetExpired') != null){
            selectedAsset = document.getElementById('selectAssetExpired').value
        }

        if (typeof(this.props.burnableExpiredAssets)!='undefined'){
            this.props.burnableExpiredAssets.forEach(function(element,index){
                if (element[0] === selectedAsset) {maxBurnableAmountLong=element[1]}
            }
            )
        }
        let stateCopy = JSON.parse(JSON.stringify(this.state))
        stateCopy[maxBurnableAmountLong] = maxBurnableAmountLong
        
        return maxBurnableAmountLong
    };
    getMaxBurnAmountShort(){
        let selectedAsset = "";
        let maxBurnableAmountShort = 0;
        if (document.getElementById('selectAssetExpired') != null){
            selectedAsset = document.getElementById('selectAssetExpired').value
        }

        if (typeof(this.props.burnableExpiredAssets)!='undefined'){
            this.props.burnableExpiredAssets.forEach(function(element,index){
                if (element[0] === selectedAsset) {maxBurnableAmountShort=element[2]}
            }
            )
        }
        let stateCopy = JSON.parse(JSON.stringify(this.state))
        stateCopy[maxBurnableAmountShort] = maxBurnableAmountShort
        
        return maxBurnableAmountShort
    };
    
    

    onSuccessApprove = async(tokenAddress,approvalAddress) =>{
        if (this.state.view === 'live'){
            this.checkInputLive();
        }
        else {
            this.checkInputExpired();
        }
    }

    setMaxAmount = async() =>{
        let amount = parseInt(this.state.selectedAssetBurnableBalance) / 1e18;
        console.log(amount);
        amount = this.roundDown(amount,14);
        document.getElementById('amountToBurn').value = amount;
        console.log(amount);
        this.checkInputLive();
        return
    }
    setMaxAmountToken1 = async() =>{
        let amount = parseInt(this.state.burnableBalanceToken1) / 1e18;
        amount = this.roundDown(amount,14);
        //console.log(amount)
        document.getElementById('amountToBurnToken1').value = amount
        return
    }
    setMaxAmountToken2 = async() =>{
        let amount = parseInt(this.state.burnableBalanceToken2) / 1e18;
        amount = this.roundDown(amount,14);
        
        document.getElementById('amountToBurnToken2').value = amount
        return
    }

    onSuccessBurn = async() => {
        let _selectedAsset = this.state.selectedAsset
        this.setState({ modalOpen: false })
        document.getElementById('amountToBurn').value = 0
        this.setState({selectedAsset: "Select Asset"})
        this.setState({selectedAssetBurnableBalance:0})

        await this.props.loadUSDBalance();
        await this.props.updateAssetBalance(_selectedAsset);
    }      
    
    openChooseAssetModal=async()=>{
        let assets = [];

        for (let key in this.props.assetDetails) {
            if (this.props.assetDetails[key]['frozen'] === false) {
                let burnableBalance = Math.min(this.props.assetDetails[key]['tokenBalance1'],this.props.assetDetails[key]['tokenBalance2'])
                if (this.props.assetDetails[key].expiryTime > Date.now()/1000){
                    assets.push([key,burnableBalance,this.props.assetDetails[key]['name'],this.props.assetDetails[key]['upperLimit']])
                }
            }
            else{
                let burnableBalance = this.props.assetDetails[key]['tokenBalance1']
                assets.push([key,burnableBalance,this.props.assetDetails[key]['name'],this.props.assetDetails[key]['upperLimit']])
            }
        }

        await this.setState({ chooseAssetModalOpen: true })
        await this.setState({assetBurnOptions:assets})
        this.filterAssets();
        
        


        
    };

    closeChooseAssetModal=()=>{
        this.setState({ chooseAssetModalOpen: false })
    }  

    openChooseExpiredAssetModal=async()=>{
        let assets = [];
        for (let key in this.props.assetDetails) {
            let burnableBalance1 = this.props.assetDetails[key]['tokenBalance1']
            let burnableBalance2 = this.props.assetDetails[key]['tokenBalance2']
            if (this.props.assetDetails[key].expiryTime < Date.now()/1000){
                assets.push([key,burnableBalance1,burnableBalance2,this.props.assetDetails[key]['name'],this.props.assetDetails[key]['upperLimit']])
            }
        }
        await this.setState({ chooseExpiredAssetModalOpen: true })
        await this.setState({assetBurnOptionsExpired:assets})
        this.filterAssetsExpired()
        
        
    };

    closeChooseExpiredAssetModal=()=>{
        this.setState({ chooseExpiredAssetModalOpen: false })
    }  

    checkInputLive = async() =>{
        let amt = document.getElementById('amountToBurn').value*1e18;
        var amountRaw = amt
        var amount = this.props.web3.utils.toBigInt(amountRaw)
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,this.state.token1Address)
        let tokenContract2 = new this.props.web3.eth.Contract(this.props.ERC20_ABI,this.state.token2Address)
        let allowance = await tokenContract.methods.allowance(this.props.address, this.props.assetFactory._address).call()
        let allowance2 = await tokenContract2.methods.allowance(this.props.address, this.props.assetFactory._address).call()
        this.setState({allowance:parseInt(allowance)})
        this.setState({allowance2:parseInt(allowance2)})
        this.setState({burnAmount:parseInt(amount)})
       
        
        if (amt > this.state.selectedAssetBurnableBalance) {
            this.setState({
                submitButtonDeactivated: true,
                submitButtonDeactivatedMessage: "Balance too low",
            });
        }
        else if (this.state.allowanceButtonVisible1 || this.state.approvalButtonVisible2){
            this.setState({
                submitButtonDeactivated: true,
                submitButtonDeactivatedMessage: "Waiting for approval",
            });
        }
        else if (amt === 0){
            this.setState({
                submitButtonDeactivated: true,
                submitButtonDeactivatedMessage: "Select an amount greater than 0",
            });
        }

        else {
            this.setState({
                submitButtonVisible: true,
                submitButtonDeactivated: false,
                
            });
        }
    }

    checkInputExpired = async() =>{
        let amt1 = document.getElementById('amountToBurnToken1').value*1e18;
        let amt2 = document.getElementById('amountToBurnToken2').value*1e18;

        var amount1 = this.props.web3.utils.toBigInt(amt1)
        var amount2 = this.props.web3.utils.toBigInt(amt2)
        console.log(this.state)
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,this.state.token1Address)
        let tokenContract2 = new this.props.web3.eth.Contract(this.props.ERC20_ABI,this.state.token2Address)
        let allowance = await tokenContract.methods.allowance(this.props.address, this.props.assetFactory._address).call()
        let allowance2 = await tokenContract2.methods.allowance(this.props.address, this.props.assetFactory._address).call()
        this.setState({allowance:parseInt(allowance)})
        this.setState({allowance2:parseInt(allowance2)})
        this.setState({burnAmount1:parseInt(amount1)})
        this.setState({burnAmount2:parseInt(amount2)})


        if (amt1 > this.state.burnableBalanceToken1 || amt2 > this.state.burnableBalanceToken2) {
            this.setState({
                submitButtonVisible: false,
                submitButtonDeactivated: true,
                submitButtonDeactivatedMessage: "Balance too low",
            });
        }
        else if (this.state.allowanceButtonVisible1 || this.state.allowanceButtonVisible2){
            this.setState({
                submitButtonVisible: false,
                submitButtonDeactivated: true,
                submitButtonDeactivatedMessage: "Waiting for approval",
            });
        }

        else {
            this.setState({
                submitButtonVisible: true,
                submitButtonDeactivated: false,
                
            });
        }
    }

      

    listBurnableAssets() {
        
        let burnableAssets = this.state.filteredAssetBurnOptions.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAssetLive(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Burnable balance: {this.props.outputNumber(element[1],4)}</div>
                        <div className="col text-right">UL: {this.props.outputNumber(Number(element[3])/1000,0)}</div>
                    </div>
                </div>
        );
        return(burnableAssets)
    }


    filterAssets(){
        let filteredAssets =[];
        let searchTerm ='_';
        try{searchTerm = document.getElementById('search').value.toLowerCase()} catch {searchTerm ='_'}

        for (let i = 0; i < this.state.assetBurnOptions.length; ++i){
            console.log(this.state.assetBurnOptions[i])
            if ((this.state.assetBurnOptions[i][2].toLowerCase().includes(searchTerm) || this.state.assetBurnOptions[i][0].toLowerCase().includes(searchTerm))&&this.state.assetBurnOptions[i][1]>0.0000001){
                filteredAssets.push(this.state.assetBurnOptions[i])
            }
            
        }
        this.setState({filteredAssetBurnOptions:filteredAssets})

    }
    filterAssetsExpired(){
        let filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < this.state.assetBurnOptionsExpired.length; ++i) {
            if ((this.state.assetBurnOptionsExpired[i][3].toLowerCase().includes(searchTerm) || this.state.assetBurnOptionsExpired[i][0].toLowerCase().includes(searchTerm))&&this.state.assetBurnOptionsExpired[i][1]>0.0000001){
                filteredAssets.push(this.state.assetBurnOptionsExpired[i])
            }
            
        }
        this.setState({filteredAssetBurnOptionsExpired:filteredAssets})

    }

    selectAssetLive = async(asset) =>{
        console.log(asset)
        document.getElementById('amountToBurn').value = 0
        await this.setState({"selectedAsset":asset});
        await this.setState({"tokenToBurn":asset});
        let token1Address = this.props.assetDetails[this.state.selectedAsset][0]
        let token2Address = this.props.assetDetails[this.state.selectedAsset][1]
        this.setState({token1Address})
        this.setState({token2Address})
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,token1Address)
        let balanceToken1 = await tokenContract.methods.balanceOf(this.props.address).call()
        await this.setState({selectedAssetToken1Balance: balanceToken1})
        console.log(balanceToken1)
        let allowance = await tokenContract.methods.allowance(this.props.address, this.props.assetFactory._address).call()
        this.setState({allowance:allowance})
        tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,token2Address)
        let balanceToken2 = await tokenContract.methods.balanceOf(this.props.address).call()
        await this.setState({selectedAssetToken2Balance: balanceToken2})
        if ((parseInt(balanceToken1) <= parseInt(balanceToken2)) || this.props.assetDetails[this.state.selectedAsset]['frozen'] === true){
            await this.setState({selectedAssetBurnableBalance:parseInt(balanceToken1)})
        }
        else {
            await this.setState({selectedAssetBurnableBalance:parseInt(balanceToken2)})
            console.log(this.props.assetDetails[this.state.selectedAsset]['frozen'])
        }
        this.setState({burnableBalanceVisible:true})
        allowance = await tokenContract.methods.allowance(this.props.address, this.props.assetFactory._address).call()
        this.setState({allowance2:allowance})
        
        await this.checkInputLive()
        this.closeChooseAssetModal();
    };


    listBurnableExpiredAssets() {
        console.log(this.state.filteredAssetBurnOptionsExpired)
        let burnableAssets = this.state.filteredAssetBurnOptionsExpired.map((element,index) =>
            <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAssetExpired(element[0])}>
               
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[3]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Burnable balance: {this.props.outputNumber(element[1],7)}(long) / {this.props.outputNumber(element[2],7)}(short)</div>
                        <div className="col text-right">UL: {this.props.outputNumber(element[4]/1000,0)}</div>
                    </div>
            </div>
        );
        return(burnableAssets)
    }

    

    selectAssetExpired = async(asset) =>{
        console.log(asset)
        await this.setState({"selectedAssetExpired":asset});
        let token1Address = this.props.assetDetails[this.state.selectedAssetExpired][0]
        let token2Address = this.props.assetDetails[this.state.selectedAssetExpired][1]
        let tokenContract1 = new this.props.web3.eth.Contract(this.props.ERC20_ABI,token1Address)
        let tokenContract2 = new this.props.web3.eth.Contract(this.props.ERC20_ABI,token2Address)
        let balanceToken1 = await tokenContract1.methods.balanceOf(this.props.address).call()
        let balanceToken2 = await tokenContract2.methods.balanceOf(this.props.address).call()
        await this.setState({burnableBalanceToken1: balanceToken1})
        await this.setState({burnableBalanceToken1Visible: true})
        await this.setState({burnableBalanceToken2: balanceToken2})
        await this.setState({burnableBalanceToken2Visible: true})
        await this.setState({token1Address})
        await this.setState({token2Address})
        console.log(balanceToken1)
        console.log(balanceToken2)
        await this.props.sleep(50) 
        await this.checkInputExpired()
        this.closeChooseExpiredAssetModal();
    }

    approvalButton(){
        let approvalButton = <div></div>;
        if (this.state.USDTAllowance === 0){
            approvalButton = <div><Button variant="accent" onClick={this.approveAccount}>Approve</Button></div>;
        };
        return(approvalButton)   
    }

    render() { 
        
        const tooltip1 = props => (
            <Tooltip {...props}>
            You can "burn" TWIN Asset Tokens and redeem those against {this.props.stableCoinName} stable coins at any time, if you provide an equal amount of long amd short tokens (from your liquid portfolio positions). The amount of {this.props.stableCoinName} stable coins you will receive is the (equal) amount of long and short tokens burned multiplied by the upper limit of the respective TWIN Asset minus a 2% early redemption fee.</Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
            When an asset is expired, you can burn your long and short token balance also after the expiry date and redeem {this.props.stableCoinName} stable coins. The amount of {this.props.stableCoinName} stable coins you will receive ist the amount of long tokens multiplied with the asset price at expiry time plus the amount of short tokens multiplied with the difference of the upper limit of the asset and the asset price at expiry.</Tooltip>
        );
        
        return ( 
            <div className="mainContainer">
                <Modal show={this.state.chooseAssetModalOpen} onHide={this.closeChooseAssetModal}>
                    <Modal.Header className="" closeButton>
                        <Modal.Title>Select TWIN Asset pair to burn</Modal.Title> 
                    
                    </Modal.Header>
                    
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                            
                        </div>
                        <div className="list-group">
                            {this.listBurnableAssets()}
                        </div>
                    </Modal.Body>
                    
                </Modal>

                <Modal show={this.state.chooseExpiredAssetModalOpen} onHide={this.closeChooseExpiredAssetModal}>
                    <Modal.Header className="" closeButton>
                        <Modal.Title>Select TWIN Asset pair to burn</Modal.Title>   
                    </Modal.Header>
                    
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                            
                        </div>
                        <ul className="list-group">
                            {this.listBurnableExpiredAssets()}
                        </ul>
                    </Modal.Body>
                    
                </Modal>


                <Modal show={this.state.modalOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Burn assets and redeem {this.props.stableCoinName}:</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>You will burn {this.state.burnAmount} {this.state.tokenToBurn} and i{this.state.tokenToBurn} token.</Modal.Body>
                        <Modal.Body>You will receive {this.props.outputNumber(this.state.amountToGet*0.98,2)} {this.props.stableCoinName}</Modal.Body>
                    <Modal.Footer>
                    <TransactionButton
                        abi={this.props.assetFactory_ABI} // Adjust this to where you're storing ABI
                        contractAddress={this.props.assetFactory_Address} 
                        functionName="burnAssets" 
                        args={[this.state.tokenToBurn, this.state.burnAmount*1e18] } // Example, adjust as needed
                        text="Burn"
                        onSuccess={this.onSuccessBurn}
                    />
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalBurnExpiredAssetsOpen} onHide={this.closeModalBurnExpiredAssets}>
                    <Modal.Header closeButton>
                        <Modal.Title>Burn assets and redeem {this.props.stableCoinName}:</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>You will burn {this.state.amountToBurnLong} {this.state.tokenToBurn} and {this.state.amountToBurnShort} i{this.state.tokenToBurn} token.</Modal.Body>
                        <Modal.Body>You will receive {this.props.outputNumber(this.state.amountToGetExpired,2)} {this.props.stableCoinName}</Modal.Body>
                    <Modal.Footer>
                        <div>
                        <TransactionButton
                            abi={this.props.assetFactory_ABI} // Adjust this to where you're storing ABI
                            contractAddress={this.props.assetFactory_Address} 
                            functionName="burnAssets" 
                            args={[this.state.tokenToBurn, this.state.burnAmount*1e18] } // Example, adjust as needed
                            text="Burn"
                            onSuccess={this.onSuccessBurn}
                        />
                        </div>
                    </Modal.Footer>
                </Modal>

                <div className="middleBoxSmall">
                        <div className="mainBox">
                            <div className="selectButtonRow">
                                <div className={this.state.style1} role="button" onClick={this.selectLiveView}>
                                    Live&nbsp;
                                    <OverlayTrigger placement="right" overlay={tooltip1}>
                                        <InfoOutlinedIcon />
                                    </OverlayTrigger>
                                </div>
                                <div className={this.state.style2} role="button" onClick={this.selectExpiredView}>
                                    Expired&nbsp;
                                    <OverlayTrigger placement="right" overlay={tooltip2}>
                                        <InfoOutlinedIcon />
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="redeemHeadline">
                                Select a TWIN asset pair to redeem
                            </div>
                            
                                {
                                this.state.view === "live" ? 
                                        <div className="subBox">
                                                <div className="col align-self-end text-lg-right">
                                                    {this.state.burnableBalanceVisible ? 
                                                        <div role="button">Balance available: <span className="tradeBalance textBalance" onClick={this.setMaxAmount}>{this.props.outputNumber(this.state.selectedAssetBurnableBalance/1e18,8)} (Max)</span></div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                            <div className="tradingBoxRow">
                                                <div className="">
                                                    <input placeholder="0.00" className="inputCustom" type='text' id='amountToBurn' onChange={() =>this.checkInputLive()} />
                                                </div>
                        
                                                <div className="selectAssetButton" type="button" onClick={this.openChooseAssetModal}>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                </div>
                                                <div>{this.state.selectedAsset} <KeyboardArrowDownOutlinedIcon />   </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col align-self-start py-4">
                                                    {this.state.burnAmount > this.state.allowance && typeof(this.state.selectedAsset) !== 'undefined' ? 
                                                        <div>
                                                            <TransactionButton
                                                                contractAddress={this.props.assetDetails[this.state.selectedAsset][0]} 
                                                                abi = {this.props.ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[this.props.assetFactory._address,this.props.web3.utils.toBigInt(2**255)]} 
                                                                text='Approve Long Token'
                                                                onSuccess={this.onSuccessApprove}
                                                            />
                                                            </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {this.state.burnAmount > this.state.allowance2 && typeof(this.state.selectedAsset) !== 'undefined' ?  
                                                        <div>
                                                            <TransactionButton
                                                                contractAddress={this.props.assetDetails[this.state.selectedAsset][1]} 
                                                                abi = {this.props.ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[this.props.assetFactory._address,this.props.web3.utils.toBigInt(2**255)]} 
                                                                text='Approve Short Token'
                                                                onSuccess={this.onSuccessApprove}
                                                            />
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {this.state.submitButtonDeactivated && this.state.burnAmount < this.state.allowance2 && this.state.burnAmount < this.state.allowance2? 
                                                        <div className="issuaaButtonDeactivated">{this.state.submitButtonDeactivatedMessage}</div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {this.state.burnAmount < this.state.allowance2 && this.state.burnAmount < this.state.allowance2 && this.state.submitButtonDeactivated === false ? 
                                                        <div role="button" className="issuaaButton" onClick={this.openModal}>Submit</div>
                                                        :
                                                        <div></div>
                                                    }
                        
                                                </div>    
                                            </div> 
                                            
                                        </div>
                                        
                                    :
                                    <div className="subBox">
                                            
                                                <div className='tradingBoxRow'>
                                                    <div role="button" className="selectAssetButton w-100"onClick={this.openChooseExpiredAssetModal}>
                                                        <div>
                                                            {this.state.selectedAssetExpired} <KeyboardArrowDownOutlinedIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            <div className="row mt-4 px-4">
                                                <div className="col align-self-end text-lg-right textBalance">
                                                    {this.state.burnableBalanceToken1Visible ? 
                                                        <div>Balance available to burn: <span role="button" onClick={this.setMaxAmountToken1}>{this.props.outputNumber(parseInt(this.state.burnableBalanceToken1)/1e18,8)}</span></div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row px-4">     
                                                {this.state.selectedAssetExpired !== "Select Asset" ?
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" for="amountToBurnToken1">Amount to convert {this.state.selectedAssetExpired}:</label>
                                                    </div>
                                                    <input className="form-control bg-innerBox" onChange={() =>this.checkInputExpired()} type='number' id='amountToBurnToken1' defaultValue={0}></input>
                                                    
                                                </div>
                                                :
                                                ''
                                                }   
                                            </div>   
                                            <div className="row px-4">
                                                <div className="col align-self-end text-lg-right textBalance">
                                                    {this.state.burnableBalanceToken1Visible ? 
                                                        <div>Balance available to burn: <span role="button" onClick={this.setMaxAmountToken2}>{this.props.outputNumber(parseInt(this.state.burnableBalanceToken2)/1e18,8)}</span></div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row px-4">     
                                                {this.state.selectedAssetExpired !== "Select Asset" ?
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" for="amountToBurnToken2">Amount to convert i{this.state.selectedAssetExpired}:</label>
                                                    </div>
                                                    <input className="form-control bg-innerBox" onChange={() =>this.checkInputExpired()} type='number' id='amountToBurnToken2' defaultValue={0}></input>
                                                    
                                                </div>
                                                :
                                                ''
                                                }   
                                            </div> 
                                            
                                            <div className="row">
                                                <div className="col align-self-start py-4">
                                                    {this.state.burnAmount > this.state.allowance && this.state.selectedAssetExpired !== 'Select Asset' ?  
                                                        <div id="buttonRounded">
                                                            <TransactionButton
                                                                contractAddress={this.props.assetDetails[this.state.selectedAssetExpired][0]} 
                                                                abi = {this.props.ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[this.props.assetFactory._address,this.props.web3.utils.toBigInt(2**255)]} 
                                                                text="Approve Long"
                                                                onSuccess={this.onSuccessApproveLong}
                                                            />
                                                            
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {this.state.burnAmount > this.state.allowance2 && this.state.selectedAssetExpired !== 'Select Asset' ?  
                                                        <div id="buttonRounded">
                                                            <TransactionButton
                                                                contractAddress={this.props.assetDetails[this.state.selectedAssetExpired][1]} 
                                                                abi = {this.props.ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[this.props.assetFactory._address,this.props.web3.utils.toBigInt(2**255)]} 
                                                                text='Approve Short Token'
                                                                onSuccess={this.onSuccessApprove}
                                                            />
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {this.state.submitButtonDeactivated ? 
                                                        <div id="buttonRounded"><button className="btn btn-accent w-100 my-2" id="submit">{this.state.submitButtonDeactivatedMessage}</button></div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {this.state.submitButtonDeactivated === false && this.state.burnAmount < this.state.allowance && this.state.burnAmount < this.state.allowance2  ? 
                                                        <button className="btn w-100 issuaaButton" onClick={this.openModalBurnExpiredAssets}>Submit</button>
                                                        :
                                                        <div></div>
                                                    }
                        
                                                </div>    
                                            </div> 
                        
                                                
                                        </div>
                                        
                                }
                             
                        </div>
                   
                    
                </div>
            
            </div> 

        );
    }
}
 
export default BurnAssets;