import '../styles/factory.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {TransactionButton} from '../functions/Trx'; 
import assetIcon from '../img/icons/Avatar.png';
import honeyIcon from '../img/icons/HoneyIcon.png';


class Factory extends Component {
    

    state = { 
        mintAmount:0,
        assets: ['wait'],
        modalOpen: false,
        selectedAsset : 'Select an asset',
        submitButtonVisible: false,
        errorButtonVisible: false,
        errorButtonMessage:"",
        USDCBalance: 0,
        chooseAssetModalOpen: false,
        filteredAssets: [],
    }

    async componentDidMount() {
        //if (this.props.USDCAllowance != 'undefined') {this.setState({USDCAllowance:this.props.USDCAllowance})}

        this.setState({
            USDCAllowance: parseInt(this.props.USDCAllowance),
            assetDetails: this.props.assetDetails,
            USDCBalance: this.props.USDCBalance,
        });
        console.log(parseInt(this.props.USDCAllowance))
        if (parseInt(this.props.USDCAllowance) > 0 ){
            console.log(parseInt(this.props.USDCAllowance))
            this.setState({approvalButtonVisible: false});
            this.setState({confirmButtonVisible: true});
            this.setState({confirmButtonBlockedVisible: false})
        }
        else {
            this.setState({approvalButtonVisible: true});
            this.setState({confirmButtonVisible: false});
            this.setState({confirmButtonBlockedVisible: true})
            }
    };

    checkUSDAllowanceAssetFactory = async () => {
        // check if the the allowance has been set for the AssetFactory contract to spend USDT
       let allowance = await this.props.USDC.methods.allowance(this.props.address, this.props.assetFactory_Address).call()
       this.setState({USDCAllowance:allowance})
       console.log(this.state.USDCAllowance)
 
     };


    openModal = async () => {
       
        if (this.props.USDCAllowance !== "0"){
            this.setState({approvalButtonVisible: false});
            this.setState({confirmButtonVisible: true});
            this.setState({confirmButtonBlockedVisible: false})
        }
        else {
            this.setState({approvalButtonVisible: true});
            this.setState({confirmButtonVisible: false});
            this.setState({confirmButtonBlockedVisible: true})
            }
        
        let selectedAsset = this.state.selectedAsset;
        let investmentAmount = document.getElementById('amountToStake').value
        let AssetDetails  = await this.props.assetFactory.methods.getAsset(selectedAsset).call()
        let upperLimit = Number(AssetDetails.upperLimit)/1000
        let tokenAmount = investmentAmount / upperLimit
        console.log("Token amount:",tokenAmount)
        console.log(AssetDetails)
        this.setState({ modalOpen: true })
        this.setState({ tokenAmount})
        
        this.setState({selectedAsset})
    };

    closeModal = () => this.setState({ modalOpen: false });

    
    onSuccessMint = async () =>{
        await this.props.updateAssetBalance(this.state.selectedAsset);
        await this.props.loadUSDBalance();
        document.getElementById('amountToStake').value = 0
        this.closeModal()
    }
    
    onSuccessApprove = async() =>{  
        this.changeAmount();
    };

    setMaxAmount = async() =>{
        let amount = 0
        if (typeof(this.props.USDCBalance) != 'undefined'){ 
            amount = parseInt(this.props.USDCBalance)
        }
        document.getElementById('amountToStake').value = amount
        this.changeAmount()
        return
    };

    changeAmount = async() =>{
        let allowance = await this.props.USDC.methods.allowance(this.props.address, this.props.assetFactory_Address).call()
        await this.setState({USDCAllowance: parseInt(allowance)})
        console.log(this.state.USDCAllowance)
        
        let amount = await document.getElementById('amountToStake').value;
        if (amount > 0){
            await this.setState({mintAmount:amount})
        }
        else{
            document.getElementById('amountToStake').value = ''
            await this.setState({mintAmount:0})
            amount = 0
        }

        if (amount > parseInt(this.props.USDCBalance)) {
            this.setState({errorButtonVisible: true})
            this.setState({errorButtonMessage: "Balance too low"})
        }
        else if (this.state.selectedAsset === "Select an asset") {
            this.setState({errorButtonVisible: true})
            this.setState({errorButtonMessage: "Select an asset"})
        }
        else {
            this.setState({errorButtonVisible: false})
        }

    }

    openChooseAssetModal=()=>{
        let assets = [];
        for (let key in this.props.assetDetails) {
            console.log(this.props.assetDetails[key])
            if (this.props.assetDetails[key]['frozen'] === false && this.props.assetDetails[key]['expiryTime'] > Date.now()/1000){

                let balance1 = this.props.assetDetails[key]['tokenBalance1'];
                let balance2 = this.props.assetDetails[key]['tokenBalance2'];
                let asset = [key,balance1,balance2, this.props.assetDetails[key]['name'], this.props.assetDetails[key]['upperLimit']];
                assets.push(asset);
                console.log(asset)
            }
            
        }

        this.setState({chooseAssetModalOpen: true })
        this.setState({assets:assets})
        this.setState({filteredAssets: assets})  

        
    };
    closeChooseAssetModal=()=>{
        this.setState({chooseAssetModalOpen: false })
    }

    listMintableAssets() {
        for (let i = 0; i < this.state.filteredAssets.length; ++i) {
            console.log(this.state.filteredAssets[i])
        }
        let assetOptions = this.state.filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAsset(element[0])}>
                    <div className="assetSelectListName">
                        <div className=""><b>{element[0]}</b></div>
                        <div className=""><b>{element[3]}</b></div>
                    </div>
                    <div className="assetSelectListBalance">
                        <div className="">Balance: {this.props.outputNumber(element[1],4)} LONG / {this.props.outputNumber(element[2],4)} SHORT</div>
                        <div className="">UL: {this.props.outputNumber(Number(element[4])/1000,0)} {this.props.stableCoinName}</div>
                    </div>
                </div>
        );
        return(assetOptions)
    }

    filterAssets(){
        let filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < this.state.assets.length; ++i) {
            if (this.state.assets[i][3].toLowerCase().includes(searchTerm) || this.state.assets[i][0].toLowerCase().includes(searchTerm)){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        this.setState({filteredAssets:filteredAssets})


    }

    selectAsset = async(asset) =>{
        console.log(asset)
        await this.setState({"selectedAsset":asset});
        this.closeChooseAssetModal();
    };

    render() { 
        
              
        return ( 
            
            <div className="mainContainer">
                

                    <Modal
                        show={this.state.chooseAssetModalOpen} onHide={this.closeChooseAssetModal}>
                        <Modal.Header className="modal-header" closeButton>
                            <Modal.Title>Select TWIN Asset pair to mint</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <input type="text" className="searchField col w-100 " id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                                
                            </div>
                            <div className="list-group list-assets">
                                {this.listMintableAssets()}
                            </div>
                        </Modal.Body>                        
                    </Modal>

                    <Modal show={this.state.modalOpen} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>You will receive:</Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                                <div className="factoryModalHeader">{this.state.selectedAsset}</div>
                                <div className='factoryModalBody'>
                                    {this.props.outputNumber(parseFloat(this.state.tokenAmount),4)} LONG & SHORT Tokens.
                                </div>
                                </Modal.Body>
                        <Modal.Footer>
                            {this.state.confirmButtonVisible ? 
                            <div className='w-100'>
                                
                                <TransactionButton
                                    abi={this.props.assetFactory_ABI} // Adjust this to where you're storing ABI
                                    contractAddress={this.props.assetFactory_Address} 
                                    functionName="mintAssets" 
                                    args={[this.state.selectedAsset, this.props.web3.utils.toBigInt(this.state.mintAmount) * this.props.web3.utils.toBigInt(10**this.props.USDDecimals)] } // Example, adjust as needed
                                    text="Mint"
                                    onSuccess={this.onSuccessMint}
                                    className="w-100"
                                />
                            </div> 
                            : 
                            <div></div>
                            }
                        </Modal.Footer>
                    </Modal>

                    
                    
                    <div className="middleBoxSmall">
                        <div className='row m-0'>
                            <div className="mainBox">
                                <div className='factoryHeadline'>Select a TWIN Asset pair to mint</div>
                                
                                <div className="subBox">                                
                                    <div className="selectAssetButton factorySelectAssetButton" onClick={this.openChooseAssetModal}>
                                        <div className='factorySelectButtonText'>
                                            <div className='assetBoxIcon'>
                                                <img className="" src={assetIcon} alt="honey" width="24px"/>
                                            </div>
                                            <div>{this.state.selectedAsset}</div>
                                        </div>
                                        <div className='factoryIconRight'><KeyboardArrowDownOutlinedIcon /></div>
                                    </div>
                                </div>
                                <div className='factoryHeadline pt-4'>{this.props.stableCoinName} amount to deposit</div>
                                <div className="subBox"> 
                                    <div className="subBoxLine">
                                        <div>
                                            <input placeholder="0.00" className="inputCustom" type='text' id='amountToStake' onChange={this.changeAmount}></input>
                                        </div>
                                        <div className='stablecoinAssetBox'>
                                            <div className='assetBoxIcon'>
                                                <img className="" src={honeyIcon} alt="honey" width="24px"/>
                                            </div>
                                            {this.props.stableCoinName}
                                        </div>
                                    </div>
                                    <div onClick={this.setMaxAmount} role="button" className="" key={this.props.USDCBalance}>
                                        Balance: <span className="tradeBalance">{typeof(this.props.USDCBalance) != 'undefined' ? this.props.outputNumber(parseInt(this.props.USDCBalance),0)+" "+this.props.stableCoinName+" (Max)" : ''}</span>
                                    </div>
                                    
                                </div>
                                    
                                
                                <div id=""  className="">
                                    {this.state.mintAmount > this.state.USDCAllowance/(10**this.props.USDDecimals) ?
                                    <TransactionButton
                                        contractAddress={this.props.USDC_Address} 
                                        abi = {this.props.ERC20_ABI}
                                        functionName= 'approve'
                                        args =  {[this.props.assetFactory_Address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                        text="Approve"
                                        onSuccess={this.onSuccessApprove}
                                    /> 
                                        
                                        : <div></div>
                                    }
                                    
                                    {this.state.errorButtonVisible ? 
                                        <div className="w-100 issuaaButtonDeactivated">
                                            {this.state.errorButtonMessage}
                                        </div> 
                                        : <div></div>}
                                    {this.state.mintAmount < this.state.USDCAllowance/(10**this.props.USDDecimals) &&  this.state.errorButtonVisible === false && this.state.mintAmount > 0 ? 
                                        <div role='button' className="w-100 issuaaButton" onClick={this.openModal}>
                                            Submit
                                        </div>
                                        
                                    : <div></div>
                                    }
                                    
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    
                   
                    
                
            </div>
            
         );
    }
}
 
export default Factory;