import React, { Component } from 'react';
import Zoom from '@successtar/react-reveal/Zoom';


import bamboo from '../img/graphics/Bamboo3.png';


class ChangeChain extends Component {
    
     

    
    



    render() { 
        console.log(this.state)
        
        return ( 
            <Zoom>
            <div className="mainContainer">
                <div className="middleBoxSmall">
                    <div className='row m-0'>
                        <div className='col-12'>
                        
                        </div>        
                        <div className="mainBox">
                            <div className="p-4 bold">
                                TWIN Protocol is powered by Berachain. Please change your chain to Berachain.                                
                            </div>
                        </div>
                         
                    </div>
                </div>
                
            </div>
            </Zoom>
        );
    }
}
 
export default ChangeChain;