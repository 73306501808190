import React, { Component } from 'react';
import Zoom from '@successtar/react-reveal/Zoom';




class Test extends Component {
    
    constructor(props){
        super(props)
        this.state = { 
            
        }
        
    }
    async componentDidMount() {
        this.setState({
            assets: ['wait'],
            USDTBalance: this.props.USDTBalance,
            //INTAddress: this.props.GovernanceToken_Address,
        });
    };

    




    mintUSD = async() => {
        let message = "Requesting 100k Mock USD"
        this.props.openMessageBox(message)

        await this.props.transactWithContract('mockUSDC','mint',[])
        await this.props.loadUSDBalance();
        this.props.closeMessageBox()
        return ("Already approved")
    }


    
    



    render() { 
        console.log(this.state)
        
        return ( 
            <Zoom>
            <div className="row w-100">
                <div className="container-fluid m-3">
                    <div className="row">
                        <div className="col"></div>
                        <div className='col-7'>
                            <div 
                                style ={{
                                    fontFamily: "PosteramaRegular",
                                    letterSpacing: "0.1rem",
                                    fontSize: "3.5vw",
                                    color:"white",
                                    paddingLeft: "2vw",
                                    marginBottom: "2vh",
                                }}
                            >
                                ISSUAA alpha testing
                            </div>
                            <div className="container bg-accent text-black p-2 rounded">
                                <div className="w-100 bg-light text-black p-3 rounded border border-dark">
                                    
                                    <div className="p-2">

                                        First, to pay the gas fees on the testnet, you will need some testnet ether. Please check the faucet below which will provide you with some Sepolia ETH for free. 
                                        <br></br>
                                        <div><a className="btn btn-fuchsia mt-2 mb-4" href="https://sepoliafaucet.com/" target="_blank"  rel="noreferrer">Faucet</a></div>
                                        <div>Next you need to bridge your testnet ETH to Scroll Sepolia using the Scroll bridge.</div>
                                        <a className="btn btn-fuchsia mt-2 mb-4" href="https://sepolia.scroll.io/bridge" target="_blank"  rel="noreferrer">Bridge</a>
                                        
                                        <br></br>
                                    </div>
                                    

                                    <div className="p-2">   
                                        Lastly, for testing, we are not using real USD coins, but a Mock-USD token.
                                        You can get some Mock-USD for testing purposes by minting them here.
                                        <br></br>
                                    </div>
                                    <div className="btn btn-fuchsia ml-2" onClick={this.mintUSD}>Get Test USD</div>
                                </div>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </div>
            </Zoom>
        );
    }
}
 
export default Test;