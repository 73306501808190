import '../styles/governance.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import {TransactionButton} from '../functions/Trx'; 
import bamboo from '../img/graphics/Bamboo3.png';

class Governance extends Component {
    state = { 
    assetDetails: null,   
    view: "assets",
    styleCSS1: "governanceBoxClicked",
    styleCSS2: "governanceBox",
    styleCSS3: "governanceBox",
    styleCSS4: "governanceBox",
    bgColor1: "bg-lightgray"    
    };

    async componentDidMount() {
        this.setState({
            assets: ['wait'],
            freezeModalOpen: false,
            assetToFreeze: "",
            assetToVote: "",
            voteModalOpen: false,
            assetToClose: "",

            closeModalOpen: false,
            finalPriceModalOpen: false ,
            assetToVoteOnFinalPrice: "",
            initiateExpiryVoteModalOpen: false ,
            assetToInitiateExpiryVote: "",
            closeExpiryVoteModalOpen: false,
            initiateGrantProposal: false,
            assetToCloseExpiryVote: "",
            assetDetails: this.props.assetDetails,
            voteOnGrantProposalModal: false,
            selectedGrantVote: "",
            requestGrantButtonVisible: true,
            proposeNewAssetButtonVisible: false,
            initiateNewAssetProposal: false,
            proposeUgradeButtonVisible: true, 

        });

        console.log(this.props.assetDetails)
        console.log(Date.now())

        if (this.props.DAO !== undefined){this.loadNewAssetProposals();}
        if (this.props.DAO !== undefined){this.loadGrantRequests();}
        if (this.props.DAO !== undefined){this.loadUpgradeVotes();}
    };

    loadNewAssetProposals = async() =>{
        let numberOfNewAssetProposals = await this.props.DAO.methods.numberOfNewAssetVotes().call();
        let newAssetsProposed = []
        console.log(numberOfNewAssetProposals)
        var newAssetVotesDetailsOpen = [];
        var newAssetVotesDetailsClosed = [];
        for (let i = 0; i < numberOfNewAssetProposals; ++i) {
            let proposedSymbol = await this.props.DAO.methods.newAssetVoteSymbols(i).call()
            console.log(proposedSymbol)
            if (newAssetsProposed.includes(proposedSymbol)){
                continue
            }
            else {
                newAssetsProposed.push(proposedSymbol)
            }
            let result = await this.props.DAO.methods.getNewAssetVotes(proposedSymbol).call()
            console.log(result)
            let hasVoted = await this.props.DAO.methods.checkIfVotedNewAsset(this.props.address,proposedSymbol).call()
            console.log(hasVoted)
            if (result['open']){
                newAssetVotesDetailsOpen.push([proposedSymbol,result['name'],result['description'],result['upperLimit'],result['endingTime'],result['yesVotes'],result['noVotes'],hasVoted])
            }
            else {
                newAssetVotesDetailsClosed.push([proposedSymbol,result['name'],result['description'],result['upperLimit'],result['endingTime'],result['yesVotes'],result['noVotes'],hasVoted])
            }
            
            
        }
        if (parseFloat(this.props.GovernanceTokenStakeBalance) > 100000) {this.setState({proposeNewAssetButtonVisible:true});console.log("Button Visible")} else {console.log(this.props.GovernanceTokenStakeBalance)};
            
        this.setState({newAssetVotesDetailsOpen})
        this.setState({newAssetVotesDetailsClosed})
        console.log(newAssetVotesDetailsOpen) 
        console.log(newAssetVotesDetailsClosed)      

    } 

    loadGrantRequests = async() =>{
        let result = await this.props.DAO.methods.getGrantVoteDetails(this.props.address).call()
        console.log(result);
        if (result[5] === true){
            this.setState({requestGrantButtonVisible: false})
        }
        else{console.log("Button visible")};
        console.log(result)
        let numberOfGrantVotes = await this.props.DAO.methods.numberOfGrantVotes().call();
        console.log(numberOfGrantVotes)
        

        var grantVotesDetailsOpen = [];
        var grantVotesDetailsClosed = [];
        for (let i = 0; i < numberOfGrantVotes; ++i) {
            console.log(i)
            let receivingAddress = await this.props.DAO.methods.grantVoteAddresses(i).call()
            console.log(receivingAddress)
            
            let result = await this.props.DAO.methods.getGrantVoteDetails(receivingAddress).call()
            console.log(result)
            let hasVoted = await this.props.DAO.methods.checkIfVotedGrantFunding(this.props.address,receivingAddress).call()
            console.log(hasVoted)
            console.log(result);
            if (result[5]){
                grantVotesDetailsOpen.push([receivingAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])

            }
            else {
                grantVotesDetailsClosed.push([receivingAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])
            }
        }
        if (this.props.GovernanceTokenStakeBalance < 100000) {this.setState({requestGrantButtonVisible:false})}
        else {this.setState({requestGrantButtonVisible:true})};

        this.setState({grantVotesDetailsOpen})
        this.setState({grantVotesDetailsClosed})
        console.log(grantVotesDetailsOpen) 
        console.log(grantVotesDetailsClosed)
        console.log(this.state.requestGrantButtonVisible)      

    }

    loadUpgradeVotes = async() =>{
        
        let result = await this.props.Upgrader.methods.getUpgradeVoteDetails(this.props.address).call()
        console.log(result);
        if (result[5] === true){
            this.setState({proposeUgradeButtonVisible: false})
        }
        else{console.log("Button visible")};
        console.log(result)
        let numberOfUpgradeVotes = await this.props.Upgrader.methods.numberOfUpgradeVotes().call();
        console.log(numberOfUpgradeVotes)
        

        var upgradeVotesDetailsOpen = [];
        var upgradeVotesDetailsClosed = [];
        for (let i = 0; i < numberOfUpgradeVotes; ++i) {
            console.log(i)
            let newContractAddress = await this.props.Upgrader.methods.upgradeVoteAddresses(i).call()
            console.log(newContractAddress)
            
            let result = await this.props.Upgrader.methods.getUpgradeVoteDetails(newContractAddress).call()
            console.log(result)
            let hasVoted = await this.props.Upgrader.methods.checkIfVotedUpgrade(this.props.address,newContractAddress).call()
            console.log(hasVoted)
            console.log(result);
            if (result[4]){
                upgradeVotesDetailsOpen.push([newContractAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])

            }
            else {
                upgradeVotesDetailsClosed.push([newContractAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])
            }
        }
        if (this.props.GovernanceTokenStakeBalance < 100000) {this.setState({requestGrantButtonVisible:false})}
        else {this.setState({requestGrantButtonVisible:true})};

        this.setState({upgradeVotesDetailsOpen})
        this.setState({upgradeVotesDetailsClosed})
        console.log(upgradeVotesDetailsOpen) 
        console.log(upgradeVotesDetailsClosed)
        console.log(this.state.requestGrantButtonVisible)      

    }     

    getAssets() {
        let assets = this.props.assets;
        return assets;
    };

    openFreezeModal=(asset)=>{
        console.log(asset)
        this.setState({assetToFreeze:asset})
        this.setState({ freezeModalOpen: true })     
    };
    
    closeFreezeModal = () => this.setState({ freezeModalOpen: false });

    openVoteModal=(asset)=>{
        console.log(asset)
        console.log("Click")
        this.setState({assetToVote: asset})
        this.setState({ voteModalOpen: true })     
    };
    
    closeVoteModal = () => this.setState({ voteModalOpen: false });
    
    openFinalPriceModal =(asset)=>{
        this.setState({assetToVoteOnFinalPrice: asset})
        this.setState({ finalPriceModalOpen: true })     
    };
    closeFinalPriceModal = () => this.setState({ finalPriceModalOpen: false });


    openCloseExpiryVoteModal =(asset)=>{
        this.setState({assetToCloseExpiryVote: asset})
        this.setState({ closeExpiryVoteModalOpen: true })     
    };
    closeCloseExpiryVoteModal = () => this.setState({ closeExpiryVoteModalOpen: false });


    openInitiateExpiryVoteModal =(asset)=>{
        this.setState({assetToInitiateExpiryVote: asset})
        this.setState({ initiateExpiryVoteModalOpen: true });
    };
    
    openGrantPropsal = () => this.setState({ initiateGrantProposal: true });
    closeGrantPropsal = () => this.setState({ initiateGrantProposal: false });

    openUpgradePropsal = () => this.setState({ initiateUpgradeProposal: true });
    closeUpgradePropsal = () => this.setState({ initiateUpgradeProposal: false });

    closeInitiateExpiryVoteModal = () => this.setState({ initiateExpiryVoteModalOpen: false });

    onSuccessCloseExpiryVote     = async() => {
        await this.props.updateExpiryVotes()
        this.setState({assetDetails: this.props.assetDetails})
    } 
    
    onSuccessInitiateFreeze = async() => {
        this.setState({ freezeModalOpen: false })
        await this.props.updateFreezeVotes()
        this.setState({assetDetails: this.props.assetDetails})
    }

    onSuccessVoteFreeze = async() => {
        this.setState({ voteModalOpen: false })
        await this.props.updateFreezeVotes()
        this.setState({assetDetails: this.props.assetDetails})
    }

    

    onSuccessCloseFreezeVote     = async() => {
        await this.props.updateFreezeVotes()
        this.setState({assetDetails: this.props.assetDetails})
    }

    onSuccessInitiateExpiryVote = async() =>{
        this.setState({ initiateExpiryVoteModalOpen: false })
        await this.props.updateExpiryVotes()
        this.setState({assetDetails: this.props.assetDetails})
    }

    checkNewGrantInput = async()=>{
        let grantAmount = parseInt(document.getElementById('grantAmount').value)*(10**18)
        let description = document.getElementById('grantDescription').value
        this.setState({grantAmount})
        this.setState({description})
        this.loadGrantRequests();
    }

    checkNewUpgradeInput = async()=>{
        let smartContractToUpgrade = document.getElementById('smartContractToUpgrade').value
        let newSmartContractAddress = document.getElementById('newSmartContractAddress').value
        this.setState({smartContractToUpgrade})
        this.setState({newSmartContractAddress})
    }

    onSuccessNewUpgradeProposal = async()=>{
        this.setState({initiateUpgradeProposal:false})
        this.setState({submitUpgradeProposal:false})
        this.loadUpgradeVotes();        
    }

    checkExpiryPrice = async() =>{
        let expiryPriceToSubmit = parseFloat(document.getElementById('expiryPrice').value)*1000
        this.setState({expiryPriceToSubmit})
    }

    onSuccessExpiryPriceVote     = async() => {
        this.setState({ finalPriceModalOpen: false });
        await this.props.updateExpiryVotes()
        this.setState({assetDetails: this.props.assetDetails})
    }

    onSuccessVoteGrantProposal = async() => {
        this.setState({ voteOnGrantProposalModal: false })
        this.loadGrantRequests();
    }

    

    onSuccessCloseGrantVote = async() =>{
        this.setState({ voteOnGrantProposalModal: false })
        this.loadGrantRequests();
    }

    onSuccessVoteUpgradeProposal = async() => {
        this.setState({ voteOnUpgradeProposalModal: false })
        this.loadUpgradeVotes();
    }
    
    onSuccessCloseUpgradeVote = async(newContractAddress) =>{
        this.loadUpgradeVotes();
    }
    
    changeView = async (newView) =>{
        this.setState({"view":newView})
        if (newView === "assets"){
            this.setState({styleCSS1: "governanceBoxClicked"});
            this.setState({styleCSS2: "governanceBox"});
            this.setState({styleCSS3: "governanceBox"});
            this.setState({styleCSS4: "governanceBox"});
        }
        if (newView === "newAsset"){
            this.setState({styleCSS1: "governanceBox"});
            this.setState({styleCSS2: "governanceBoxClicked"});
            this.setState({styleCSS3: "governanceBox"});
            this.setState({styleCSS4: "governanceBox"});
        }
        if (newView === "grantVotes"){
            this.setState({styleCSS1: "governanceBox"});
            this.setState({styleCSS2: "governanceBox"});
            this.setState({styleCSS3: "governanceBoxClicked"});
            this.setState({styleCSS4: "governanceBox"});
        }
        if (newView === "upgrade"){
            this.setState({styleCSS1: "governanceBox"});
            this.setState({styleCSS2: "governanceBox"});
            this.setState({styleCSS3: "governanceBox"});
            this.setState({styleCSS4: "governanceBoxClicked"});
        }
    }
    timeStampToDate(timestamp) {
        var date = new Date(timestamp * 1000)
        const options = {year: 'numeric', month: 'long', day: 'numeric' };
        let formattedDate = date.toLocaleDateString(options);
        return(formattedDate);
    }

    timeStampToDateAndTime(timestamp) {
    var date = new Date(timestamp * 1000)
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    let formattedDate = date.toLocaleDateString(options);
    const optionsTime = {hour: '2-digit', minute: '2-digit'};
    let formattedTime = date.toLocaleTimeString([],optionsTime);
    return(formattedDate+" "+formattedTime);
  }

    closeVoteOnGrantProposalModal= () => {
        this.setState({ voteOnGrantProposalModal: false });
    };
    openVoteOnGrantProposalModal= async(receiver) => {
        this.setState({ voteOnGrantProposalModal: true });
        this.setState({ selectedGrantVote: receiver });
        let result = await this.props.DAO.methods.getGrantVoteDetails(receiver).call()
        console.log(result)
        this.setState({selectedGrantVoteReceiver: receiver})
        this.setState({selectedGrantVoteAmount: result[3]})
        this.setState({selectedGrantVoteDescription: result[4]})
        
    }

    openNewAssetPropsalModal= () => {
        this.setState({ initiateNewAssetProposal: true });
    }

    closeNewAssetPropsalModal= () => {
        this.setState({ initiateNewAssetProposal: false });
    };

    checkNewAssetProposalInput= async()=>{
        let proposedName = document.getElementById('proposedName').value
        let proposedSymbol = document.getElementById('proposedSymbol').value
        let year = new Date().getFullYear()-2000+1
        let month = new Date().getMonth()+1
        if (month<10) {month = "0".concat(month.toString())} else {month=month.toString()}
        proposedSymbol = proposedSymbol.concat("_",year.toString(),month)
        let proposedUpperLimit = parseInt(document.getElementById('proposedUpperLimit').value*1000).toString();
        let proposedDescription = document.getElementById('proposedDescription').value
        this.setState({proposedName})
        this.setState({proposedSymbol})
        this.setState({proposedUpperLimit})
        this.setState({proposedDescription})
    
    }
    onSuccessNewGrantProposal = async()=>{
        this.setState({initiateGrantProposal:false})
        this.loadGrantRequests()
    }

    openVoteOnNewAssetProposalModal= async (symbol) => {
        this.setState({ voteOnNewAssetProposalModal: true });
        this.setState({ selectedNewAssetVote: symbol });
        let result = await this.props.DAO.methods.getNewAssetVotes(symbol).call()
        console.log(result)
        this.setState({selectedNewAssetName: result[6]})
        this.setState({selectedNewAssetUpperLimit: result[8]})
        this.setState({selectedNewAssetDescription: result[7]})
    }

    closeVoteOnNewAssetProposalModal= () => {
        this.setState({ voteOnNewAssetProposalModal: false });
    };
    onSuccessVoteNewAssetProposal = async() => {
        this.setState({ voteOnNewAssetProposalModal: false })
        this.loadNewAssetProposals();
        
    }
   

    onSuccessCloseVoteOnNewAssetProposal = async(symbol) =>{
        this.loadUpgradeVotes()
        
    }

    openVoteOnUpgradeProposalModal= async(newContractAddress) => {
        this.setState({ voteOnUpgradeProposalModal: true });
        this.setState({ selectedUpgradeVote: newContractAddress });
        let result = await this.props.Upgrader.methods.getUpgradeVoteDetails(newContractAddress).call()
        console.log(result)
        this.setState({selectedNewContractAddress: newContractAddress})
        this.setState({selectedContractToUpgrade: result[3]})
        
    }

    closeVoteOnUpgradeProposalModal= () => {
        console.log("Debug")
        this.setState({ voteOnUpgradeProposalModal: false });
    };

    render() {

        const tooltip1 = props => (
            <Tooltip {...props}>
            TWIN owners can initiate Expiry votings and Freeze votings for the TWIN Assets listed below.
            </Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
            Propose new TWIN Assets. Requires min 100,000 TWIN staked.
            </Tooltip>
        );
        const tooltip3 = props => (
            <Tooltip {...props}>
            TWIN owners may propose to receive TWIN from the DAO grant reserve as reward for exeptional value-add they contributed to the TWIN
            DAO.
            </Tooltip>
        );
        const tooltip4 = props => (
            <Tooltip {...props}>
            TWIN holders can suggest and vote on upgrades of the existing smart contracts.
            </Tooltip>
        );
        const tooltip5 = props => (
            <Tooltip {...props}>
            Please select a 3-4 digit (letters only) symbol for the asset
            </Tooltip>
        );
        const tooltip6 = props => (
            <Tooltip {...props}>
            Please describe the underlying asset briefly and precisely. Please also provide the ISIN (if existing) of the underlying asset
            </Tooltip>
        );
        const tooltip7 = props => (
            <Tooltip {...props}>
            Please choose which upper limit shall apply for the short token of the asset. The upper limit should be defined at approx. twice the current market price of the underlying asset and rounded on full hundreds, thousands, etc., as applicable.
            </Tooltip>
        );
        const tooltip8 = props => (
            <Tooltip {...props}>
                Max 100,000 TWIN
            </Tooltip>
        );


       
        //var AssetOptions = ['loading']
        var assets = this.getAssets();
        if (typeof(this.props.assets) != 'undefined'){
            console.log(typeof(assets))
            console.log(assets)
            //AssetOptions = assets
        }

        let showNewAssetProposals
        console.log(this.state.newAssetVotesDetailsOpen)
        if (typeof(this.state.newAssetVotesDetailsOpen) != 'undefined'){
            showNewAssetProposals = this.state.newAssetVotesDetailsOpen.map((details,index) =>
                <div key ={index} className="">
                    <div key ={index} className="governanceAssetBox">
                        <div id="innerBox" key ={index} className="h-100 p-4 col">
                            
                            <div className="row">
                                <div className="col-12 mb-2"><b>Active new TWIN Asset proposal vote #{index+1}</b></div>
                            </div>
                            <div className="row">
                                <div className="col-4">Symbol:</div>
                                <div className="col-8">{details[0]}</div>
                            </div>
                            <div className="row">
                                <div className="col-4">Name:</div>
                                <div className="col-8">{details[1]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col-4">Vote ends:</div>
                                <div className="col-8">{this.timeStampToDateAndTime(parseInt(details[4]))}</div>
                            </div>
                            <div className="row">
                                <div className="col-4">Upper Limit:</div>
                                <div className="col-8">{this.props.outputNumber(parseInt(details[3])/(1000),0)}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Description of the Asset:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[2]}</div>
                            </div>
                            <div className="row">
                                <div className="col-4">YES votes</div>
                                <div className="col-8">
                                    {parseInt(details[5]) > 0 ?
                                        <span>{(parseInt(details[5])*100/(parseInt(details[5])+parseInt(details[6]))).toFixed(2)}</span>
                                        :
                                        <span>0</span>
                                    }
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">NO votes</div>
                                <div className="col-8">
                                    {parseInt(details[6]) > 0 ?
                                        <span>{(parseInt(details[6])*100/(parseInt(details[5])+parseInt(details[6]))).toFixed(2)}</span>
                                        :
                                        <span>0</span>
                                    }
                                    %
                                </div>
                            </div>

                            {parseInt(details[4])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton w-100' onClick={()=>this.openVoteOnNewAssetProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                null
                            }
                            {parseInt(details[4])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={this.props.DAO_ABI}
                                    contractAddress={this.props.DAO_Address}
                                    functionName="closeNewAssetVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {this.onSuccessNewAssetProposal}
                                /> 
                                :
                                null
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }

        let showDAOvotes
        if (typeof(this.state.grantVotesDetailsOpen) != 'undefined'){
            showDAOvotes = this.state.grantVotesDetailsOpen.map((details,index) =>
                <div key ={index} className="governanceAssetBox">
                    <div key ={index} className="h-100 p-1 mb-4 col">
                        <div id="" key ={index} className="h-100 p-4 col">

                            <div className="row">
                                <div className="col-12 mb-2"><b>Active TWIN DAO grant proposal #{index+1}</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col my-auto"><b>Receiver:</b></div>
                                <div className="col addressGovernance my-auto mb-2">{details[0]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col"><b>Vote ends:</b></div>
                                <div className="col">{this.timeStampToDateAndTime(parseInt(details[1]))}</div>
                            </div>
                            <div className="row">
                                <div className="col"><b>Requested Amount:</b></div>
                                <div className="col">{this.props.outputNumber(parseInt(details[4])/(10**18),0)} TWIN</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Reason for the requested DAO grant:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[5]}</div>
                            </div>
                            <div className="row">
                                <div className="col">YES votes</div>
                                <div className="col">
                                    {parseInt(details[2])>0 ?
                                        <span>(parseInt(details[2])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                        :
                                        <span>0</span>
                                    }
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">NO votes</div>
                                <div className="col">{parseInt(details[3]) > 0 ?
                                    <span>(parseInt(details[3])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                }
                                %
                            </div>
                            </div>

                            {parseInt(details[1])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton' onClick={()=>this.openVoteOnGrantProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                ''
                            }
                            {parseInt(details[1])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={this.props.DAO_ABI}
                                    contractAddress={this.props.DAO_Address}
                                    functionName="closeGrantFundingVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {this.onSuccessCloseGrantVote}
                                /> 
                                :
                                ''
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }
        let showUpgradevotes
        if (typeof(this.state.upgradeVotesDetailsOpen) != 'undefined'){
            showUpgradevotes = this.state.upgradeVotesDetailsOpen.map((details,index) =>
            <div key ={index} className="governanceAssetBox">
                    <div key ={index} className="h-100 p-1 mb-4 col">
                        <div id="innerBox" key ={index} className="h-100 p-4 col">

                            <div className="row">
                                <div className="col-12 mb-2"><b>TWIN Code Upgrade proposal #{index+1}</b></div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Contract to be upgraded:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[4]}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col my-auto"><b>New implementation contract address:</b></div>
                                <div className="col addressGovernance my-auto mb-2">{details[0]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col"><b>Vote ends:</b></div>
                                <div className="col">{this.timeStampToDateAndTime(parseInt(details[1]))}</div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col">YES votes</div>
                                <div className="col">{parseInt(details[2]>0 ? 
                                    <span>(parseInt(details[2])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                    )}
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">NO votes</div>
                                <div className="col">{parseInt(details[3])>0 ?
                                    <span>(parseInt(details[3])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                    }
                                    %
                                </div>
                            </div>

                            {parseInt(details[1])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton' onClick={()=>this.openVoteOnUpgradeProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                null
                            }
                            {parseInt(details[1])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={this.props.Upgrader_ABI}
                                    contractAddress={this.props.Upgrader_Address}
                                    functionName="closeUpgradeVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {this.onSuccessCloseUpgradeVote}
                                />
                                
        
                                :
                                null
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }


        let assetOutput
        if (typeof(this.props.assetDetails) != 'undefined' && typeof(this.props.assets) != 'undefined'){
            console.log(this.props.assetDetails);
            assets = this.getAssets();
            let freezableAssets = []
            for (let i = 0; i <= assets.length-1; i++) {
                let assetSymbol = assets[i]
                console.log(assetSymbol)
                let details = this.props.assetDetails[assetSymbol]
                let frozen = details['frozen']
                if (frozen === false){freezableAssets.push(assetSymbol)}
            }
            console.log(assets);
            assetOutput = freezableAssets.map((asset,index) =>
                <div key ={index} className="governanceAssetBox">
                    <p><b>{this.props.assetDetails[asset][2]}</b></p>
                    <p>{this.props.assetDetails[asset]['description']}</p>
                    <p>Upper Limit: {(parseFloat(this.props.assetDetails[asset]['upperLimit'])/1000).toLocaleString()} USD</p>
                    <br/>
                    <b>Your position:</b>
                    <p>{asset}: {parseFloat(this.props.assetDetails[asset]['tokenBalance1']).toFixed(6)}</p>
                    <p>short{asset}: {parseFloat(this.props.assetDetails[asset]['tokenBalance2']).toFixed(6)}</p>
                    
                
                    {this.props.assetDetails[asset]['votesEndingTime'] > Date.now()/1000 && this.props.assetDetails[asset]['voteOpen'] && this.props.assetDetails[asset]['hasVoted'] === false
                        ? 
                        <div>Vote if upper limit has been breached 
                            <Button className='issuaaButton'  onClick={()=>this.openVoteModal(asset)}>
                                Vote
                            </Button>
                            <div>Vote closes: {this.timeStampToDateAndTime(parseInt(this.props.assetDetails[asset]['votesEndingTime']))}</div>
                        </div>
                        
                        :
                        null
                    }
                    {this.props.assetDetails[asset]['votesEndingTime'] < Date.now()/1000 && this.props.assetDetails[asset]['voteOpen']
                        ? 
                        
                        <TransactionButton
                            abi={this.props.VoteMachine_ABI}
                            contractAddress={this.props.VoteMachine_Address}
                            functionName="closeFreezeVote"
                            args={[asset]}
                            text="Close Vote"
                            onSuccess = {this.onSuccessCloseFreezeVote}
                        /> 
                        :
                        null 
                    }
                    {this.props.assetDetails[asset]['voteOpen'] !== true && this.props.assetDetails[asset]['frozen'] === false && this.props.assetDetails[asset]['expiryTime'] > Date.now()/1000  && this.props.GovernanceTokenStakeBalance >100000
                        ? 
                        <div>
                            Upper limit breached?<br />
                            <Button className='governanceButtonBlack w-100' onClick={()=>this.openFreezeModal(asset)}>Initiate Freeze Vote</Button>
                        </div> 
                        :
                        null
                    }

                    {this.props.assetDetails[asset]['expiryTime'] < Date.now()/1000 && 
                    this.props.assetDetails[asset]['frozen'] === false &&
                    this.props.assetDetails[asset]['hasVotedOnExpiry'] === false &&
                    this.props.assetDetails[asset]['expiryVoteEndingTime'] > Date.now()/1000  &&
                    this.props.assetDetails[asset]['expiryVoteOpen'] === true
                        ? 
                        <div>
                            <Button className='issuaaButton'  onClick={()=>this.openFinalPriceModal(asset)}>Vote on the expiry price</Button>
                        </div>
                        :
                        null
                    }

                    {this.props.assetDetails[asset]['expiryTime'] < Date.now()/1000 && 
                    this.props.assetDetails[asset]['frozen'] === false &&
                    this.props.assetDetails[asset]['expiryVoteOpen'] === false &&
                    parseInt(this.props.assetDetails[asset]['endOfLifeValue']) === 0
                        ? 
                        <div>
                            <Button className='issuaaButton'  onClick={()=>this.openInitiateExpiryVoteModal(asset)}>
                                Initiate price discovery
                            </Button>
                        </div>
                        :
                        null 
                    }


                    {this.props.assetDetails[asset]['expiryVoteEndingTime'] < Date.now()/1000 && 
                    this.props.assetDetails[asset]['expiryVoteOpen'] === true
                        ? 
                        <div>
                            <TransactionButton
                                abi={this.props.VoteMachine_ABI}
                                contractAddress={this.props.VoteMachine_Address}
                                functionName="closeEndOfLifeVote"
                                args={[asset]}
                                text="Close Vote"
                                onSuccess = {this.onSuccessCloseExpiryVote}
                            /> 
                            
                        </div>
                        :
                        null 
                    }
                </div>    
            );  
        } ;   

        
        
       

        return ( 
            
            <div className="mainContainer">

                <Modal show={this.state.initiateUpgradeProposal} onHide={this.closeUpgradePropsal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Propose an Upgrade to the existing smart contracts of TWIN:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                            
                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Which smart contract would you like to upgrade?&nbsp;</b>
                                <OverlayTrigger placement="right" overlay={tooltip8}>
                                    <InfoOutlinedIcon/>
                                </OverlayTrigger>
                            </div>
                            
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='text' id='smartContractToUpgrade' onChange={() =>this.checkNewUpgradeInput()}></input>
                                
                            </div>
                        </div>

                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Please insert the address of the new smart contract:&nbsp;</b>
                            </div>                                
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='text' id='newSmartContractAddress' onChange={() =>this.checkNewUpgradeInput()}></input>
                            </div>
                        </div>


                        
                            
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <TransactionButton
                            abi={this.props.Upgrader_ABI}
                            contractAddress={this.props.Upgrader_Address}
                            functionName="initiateUpgradeVote"
                            args={[this.state.newSmartContractAddress,this.state.smartContractToUpgrade]}
                            text="Submit Proposal"
                            onSuccess = {this.onSuccessNewUpgradeProposal}
                        />  
        
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.initiateGrantProposal} onHide={this.closeGrantPropsal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Initiate a new DAO grant proposal:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                            
                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>How many TWIN are you requesting?&nbsp;</b>
                                <OverlayTrigger placement="right" overlay={tooltip8}>
                                    <InfoOutlinedIcon/>
                                </OverlayTrigger>
                            </div>
                            
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='number' id='grantAmount' onChange={() =>this.checkNewGrantInput()}></input>
                                
                            </div>
                        </div>

                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Please describe what you will do for the proposed grant, which helps the TWIN protocol to foster:&nbsp;</b>
                            </div>                                
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <textarea className="w-100" id='grantDescription' rows="7" onChange={() =>this.checkNewGrantInput()}></textarea>
                            </div>
                        </div>


                        
                            
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <TransactionButton
                            abi={this.props.DAO_ABI}
                            contractAddress={this.props.DAO_Address}
                            functionName="initiateGrantFundingVote"
                            args={[this.props.address,this.state.grantAmount,this.state.description]}
                            text="Submit Proposal"
                            onSuccess = {this.onSuccessNewGrantProposal}
                        />  
                        
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.initiateNewAssetProposal} onHide={this.closeNewAssetPropsalModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Propose a new TWIN Asset:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                            
                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Name of underlying asset&nbsp;</b>
                                <OverlayTrigger placement="right" overlay={tooltip4}>
                                    <InfoOutlinedIcon/>
                                </OverlayTrigger>
                            </div>
                            
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='text' id='proposedName' onChange={() =>this.checkNewAssetProposalInput()}></input>
                                
                            </div>
                        </div>

                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                            <b>Symbol&nbsp;</b>
                            <OverlayTrigger classname="border" placement="right" overlay={tooltip5}>
                                <InfoOutlinedIcon/>
                            </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='text' id='proposedSymbol' onChange={() =>this.checkNewAssetProposalInput()}></input>
                            </div>
                        </div>  

                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Description&nbsp;</b>
                                <OverlayTrigger placement="right" overlay={tooltip6}>
                                    <InfoOutlinedIcon/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <textarea rows="5" className="form-control" type='text' id='proposedDescription' onChange={() =>this.checkNewAssetProposalInput()}></textarea>
                            </div>
                        </div>  

                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Upper Limit&nbsp;</b>
                                <OverlayTrigger placement="right" overlay={tooltip7}>
                                    <InfoOutlinedIcon/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='number' id='proposedUpperLimit' onChange={() =>this.checkNewAssetProposalInput()}></input>
                            </div>
                        </div>    
                                
                                
                            
                            
                            
                    </Modal.Body>
                    <Modal.Footer>
                    <TransactionButton
                        abi={this.props.DAO_ABI}
                        contractAddress={this.props.DAO_Address}
                        functionName="initiateNewAssetVote"
                        args={[this.state.proposedSymbol,this.state.proposedName,this.state.proposedUpperLimit,this.state.proposedDescription]}
                        text="Submit Proposal"
                        onSuccess = {this.onSuccessNewAssetProposal}
                    />    
                       
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.voteOnGrantProposalModal} onHide={this.closeVoteOnGrantProposalModal}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                            <div className="row p-2">Vote on the following Grant Request:</div>
                            <div className="row px-2">Receiver: {this.state.selectedGrantVote}</div>
                            <div className="row px-2">Amount: {(parseInt(this.state.selectedGrantVoteAmount)/(10**18))} TWIN</div>
                            <div className="row px-2">description: {this.state.selectedGrantVoteDescription}</div>
                    </Modal.Body>
                    <Modal.Footer>
                    <TransactionButton
                        abi={this.props.DAO_ABI}
                        contractAddress={this.props.DAO_Address}
                        functionName="voteGrantFundingVote"
                        args={[this.state.selectedGrantVote,true]}
                        text="Yes"
                        onSuccess = {this.onSuccessVoteGrantProposal}
                    /> 
         
                    <TransactionButton
                        abi={this.props.DAO_ABI}
                        contractAddress={this.props.DAO_Address}
                        functionName="voteGrantFundingVote"
                        args={[this.state.selectedGrantVote,false]}
                        text="No"
                        onSuccess = {this.onSuccessVoteGrantProposal}
                    />
                    
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.voteOnUpgradeProposalModal} onHide={this.closeVoteOnUpgradeProposalModal}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                            <div className="row p-2">Vote on the following Upgrade Proposal:</div>
                            <div className="row px-2">Contract to be replaced: {this.state.selectedContractToUpgrade}</div>
                            <div className="row px-2">New Implmentation Address: {this.state.selectedNewContractAddress}</div>

                            
                    </Modal.Body>
                    <Modal.Footer>
                    
                    <TransactionButton
                        abi={this.props.Upgrader_ABI}
                        contractAddress={this.props.Upgrader_Address}
                        functionName="voteUpgradeVote"
                        args={[this.state.selectedUpgradeVote,true]}
                        text="Yes"
                        onSuccess = {this.onSuccessVoteUpgradeProposal}
                    /> 
         
                    <TransactionButton
                        abi={this.props.Upgrader_ABI}
                        contractAddress={this.props.Upgrader_Address}
                        functionName="voteUpgradeVote"
                        args={[this.state.selectedUpgradeVote,false]}
                        text="No"
                        onSuccess = {this.onSuccessVoteUpgradeProposal}
                    />
                    
                    
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.voteOnNewAssetProposalModal} onHide={this.closeVoteOnNewAssetProposalModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Vote on the following proposed new Asset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div className="row p-2"></div>
                            <div className="row px-2">Symbol: {this.state.selectedNewAssetVote}</div>
                            <div className="row px-2">Name: {this.state.selectedNewAssetName}</div>
                    </Modal.Body>
                    <Modal.Footer>
                    <TransactionButton
                        abi={this.props.DAO_ABI}
                        contractAddress={this.props.DAO_Address}
                        functionName="voteNewAssetVote"
                        args={[this.state.selectedNewAssetVote,true]}
                        text="Yes"
                        onSuccess = {this.onSuccessVoteNewAssetProposal}
                    /> 
         
                    <TransactionButton
                        abi={this.props.DAO_ABI}
                        contractAddress={this.props.DAO_Address}
                        functionName="voteNewAssetVote"
                        args={[this.state.selectedNewAssetVote,false]}
                        text="No"
                        onSuccess = {this.onSuccessVoteNewAssetProposal}
                    />  
                    
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.initiateExpiryVoteModalOpen} onHide={this.closeInitiateExpiryVoteModal}>
                    <Modal.Header closeButton>
                        <h3>Initiating expiry vote</h3>
                    </Modal.Header>
                        <Modal.Body>
                            Do you want to initiate the expiry price voting process for {this.state.assetToInitiateExpiryVote}?
                        </Modal.Body>
                    <Modal.Footer>
                        <TransactionButton
                            abi={this.props.VoteMachine_ABI}
                            contractAddress={this.props.VoteMachine_Address}
                            functionName="initiateEndOfLifeVote"
                            args={[this.state.assetToInitiateExpiryVote]}
                            text="Confirm"
                            onSuccess = {this.onSuccessInitiateExpiryVote}
                        />
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.finalPriceModalOpen} onHide={this.closeFinalPriceModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Vote on expiry price</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            Please vote on the price at expiry of the following asset: {this.state.assetToVoteOnFinalPrice}
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" for="amountToStake">Expiry price:</label>
                                </div>
                                <input className="form-control" type='number' id='expiryPrice' onChange={() =>this.checkExpiryPrice()}></input>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <TransactionButton
                            abi={this.props.VoteMachine_ABI}
                            contractAddress={this.props.VoteMachine_Address}
                            functionName="voteOnEndOfLifeValue"
                            args={[this.state.assetToVoteOnFinalPrice,this.state.expiryPriceToSubmit]}
                            text="Confirm"
                            onSuccess = {this.onSuccessExpiryPriceVote}
                        />

                        
                    </Modal.Footer>
                </Modal>

                

                <Modal show={this.state.freezeModalOpen} onHide={this.closeFreezeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Notify that an asset has frozen:</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            Are you sure that you want to initiate the freeze process for {this.state.assetToFreeze}?
                        </Modal.Body>
                    <Modal.Footer>
                    
                    <TransactionButton
                        abi={this.props.VoteMachine_ABI}
                        contractAddress={this.props.VoteMachine_Address}
                        functionName="initiateFreezeVote"
                        args={[this.state.assetToFreeze]}
                        text="Confirm"
                        onSuccess = {this.onSuccessInitiateFreeze}
                    />
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.voteModalOpen} onHide={this.closeVoteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Vote:</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            Has  {this.state.assetToVote} breached its upper limit?
                        </Modal.Body>
                    <Modal.Footer>
                    <TransactionButton
                        abi={this.props.VoteMachine_ABI}
                        contractAddress={this.props.VoteMachine_Address}
                        functionName="voteFreezeVote"
                        args={[this.state.assetToVote,true]}
                        text="Yes"
                        onSuccess = {this.onSuccessVoteFreeze}
                    /> 
         
                    <TransactionButton
                        abi={this.props.VoteMachine_ABI}
                        contractAddress={this.props.VoteMachine_Address}
                        functionName="voteFreezeVote"
                        args={[this.state.assetToVote,false]}
                        text="No"
                        onSuccess = {this.onSuccessVoteFreeze}
                    /> 
        
                    </Modal.Footer>
                </Modal>

                
                <div className="middleBoxFull">
                    <div className="">
                        
                            <div className="mainBox">
                                <div className="governanceIntroText">
                                    As a decentralized autonomous organization (DAO) the TWIN protocol is governed exclusively via voting procedures 
                                    by the community of owners of the TWIN Protocol Token (TWIN). 
                                    One staked TWIN represents one vote in TWIN governance votings.
                                </div>
                                <div className="infoRow">
                                    <div className="governanceBox governanceBox20" onClick={()=>this.changeView('assets')} role="button">
                                        Freeze- and Expiry votings for TWIN Assets &nbsp;
                                        <OverlayTrigger placement="top" overlay={tooltip1}>
                                            <InfoOutlinedIcon/>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="governanceBox governanceBox40" onClick={()=>this.changeView('newAsset')} role="button">
                                        
                                        New TWIN Asset proposals & votings&nbsp;
                                        <OverlayTrigger placement="top" overlay={tooltip2}>
                                            <InfoOutlinedIcon/>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="governanceBox governanceBox60" onClick={()=>this.changeView('grantVotes')} role="button">
                                        TWIN DAO grant proposals & votings&nbsp;
                                        <OverlayTrigger placement="top" overlay={tooltip3}>
                                            <InfoOutlinedIcon/>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="governanceBox governanceBox80" onClick={()=>this.changeView('upgrade')} role="button">
                                        Smart Contract Code Upgrade Votes&nbsp;
                                        <OverlayTrigger placement="top" overlay={tooltip4}>
                                            <InfoOutlinedIcon/>
                                        </OverlayTrigger>
                                    </div>
                                    
                                    

                                </div>

                            </div>
                            <div className="governanceOutput">
                                {this.state.view === "assets" ?
                                    <div>{assetOutput}</div> 
                                : 
                                null
                                }

                                {this.state.view === "newAsset" ?
                                    <div className="w-100">
                                        <div className="row m-0">
                                            {this.state.proposeNewAssetButtonVisible ?
                                                <div role="button" className="governanceButtonLightGreen w-100" onClick={()=>this.openNewAssetPropsalModal()}>You have an idea for a great new asset to add? Propose it!</div>
                                                :
                                                null
                                            }

                                        </div>
                                        <div className="pl-0 ml-0 row p-0 mr-0 pr-0" id="3">
                                            <div className="row ml-0 mr-0 w-100 my-auto py-4">                                                    
                                                {showNewAssetProposals}
                                            </div>
                                        </div>
                                        

                                    
                                    </div>
                                : 
                                ''
                                }
                                
                                {this.state.view === "grantVotes" ?
                                    <div className="w-100">
                                        <div className="row m-0">
                                            {this.state.requestGrantButtonVisible ?
                                                <div role="button" className="governanceButtonLightGreen w-100" onClick={()=>this.openGrantPropsal()}>Are you planning on doing something that helps the TWIN protocol? Request a DAO grant!</div>
                                                :
                                                ''
                                            }

                                        </div>
                                        <div className="pl-0 ml-0 row p-0 mr-0 pr-0" id="3">
                                            <div className="row ml-0 mr-0 w-100 my-auto py-4">
                                                
                                                {showDAOvotes}
                                            </div>
                                        </div>
                                       
                                    </div>

                                :
                                    null
                                }

                                {this.state.view === "upgrade" ?

                                    <div className="w-100">
                                    
                                        <div className="row m-0">

                                            {this.props.GovernanceTokenStakeBalance > 100000 ?
                                                <div role="button" className="governanceButtonLightGreen w-100"onClick={()=>this.openUpgradePropsal()}>Propose an upgrade to the existing smart contracts!</div>
                                                    
                                                :
                                                null
                                            }

                                        </div>
                                        <div className="pl-0 ml-0 row p-0 mr-0 pr-0" id="3">
                                            <div className="row ml-0 mr-0 w-100 my-auto py-4">
                                                
                                                {showUpgradevotes}
                                            </div>
                                        </div>
                                    
                                    </div>

                                :
                                    null
                                }
                            </div>
                        

                    </div>
                        
                               
                    
                </div>    
            </div>
             


            
         );
    }
}
 
export default Governance;