import '../styles/portfolio.css';
import React, { Component } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import bamboo from '../img/graphics/Bamboo3.png';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from 'react-router-dom';

class Portfolio extends Component {
    state = { 
    assetDetails: null,       
    };
    
    async componentDidMount() {
        this.setState({
            assetDetails: this.props.assetDetails,
            assets: this.props.assets
        });
        this.loadPortfolio();
        
    };

    componentDidUpdate(prevProps) {
        if (prevProps.blockchainDataLoaded === false && this.props.blockchainDataLoaded) {
          this.loadPortfolio();
        }
      }

    getAssets() {
        let assets = this.props.assets;
        return assets;
    };
    getAssetsAll() {
        let allAssets = [];
        let assets = this.props.assets;
        for (let i = 0; i < assets.length; ++i) {
            //newAsset = this.props.assetDetails[]
            allAssets.push(assets[i])
            allAssets.push("i"+assets[i])
        }
        return allAssets;
    };

    
    loadPortfolio = async() => {
        var portfolio = {}
        var portfolioAssets =[]
        portfolio["HONEY"] = {}
        if (this.props.USDCBalance >0.01){
            portfolio["HONEY"]['name'] = this.props.stableCoinName
            portfolio["HONEY"]['balance'] = this.props.USDCBalance
            portfolio["HONEY"]['expiryDate'] = ""
            portfolio["HONEY"]['upperLimit'] = ""
            portfolio["HONEY"]['isFrozen'] = ""
            portfolio["HONEY"]['price'] = 1
            portfolio["HONEY"]['portfolioValue'] = this.props.USDCBalance
            portfolioAssets.push("HONEY")
        }

        portfolio["TWIN"] = {}
        if (this.props.GovernanceTokenTotalBalance >0.01){
            portfolio["TWIN"]['name'] = "TWIN Protocol Token"
            portfolio["TWIN"]['balance'] = this.props.GovernanceTokenTotalBalance
            portfolio["TWIN"]['expiryDate'] = ""
            portfolio["TWIN"]['upperLimit'] = ""
            portfolio["TWIN"]['isFrozen'] = ""
            portfolio["TWIN"]['price'] = this.props.ISSPrice
            portfolio["TWIN"]['portfolioValue'] = this.props.ISSPrice * this.props.GovernanceTokenTotalBalance
            portfolio["TWIN"]['marketPair'] = '0xB526c7B7bb3ab7d8CBccdf6e6eCD2EAe9b8276bc'

            portfolioAssets.push("TWIN")
        }

        

        var assets = this.getAssets();
        
        if (typeof(this.props.assetDetails) != 'undefined' && typeof(this.props.assets) != 'undefined' && typeof(this.props.USDC_Address) && typeof(this.props.MarketFactory) != 'undefined'){
            for (let i = 0; i < assets.length; ++i) {
                
                let asset=assets[i]
                if (parseFloat(this.props.assetDetails[asset]['tokenBalance1'])>0.0000001) {
                    // Get the token price
                    let tokenAddress = this.props.assetDetails[asset][0]
                    //console.log(this.props)
                    let pair = await this.props.MarketFactory.methods.getPair(tokenAddress,this.props.USDC_Address).call();
                    let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair);
                    let reserves = await MarketPair.methods.getReserves().call();
                    let token0 = await MarketPair.methods.token0().call();
                    let tokenPrice
                    if (token0 === this.props.USDC_Address) {
                        tokenPrice = Number(reserves[0])*(10**(18-this.props.USDDecimals))/Number(reserves[1])
                    }
                    else{
                        tokenPrice = Number(reserves[1])/Number(reserves[0])*(10**(18-this.props.USDDecimals))
                    }


                    

                    portfolio[asset] = {}
                    portfolio[asset]['name'] = this.props.assetDetails[asset]['name']
                    portfolio[asset]['balance'] = this.props.assetDetails[asset]['tokenBalance1']
                    portfolio[asset]['expiryDate'] = this.props.assetDetails[asset]['expiryTime']
                    portfolio[asset]['upperLimit'] = this.props.assetDetails[asset]['upperLimit']
                    portfolio[asset]['isFrozen'] = this.props.assetDetails[asset]['frozen']
                    let price = tokenPrice
                    portfolio[asset]['price'] = price
                    portfolio[asset]['portfolioValue'] = parseFloat(this.props.assetDetails[asset]['tokenBalance1']) * price
                    portfolio[asset]['marketPair'] = pair
                    portfolioAssets.push(assets[i])
                }
                if (parseFloat(this.props.assetDetails[asset]['tokenBalance2'])>0.0000001) {
                    // Get the token price
                    let tokenPrice1
                    let tokenAddress = this.props.assetDetails[asset][1]
                    let pair = await this.props.MarketFactory.methods.getPair(tokenAddress,this.props.USDC_Address).call();
                    let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair);
                    let reserves = await MarketPair.methods.getReserves().call();
                    let token0 = await MarketPair.methods.token0().call();
                    if (token0 === this.props.USDC_Address) {
                        tokenPrice1 = Number(reserves[0])*(10**(18-this.props.USDDecimals))/Number(reserves[1])
                    }
                    else{
                        tokenPrice1 = Number(reserves[1])/Number(reserves[0])*(10**(18-this.props.USDDecimals))
                    }

                    portfolio["i"+asset] = {}
                    portfolio["i"+asset]['name'] = "Inverse "+this.props.assetDetails[asset]['name']
                    portfolio["i"+asset]['balance'] = this.props.assetDetails[asset]['tokenBalance2']
                    portfolio["i"+asset]['expiryDate'] = this.props.assetDetails[asset]['expiryTime']
                    portfolio["i"+asset]['upperLimit'] = this.props.assetDetails[asset]['upperLimit']
                    portfolio["i"+asset]['isFrozen'] = this.props.assetDetails[asset]['frozen']
                    let price = tokenPrice1
                    portfolio["i"+asset]['price'] = price
                    portfolio["i"+asset]['portfolioValue'] = parseFloat(this.props.assetDetails[asset]['tokenBalance2']) * price
                    portfolio["i"+asset]['marketPair'] = pair
                    portfolioAssets.push("i"+assets[i])
                }

            }
            this.setState({portfolio})
            this.setState({portfolioAssets})
            console.log(portfolio)
        }
        
    }

    timeStampToDate(timestamp) {
        var date = new Date(timestamp * 1000)
        const options = {year: '2-digit', month: '2-digit', day: '2-digit' };
        let formattedDate = date.toLocaleDateString(undefined,options);
        //console.log(formattedDate)
        return(formattedDate);
    }

    render() { 
        
        //console.log(this.state.portfolio)
        const tooltip1 = props => (
            <Tooltip {...props}>
            Your liquidity provider tokens reflecting your assets and cash locked in the TWIN Protocol pools.
            </Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
            Your TWIN Assets portfolio available for trading; the exception are your TWIN tokens, which might partly be locked.
            </Tooltip>
        );


        //var AssetOptions = ['loading']
        let assetOutput
        if (typeof(this.state.portfolio) != 'undefined' && typeof(this.state.portfolioAssets) != 'undefined'){
            assetOutput = this.state.portfolioAssets.map((asset,index) =>

                    
                        <tr key ={index}>
                            <td className="text-left">{asset}</td>
                            <td className="text-left">{this.state.portfolio[asset]['name']}</td>
                            <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset]['price']),2)}</td>
                            <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset]['balance']),4)}</td>
                            <td className="text-right">{(asset !== "TWIN" & asset !== "HONEY") ? this.timeStampToDate(parseInt(this.state.portfolio[asset]['expiryDate'])):'n.a.'}</td>
                            <td className="text-right">{(asset !== "TWIN" & asset !== "HONEY") ? this.props.outputNumber((parseFloat(this.state.portfolio[asset]['upperLimit'])/1000),2):'n.a.'}</td>
                            {this.state.portfolio[asset]['frozen']?
                                <td className="text-right">frozen</td>
                                :
                                <td className="text-right"><div className='portfolioStatusLive'>LIVE</div></td>
                            }
                            <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset]['portfolioValue']),0)}</td>
                            <td>
                                {this.state.portfolio[asset]['name'] !== this.props.stableCoinName ?
                                    <div className="d-flex flex-row">
                                        <Link to={`/trade/buy/${this.state.portfolio[asset]['marketPair']}`}>
                                            <div className="buyButton">Buy</div>
                                        </Link>
                                        <Link to={`/trade/sell/${this.state.portfolio[asset]['marketPair']}`}>
                                            <div className="sellButton">Sell</div>
                                        </Link>
                                    </div>
                                :''}
                            </td>
                            
                        </tr>

                        
               
            );  
        } ;   

        let LPOutput
        let myPools = []
        if (typeof(this.props.pools) !== 'undefined' & this.props.assetDetails !== 'undefined'){
            
            for (let i=0; i<this.props.pools.length; i++) {
                //console.log(this.props.pools[i])
                if (((this.props.pools[i][4]/this.props.pools[i][5])*this.props.pools[i][2]) >= 0.1){
                    myPools.push(this.props.pools[i])
            }
            console.log(myPools)
            LPOutput = myPools.map((pool,index) =>
                        <tr key ={index}>
                            <td className="text-left">{pool[0]}</td>
                            <td className="text-left">{pool[3]}</td>
                            <td className="text-right">{this.props.outputNumber((parseFloat(pool[4])/1e18),8)}</td>
                            <td className="text-right">{this.props.outputNumber((((parseFloat(pool[4])/parseFloat(pool[5]))*pool[8])/1e18),3)} {pool[0]} & {this.props.outputNumber((((parseFloat(pool[4])/parseFloat(pool[5]))*pool[2]*0.5)/1e18),0)} {this.props.stableCoinName}</td>
                            <td className="text-right">{((parseFloat(pool[4])/parseFloat(pool[5]))*100).toFixed(2)}%</td>
                            <td className="text-right">{this.props.outputNumber((((parseFloat(pool[4])/parseFloat(pool[5]))*parseFloat(pool[2]))),0)}</td>
                            
                        </tr>                        
            );  
            }        
        }
        let farmOutput
        let myFarms = []
        if (typeof(this.props.farms) !== 'undefined' & this.props.assetDetails !== 'undefined'){
            for (let i=0; i<this.props.farms.length; i++) {
                let farm = this.props.farms[i]
                let userValue = farm['userAmount'] * farm['tvl'] / farm['totalSupply'] /1e18
                if (userValue >= 0.1){
                    myFarms.push(farm)
                }
            
            farmOutput = myFarms.map((farm,index) =>

                        <tr key ={index}>
                            <td className="text-left">{farm['symbol']}</td>
                            <td className="text-left">{farm['name']}</td>
                            <td className="text-right">{this.props.outputNumber(farm['tokenReserves']*farm['userAmount']/farm['totalAmount']/1e18,4)} {farm['symbol']}  / {this.props.outputNumber(farm['USDCReserves']*farm['userAmount']/farm['totalAmount']/1e6,0)} {this.props.stableCoinName}</td>
                            <td className="text-right">{this.props.outputNumber(farm['userAmount']*100/farm['totalAmount'],0)}%</td>
                            <td className="text-right">{this.props.outputNumber(farm['maxAPR']*40,0)}% - {this.props.outputNumber(farm['maxAPR']*100,0)}%</td>
                            <td className="text-right">{this.props.outputNumber(farm['maxAPR']*40*farm['boostFactor']/1e12,0)}%</td>
                            <td className="text-right">{this.props.outputNumber(farm['userAmount'] * farm['tvl'] / farm['totalSupply'] /1e18,0)}</td>
                            
                        </tr>                        
            );  
            }        
        }        
        
       
        return ( 
            
            <div className="mainContainer">
                
                <div className="middleBoxFull"> 
                    <div className="contentContainer">
                        <div className="portfolioTotalValueBox">
                            <div className='infoBoxIcon'><AttachMoneyOutlinedIcon/></div>
                            <div className="infoBoxItem">{this.props.outputNumber(this.props.totalValue,0)} USD</div>
                            <div className='infoBoxItem2'>Total portfolio value</div>
                        </div>
                        <div className='portfolioAssetBox'>           
                            <div className="portfolioBoxHeader">
                                <div className="">Your Assets</div>
                            </div>
                            <div className="row mainPortfolioContent">    
                                <div className="pl-3 pr-3  w-100">
                                    <table className="w-100">
                                        <tr className="">
                                            <th className="text-left" scope="col">Symbol</th>
                                            <th className="text-left" scope="col">Name</th>
                                            <th className="text-right" scope="col">Price</th>                                            
                                            <th className="text-right" scope="col">Position</th>
                                            <th className="text-right" scope="col">Expiry Date</th>
                                            <th className="text-right" scope="col">Upper limit</th>
                                            <th className="text-right" scope="col">Status?</th>
                                            <th className="text-right" scope="col">Value (USD)</th>
                                            <th className="text-right" scope="col"></th>
                                        </tr>
                                    
                                        <tbody>
                                            
                                        {assetOutput}
                                        <tr>
                                            <td className="text-left"><b>Total Assets</b></td>
                                            <td className="text-right"></td>
                                            <td></td>                                            
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right"><b>{this.props.outputNumber(this.props.assetValue,0)}</b></td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div className='portfolioAssetBox'>           
                            <div className="portfolioBoxHeader">
                                <div className="">Your liquidity provider tokens</div>
                            </div>
                            <div className="row mainPortfolioContent">    
                                <div className="pl-3 pr-3 w-100">
                                    <table className="w-100">
                                        <tr className="">
                                            <th className="text-left" scope="col">Symbol</th>
                                            <th className="text-left" scope="col">Name</th>
                                            <th className="text-right" scope="col">LP-Balance</th>
                                            <th className="text-right" scope="col">Withdrawable Assets</th>                                            
                                            <th className="text-right" scope="col">Pool share</th>
                                            <th className="text-right" scope="col">Value (USD)</th>
                                        </tr>
                                        <tbody>
                                        {LPOutput}
                                        <tr>
                                            <td className="text-left"><b>Total LP tokens</b></td>
                                            <td></td>
                                            <td></td>                                            
                                            <td></td>
                                            <td></td>
                                            <td className="text-right"><b>{this.props.outputNumber(this.props.LPValue,0)}</b></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>  
                            </div>                                    
                        </div>

                                    {this.props.farmActive ?
                                    <div className="row mt-5">
                                        <div className="h-5 pl-3 py-0">
                                            <h5>
                                                <OverlayTrigger placement="right" overlay={tooltip1}>
                                                    <InfoOutlinedIcon />
                                                </OverlayTrigger>
                                                Your liquidity provider token farms:
                                                
                                            </h5>
                                            
                                        </div>
                                    </div>
                                        :
                                        ''
                                    }
                                    {this.props.farmActive ?
                                    <div className="row">    
                                        <div className="pl-3 pr-3 w-100">
                                            <table className="w-100">
                                                <tr className="">
                                                    <th className="text-left" scope="col">Symbol</th>
                                                    <th className="text-left" scope="col">Name</th>
                                                    <th className="text-right" scope="col">Withdrawable Assets</th>                                            
                                                    <th className="text-right" scope="col">Farm share</th>
                                                    <th className="text-right" scope="col">Pool yield</th>
                                                    <th className="text-right" scope="col">Your yield</th>
                                                    <th className="text-right" scope="col">Value (USD)</th>
                                                </tr>
                                                <tbody>
                                                {farmOutput}
                                                <tr>
                                                    <td className="text-left"><b>Total LP token farms</b></td>
                                                    <td></td>
                                                    <td></td>                                            
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right"><b>{this.props.outputNumber(this.props.farmsValue/1e6,0)}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>  
                                    </div>
                                    :''}
                                
                            
                        
                        
                    </div>
                </div>      
                </div>
             


            
         );
    }
}
 
export default Portfolio;