import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import logo from './img/graphics/TWIN_Sublogo.png';
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {USDC_ABI, assetFactory_ABI, ERC20_ABI, GovernanceToken_ABI, VoteMachine_ABI,MarketFactory_ABI,MarketRouter_ABI,MarketPair_ABI,RewardsMachine_ABI,DAO_ABI,Upgrader_ABI,VotingEscrow_ABI} from './config';
import {USDC_Address_b, IDT_Address_b,assetFactory_Address_b,GovernanceToken_Address_b, VoteMachine_Address_b,MarketFactory_Address_b,MarketRouter_Address_b,RewardsMachine_Address_b,DAO_Address_b,Upgrader_Address_b,VotingEscrow_Address_b} from './config';
import {USDC_Address_ba, IDT_Address_ba, assetFactory_Address_ba,GovernanceToken_Address_ba, VoteMachine_Address_ba,MarketFactory_Address_ba,MarketRouter_Address_ba,RewardsMachine_Address_ba,DAO_Address_ba,Upgrader_Address_ba, VotingEscrow_Address_ba} from './config';
//import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAccount, useNetwork } from "wagmi";

import Mainpage from './components/Mainpage';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; 
import Factory from './components/Factory';
import Portfolio from './components/Portfolio';
import Points from './components/Points';
import BurnAssets from './components/BurnAssets';
import MessageBox from './components/MessageBox';
import GovernanceTokenPage from './components/GovernanceTokenPage';
import Market from './components/Market';
import Pool from './components/Pool';
import Governance from './components/Governance';
import Test from './components/Test';
import IDTConversion from './components/IDTConversion';
import SidebarElement from './components/Sidebar';
import ChangeChain from './components/ChangeChain';

import {timeStampToDate, outputNumber, timeStampToDateAndTime,sleep,roundDown} from './functions/Functions.jsx'
import {getUserData,getGovernanceTokenData,getAssetData,getPrice,getAssetBalances,getLPBalances,getSingleAssetBalance,getAssetBalancesFromTicker,getLPBalancesFromTicker} from './functions/BlockchainData.jsx'


function App() {
  
  const location = useLocation();
  console.log(location)
  
  // State Variables
  const stableCoinName = "HONEY"
  const USDDecimals = 18; 
  const [activePage, setActivePage] = useState('mainpage');
  const [address,setAddress] = useState();
  const [assetBalancesLoaded,setAssetBalancesLoaded] = useState(false)
  const [assetDetails,setAssetDetails] = useState();  
  const [assetFactory,setAssetFactory] = useState();
  const [assetFactory_Address,setAssetFactory_Address] = useState();
  const [assets,setAssets] = useState();  
  const [assetValue,setAssetValue] = useState();  
  const [chainData,setChainData] = useState(); 
  const [chainName,setChainName] = useState();
  const [chainDetailsLoaded,setChainDetailsLoaded] = useState(false);
  const [blockchainDataLoaded,setblockchainDataLoaded] = useState(false);
  const [fullProtocolIntegration,setFullProtocolIntegration] = useState(false);
  const [DAO_Address,setDAO_Address] = useState();
  const [expiredAssets,setExpiredAssets] = useState();
  const [GovernanceToken,setGovernanceToken] = useState();
  const [GovernanceToken_Address,setGovernanceToken_Address] = useState();
  const [GovernanceTokenBalance,setGovernanceTokenBalance] = useState();
  const [GovernanceTokenTotalBalance,setGovernanceTokenTotalBalance] = useState();
  const [GovernanceTokenTotalBalanceAllChains,setGovernanceTokenTotalBalanceAllChains] = useState();
  const [GovernanceTokenStakeBalance,setGovernanceTokenStakeBalance] = useState();
  const [GovernanceTokenVestedStakeBalance,setGovernanceTokenVestedStakeBalance] = useState();
  const [IDT_nm,setIDT_nm] = useState();
  const [IDTBalance,setIDTBalance] = useState();
  const [IDTBalanceWei,setIDTBalanceWei] = useState();
  const [ISSPrice,setISSPrice] = useState();
  const [ISSMarketCap,setISSMarketCap] = useState();
  const [ISSMarketCapCurrent,setISSMarketCapCurrent] = useState();
  const [ISSSupply,setISSSupply] = useState();
  const [ISSSupplyCurrent,setISSSupplyCurrent] = useState();
  const [liveAssets,setLiveAssets] = useState();
  const [loadingBlockchainData,setLoadingBlockchainData] = useState(true);
  const [loadingData,setLoadingData] = useState(true);
  const [lockDate,setLockDate] = useState();
  const [loggedIn,setLoggedIn] = useState(false);
  const [LPValue,setLPValue] = useState(true);
  const [MarketFactory_Address,setMarketFactory_Address] = useState();
  const [MarketRouter_Address,setMarketRouter_Address] = useState();
  const [messageBoxVisible,setMessageBoxVisible] = useState(false);
  const [messageBoxHeader,setMessageBoxHeader] = useState('');
  const [messageBoxFooter,setMessageBoxFooter] = useState('');
  const [messageBoxContent,setMessageBoxContent] = useState('');
  const [pageLoadedMainpage,setPageLoadedMainpage] = useState(false);
  const [pools,setPools] = useState();
  const [RewardsMachine_Address,setRewardsMachine_Address] = useState();
  const [showAccount,setShowAccount] = useState(false);
  const [slippage,setSlippage] = useState(50);
  const [testnet,setTestnet] = useState();
  const [totalLockedValue,setTotalLockedValue] = useState();
  const [totalValue,setTotalValue] = useState();
  const [TotalVeISSSupply,setTotalVeISSSupply] = useState();
  const [trxTime,setTrxTime] = useState(30);
  const [txhash, setTxhash] = useState("");
  const [uData,setUData] = useState();
  const [Upgrader_Address,setUpgrader_Address] = useState();
  
  const [USDC_Address,setUSDC_Address] = useState();
  const [USDCAllowance,setUSDCAllowance] = useState();
  const [USDCBalance,setUSDCBalance] = useState(0);
  const [veISSBalance,setVeISSBalance] = useState();
  const [VotingEscrow_Address,setVotingEscrow_Address] = useState();
  const [VoteMachine_Address,setVoteMachine_Address] = useState();
  const [web3_nm,setWeb3_nm] = useState();
  const [wrongNetworkMessage,setWrongNetworkMessage] = useState(false);


  //smartContracts
  const [assetFactory_nm,setAssetFactory_nm] = useState();
  const [USDC_nm,setUSDC_nm] = useState();
  const [GovernanceToken_nm,setGovernanceToken_nm] = useState();
  const [VoteMachine_nm,setVoteMachine_nm] = useState();
  const [MarketRouter_nm,setMarketRouter_nm] = useState();
  const [MarketFactory_nm,setMarketFactory_nm] = useState();
  const [RewardsMachine_nm,setRewardsMachine_nm] = useState();
  const [DAO_nm,setDAO_nm] = useState();
  const [Upgrader_nm,setUpgrader_nm] = useState();
  const [VotingEscrow_nm,setVotingEscrow_nm] = useState();
  

  const network = useNetwork()
  
  const web3Data = useAccount({
    onConnect({ address, connector, isReconnected }) {
      setAddress(address);
      //console.log(web3Data)
      console.log("Connected.");
      console.log(connector);
      setLoggedIn(true)
      getChainDetails()
    },
    onDisconnect() {
      setAddress("");
      console.log("Disconnected.");
      setLoggedIn(false)
    },
  });
  
  useEffect(() => {
    console.log('Path changed to:', location.pathname);
    if (location.pathname === '/'){
      setActivePage('Dashboard')
    }
    if (location.pathname === '/portfolio'){
      setActivePage('Portfolio')
    }
    if (location.pathname === '/mint'){
      setActivePage('Mint assets')
    }
    if (location.pathname === '/pool'){
      setActivePage('Pools')
    }
    if (location.pathname === '/trade'){
      setActivePage('Trade')
    }
    if (location.pathname === '/redeem'){
      setActivePage('Redeem')
    }
    if (location.pathname === '/governance'){
      setActivePage('Governance')
    }
    if (location.pathname === '/IDT'){
      setActivePage('TWIND Conversion')
    }

    if (location.pathname === '/ISS'){
      setActivePage('TWIN Token')
    }
  }, [location.pathname]);


  useEffect(() => {
    if (pageLoadedMainpage === true){
      loadAssetBalances() 
    }
  }, [pageLoadedMainpage]);
  
  useEffect(() => {
    if (assetBalancesLoaded === true){
      loadLPBalances() 
    }
  }, [assetBalancesLoaded]);
  
useEffect(() => {
    if (loadingBlockchainData === false){
      setChainDetailsLoaded(false)
      setTimeout(() => getChainDetails(), 100); // Wait 0.1 seconds before calling the function
      }
  }, [web3Data.address,network.chain]);
  
  useEffect(() => {
    console.log(chainDetailsLoaded)
    console.log("Loading blockchain data?")
    if (chainDetailsLoaded){
      loginWeb3()
    }
    else{
      console.log(chainDetailsLoaded)
      setLoadingBlockchainData(false)
    }
  }, [chainDetailsLoaded]);

  useEffect(() => {
    if (pageLoadedMainpage && blockchainDataLoaded) {
        console.log('Asset details or blockchain data changed, re-rendering Portfolio...');
        // Simulate fetching new asset details
        updateAssetDetails(assetDetails); // Ensure you have the correct function here
    }
  }, [pageLoadedMainpage, blockchainDataLoaded, assetDetails, assets]);

  useEffect(() => {
    if (loadingBlockchainData === false){
      console.log("updating asset value")
      updateAssetValue()
      }
  }, [GovernanceTokenBalance, GovernanceTokenStakeBalance, GovernanceTokenTotalBalance,USDCBalance,assetDetails]);


  useEffect(() => {
    if (loadingBlockchainData === false){
      updatePortfolioValue()
      }
  }, [assetValue]);

  const updateAssetDetails = (newAssetDetails) => {
    setAssetDetails(newAssetDetails);
    console.log('Asset details updated:', newAssetDetails);
  };

  const saveSlippagePreference = (slippage) =>{
    setSlippage(slippage)
  };
  const saveTrxTimePreference = (trxTime) =>{
    setTrxTime(trxTime)
  };  

  function changeView(_page){
    setActivePage(_page)
  }
 
  async function updateUserData() {
    setLoadingData(true)
    var _uData = await getUserData(address)
    setIDTBalance(_uData.DistributionTokenBalance)
      
    // Load USDC Balance etc
    let _USDCBalance = _uData.StableCoinBalance
    await setUSDCBalance(_USDCBalance)
    
    // Load the Government Token and IDT balance as well as the staked amaount
    let _GovernanceTokenBalance = _uData.GovernanceTokenBalance
    setGovernanceTokenBalance(_GovernanceTokenBalance)
  
    let _GovernanceTokenStakeBalance = _uData.lockedBalance   
    let _veISSBalance = _uData.veBalance
    let _lockDate = _uData.lockTime
    setVeISSBalance(_veISSBalance)
    setLockDate(_lockDate)
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    let _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
    let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains)
    console.log("User for TWIN and HONEY data updated")
    setLoadingData(false)
  }

  async function loadMainpage(){
    setLoadingData(true)
    setblockchainDataLoaded(false)
    setPageLoadedMainpage(false)
    console.log("Loading mainpage")
    let _address = web3Data.address
    var _uData = await getUserData(_address)
    setIDTBalance(_uData.DistributionTokenBalance)
      
    // Load USDC Balance etc
    let _USDCBalance = _uData.StableCoinBalance
    await setUSDCBalance(_USDCBalance)
    
    // Load the Government Token and IDT balance as well as the staked amaount
    let _GovernanceTokenBalance = _uData.GovernanceTokenBalance
    setGovernanceTokenBalance(_GovernanceTokenBalance)
  
    let _GovernanceTokenStakeBalance = _uData.lockedBalance   
    let _veISSBalance = _uData.veBalance
    let _lockDate = _uData.lockTime
    setVeISSBalance(_veISSBalance)
    setLockDate(_lockDate)
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    let _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
    let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains)
  
    // LOAD the Governance Token Data
    let governanceTokenData = await getGovernanceTokenData()
    let _ISSSupply = governanceTokenData.GovernanceTokenTotalSupply
    setISSSupply(_ISSSupply)
    let _ISSSupplyCurrent = governanceTokenData.GovernanceTokenCurrentSupply
    console.log(_ISSSupplyCurrent)
    setISSSupplyCurrent(_ISSSupplyCurrent)
    let _totalVeISSSupply = governanceTokenData.TotalVeSupply
    setTotalVeISSSupply(_totalVeISSSupply)

    console.log("DEBUG0")
  
    let ISSData = await getPrice(GovernanceToken_Address)

    console.log("DEBUG1")
    setISSPrice(ISSData[0])
    setISSMarketCap(ISSData[1])
    setISSMarketCapCurrent(ISSData[0]*_ISSSupplyCurrent)
    // load the different assets
    let _assets = []
    let assetData =  await getAssetData('bartio')
    console.log(assetData)
    let _pools = assetData[0]
    console.log(_pools)
    let _assetDetails = assetData[1]
    console.log(_assetDetails)
    setAssetDetails(_assetDetails)
    setPools(_pools)
    
    for (const key of Object.keys(_assetDetails)) {
      _assets.push(key)
    }
    console.log(assets)
    setAssets(_assets)
  
    let _totalLockedValue = 0
    for (let i = 0; i < _pools.length; i++) {
      _totalLockedValue += _pools[i][2]
    }
  
    setTotalLockedValue(_totalLockedValue)
    setLoadingBlockchainData(false)
    setPageLoadedMainpage(true)
    setblockchainDataLoaded(true)
    setLoadingData(false)
  }
  
  async function updateAssetValue() {
    setLoadingData(true)
    let _assetValue = 0
    let keys = Object.keys(assetDetails);
    keys.forEach(key => {
      const value = assetDetails[key];
      console.log(value)
      _assetValue += (value['priceLong'] * value['tokenBalance1']) + (value['priceShort'] * value['tokenBalance2'])
    });
    console.log(_assetValue)
    _assetValue += GovernanceTokenTotalBalance * ISSPrice + USDCBalance
    console.log(_assetValue)
    console.log(GovernanceTokenTotalBalance * ISSPrice)
    console.log(USDCBalance)
    console.log(_assetValue)
    
    setAssetValue(_assetValue)
    setLoadingData(false)
  }

  async function loadAssetBalances() {
    setLoadingData(true)
    setAssetBalancesLoaded(false);
    let assetBalances = await getAssetBalances(address);
    //console.log(assetBalances)
  
    // Create a new copy of assetDetails to ensure immutability
    let _assetDetails = { ...assetDetails };
  
    //console.log(_assetDetails);
    //console.log(assetBalances);
  
    // Update the copy with new balances
    assetBalances.forEach(balance => {
        _assetDetails[balance[0]] = {
            ..._assetDetails[balance[0]],
            tokenBalance1: balance[1],
            tokenBalance2: balance[2],
        };
    });
    //console.log(assetBalances)
    //console.log(_assetDetails);
  
    // Set the updated copy to state
    setAssetDetails(_assetDetails);
  
    // Load the assetValue of the user
    let _assetValue = 0
    let keys = Object.keys(_assetDetails);
    keys.forEach(key => {
      const value = _assetDetails[key];
      //console.log(value)
      _assetValue += (value['priceLong'] * value['tokenBalance1']) + (value['priceShort'] * value['tokenBalance2'])
    });
    _assetValue += GovernanceTokenTotalBalance * ISSPrice + USDCBalance
    //console.log(_assetValue)
    //console.log(GovernanceTokenTotalBalance * ISSPrice)
    //console.log(USDCBalance)
    setAssetValue(_assetValue)
    setAssetBalancesLoaded(true);
    setLoadingData(false)
  }
  
  
  async function loadLPBalances() {
    setLoadingData(true)
    console.log("Loading LP Balances");
    let _LPValue = 0
    let lpBalances = await getLPBalances(address);
    //console.log(lpBalances)
    
    // Create a new copy of pools to ensure immutability
    let _pools = pools.map(pool => ({ ...pool }));
  
    for (let i = 0; i < _pools.length; i++) {
        //console.log(_pools[i]);
  
        let asset = _pools[i][0];
        let lpBalance = lpBalances[asset];
        //console.log(lpBalance)
        if (lpBalance !== undefined) {
            _pools[i][4] = lpBalance * 1e18;
            _LPValue += (lpBalance * 1e18) * _pools[i][2] / _pools[i][5]
        }
    }
    //console.log(_pools);
    //console.log(_LPValue)
    setLPValue(_LPValue)
    setTotalValue(_LPValue+assetValue)
  
    // Set the updated copy to state
    setPools(_pools);
    setLoadingData(false)
  }

  async function getChainDetails(){
    setLoadingData(true)
    setChainDetailsLoaded(false)
    let chainId
    try{
      chainId = network.chain.id
    }
    catch{
      setLoadingBlockchainData(false)
      return
    }
    console.log(chainId)
    let _chainName
    let _web3_nm
    try {_web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.scroll.io'))}
    catch{_web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.ankr.com/scroll'))}
    let _AssetFactory_Address = assetFactory_Address_b
    let _DAO_Address = DAO_Address_b
    let _fullProtocolIntegration = true
    let _GovernanceToken_Address = GovernanceToken_Address_b
    let _IDT_Address = IDT_Address_b
    let _MarketFactory_Address = MarketFactory_Address_b
    let _MarketRouter_Address = MarketRouter_Address_b
    let _RewardsMachine_Address = RewardsMachine_Address_b
    let _testnet = false
    let _Upgrader_Address = Upgrader_Address_b
    let _USDC_Address = USDC_Address_b
    let _VotingEscrow_Address = VotingEscrow_Address_b
    let _VoteMachine_Address = VoteMachine_Address_b
    console.log(chainId)
    if (chainId === '0xxxxxxx' || chainId === 111111) {
      console.log("Berachain detected")
    }
    
    else if (chainId === '0x138d5' || chainId === 80084)  {
      console.log("Bera testnet detected")
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://bartio.rpc.berachain.com/'));
      _chainName = "Berachain BArtio"
      _USDC_Address = USDC_Address_ba
      _GovernanceToken_Address = GovernanceToken_Address_ba
      _IDT_Address = IDT_Address_ba
      _AssetFactory_Address = assetFactory_Address_ba
      _VoteMachine_Address = VoteMachine_Address_ba
      _MarketFactory_Address = MarketFactory_Address_ba
      _MarketRouter_Address = MarketRouter_Address_ba
      _RewardsMachine_Address = RewardsMachine_Address_ba
      _DAO_Address = DAO_Address_ba
      _Upgrader_Address = Upgrader_Address_ba
      _VoteMachine_Address = VoteMachine_Address_ba
      _VotingEscrow_Address = VotingEscrow_Address_ba
      _testnet = true
      _fullProtocolIntegration = true
    }
  
    else{
      console.log("Unknown chain detected")
      _fullProtocolIntegration = true
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      return
    }

    setWeb3_nm(_web3_nm);
    setChainName(_chainName)
    setUSDC_Address(_USDC_Address)
    setGovernanceToken_Address(_GovernanceToken_Address)
    setAssetFactory_Address(_AssetFactory_Address)
    setVoteMachine_Address(_VoteMachine_Address)
    setMarketFactory_Address(_MarketFactory_Address)
    setMarketRouter_Address(_MarketRouter_Address)
    setRewardsMachine_Address(_RewardsMachine_Address)
    setDAO_Address(_DAO_Address)
    setUpgrader_Address(_Upgrader_Address)
    setVoteMachine_Address(_VoteMachine_Address)
    setVotingEscrow_Address(_VotingEscrow_Address)
    setTestnet(_testnet)
    setFullProtocolIntegration(_fullProtocolIntegration)
    
    
    const _USDC_nm = new _web3_nm.eth.Contract(USDC_ABI,_USDC_Address)
    const _IDT_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI,_IDT_Address)
    const _assetFactory_nm = new _web3_nm.eth.Contract(assetFactory_ABI,_AssetFactory_Address);
    const _GovernanceToken_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI,_GovernanceToken_Address)
    const _VoteMachine_nm = new _web3_nm.eth.Contract(VoteMachine_ABI,_VoteMachine_Address)
    const MarketRouter_nm = new _web3_nm.eth.Contract(MarketRouter_ABI,_MarketRouter_Address)
    const _MarketFactory_nm = new _web3_nm.eth.Contract(MarketFactory_ABI,_MarketFactory_Address)
    const _RewardsMachine_nm =  new _web3_nm.eth.Contract(RewardsMachine_ABI,_RewardsMachine_Address)
    const _DAO_nm = new _web3_nm.eth.Contract(DAO_ABI,_DAO_Address)
    const _Upgrader_nm = new _web3_nm.eth.Contract(Upgrader_ABI, _Upgrader_Address)
    const _VotingEscrow_nm = new _web3_nm.eth.Contract(VotingEscrow_ABI, _VotingEscrow_Address)
    
    setUSDC_nm(_USDC_nm)
    setAssetFactory_nm(_assetFactory_nm)
    setMarketFactory_nm(_MarketFactory_nm)
    setDAO_nm(_DAO_nm)
    setUpgrader_nm(_Upgrader_nm)
    setRewardsMachine_nm(_RewardsMachine_nm)
    setVotingEscrow_nm(_VotingEscrow_nm)
    setVoteMachine_nm(_VoteMachine_nm)
    setGovernanceToken_nm(_GovernanceToken_nm)
    setIDT_nm(_IDT_nm)
    setChainDetailsLoaded(true)
    setLoadingData(false)
  }

  async function loginWeb3() {
    setLoadingData(true)
    setLoadingBlockchainData(true)
    let _address = web3Data.address
    setAddress(_address)
    loadMainpage()
    
    
    
    console.log("Blockchain data is loaded")
    setLoadingData(false)
  
  
  
  }




  async function loadLimitedBlockchainData() {
    setLoadingData(true)
    // Load the Government Token balance as well as the staked amaount

    let _IDTBalanceWei = await IDT_nm.methods.balanceOf(address).call()
    setIDTBalanceWei(_IDTBalanceWei)
    let _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call()
    let _ISSSupplyWei = await GovernanceToken_nm.methods.totalSupply().call()
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    setISSSupply(_ISSSupply)
    let _veISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call()
    let _remainingRewards = await GovernanceToken_nm.methods.balanceOf(RewardsMachine_Address).call()
    let _veISSShare = parseFloat(_veISSSupplyWei / (_ISSSupplyWei - _remainingRewards))

    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    console.log(_GovernanceTokenBalance)
    setGovernanceTokenBalance(_GovernanceTokenBalance)

    let _userData = await VotingEscrow_nm.methods.userData(address).call()
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount']    
    let _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    
    let _veISSBalanceWei = _userData['_balanceVeISS']
    let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    let _lockDate = _userData['_lockedBalance']['end']
    let _totalVeISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    
    setVeISSBalance(_veISSBalance)
    setLockDate(_lockDate)
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    setTotalVeISSSupply(_totalVeISSSupply)

    let _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
    let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance
    let _USDCAllowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call() 
    
    setUSDCAllowance(_USDCAllowance)
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains)

     ////////////// 
    setLoadingData(false)
    console.log("Blockchain data is loaded")
  }


  function openMessageBox(message,header='Transaction Hash',footer=''){
    setMessageBoxVisible(true)
    setMessageBoxContent(message)
    setMessageBoxHeader(header)
    setMessageBoxFooter(footer)
  }

  function closeMessageBox(){
    setMessageBoxVisible(false)
  }

  const checkUSDAllowanceAssetFactory = async () => {
       // check if the the allowance has been set for the AssetFactory contract to spend USDT
      let allowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call()
      setUSDCAllowance(allowance)

    };
  

  const loadUSDBalance = async() =>{
    setLoadingData(true)
    //Load the USDC balance
    await sleep(2000);
    console.log(USDCBalance)
    console.log(USDDecimals)  
    var balanceWEI = await USDC_nm.methods.balanceOf(address).call()
    console.log(balanceWEI)
    let balance
    if (parseInt(USDDecimals) === 6) {
      balance = parseInt(balanceWEI)/1e6
    }
    else {
      balance = parseInt(balanceWEI)/1e18
    }
    console.log(balance)
    await setUSDCBalance(balance)
    setLoadingData(false)
  };

  const updateExpiryVotes = async () => {
    let _assetDetails = assetDetails;
    for (let asset in assetDetails) {
      let expiryVotes = await VoteMachine_nm.methods.getEndOfLifeVotes(asset).call()
      _assetDetails[asset]['expiryVoteOpen'] = expiryVotes.open
      _assetDetails[asset]['expiryVoteExists'] = expiryVotes.exists
      _assetDetails[asset]['expiryVoteEndingTime'] = expiryVotes.endingTime
      let hasVotedOnExpiry = await VoteMachine_nm.methods.checkIfVotedOnExpiry(address,asset).call()
      _assetDetails[asset]['hasVotedOnExpiry'] = hasVotedOnExpiry
    }
    setAssetDetails(_assetDetails)
  }

  const updateFreezeVotes = async () => {
    let _assetDetails = assetDetails;
    for (let asset in _assetDetails) {
      let freezeVotes = await VoteMachine_nm.methods.getFreezeVotes(asset).call()
      _assetDetails[asset]['voteOpen'] = freezeVotes.open
      _assetDetails[asset]['yesVotes'] = freezeVotes.yesVotes
      _assetDetails[asset]['noVotes'] = freezeVotes.noVotes
      _assetDetails[asset]['votesEndingTime'] = freezeVotes.endingTime
      _assetDetails[asset]['votesStartingTime'] = freezeVotes.startingTime
      let hasVoted = await VoteMachine_nm.methods.checkIfVoted(address,asset).call()
      _assetDetails[asset]['hasVoted'] = hasVoted
    }
    setAssetDetails(_assetDetails)
  }

  const updateAssetBalance = async(symbol) =>{
    console.log(symbol)
    console.log("Loading new Asset Balance");
    let _assetDetails = assetDetails;
    let updatedBalances = getAssetBalancesFromTicker(address,symbol)
    let tokenBalanceLong = updatedBalances[0]
    let tokenBalanceShort = updatedBalances[1]
    _assetDetails[symbol]['tokenBalance1'] = parseFloat(tokenBalanceLong)
    _assetDetails[symbol]['tokenBalance2'] = parseFloat(tokenBalanceShort)

    // Update the LP token details
    let UpdatedLPBalances = getLPBalancesFromTicker(address,symbol)
    _assetDetails[symbol]['poolBalanceLong'] = parseFloat(UpdatedLPBalances[0])
    _assetDetails[symbol]['poolBalanceShort'] = parseFloat(UpdatedLPBalances[1])
    console.log(_assetDetails)
    setAssetDetails(_assetDetails)
  }

  const updateISSData = async() =>{
    let _IDTBalanceWei = await IDT_nm.methods.balanceOf(address).call()
    setIDTBalanceWei(_IDTBalanceWei)
    let _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call()
    let _ISSSupplyWei = await GovernanceToken_nm.methods.totalSupply().call()
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    setISSSupply(_ISSSupply)
    let _veISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call()
    let _remainingRewards = await GovernanceToken_nm.methods.balanceOf(RewardsMachine_Address).call()
    let _veISSShare = parseFloat(_veISSSupplyWei / (_ISSSupplyWei - _remainingRewards))

    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    console.log(_GovernanceTokenBalance)
    setGovernanceTokenBalance(_GovernanceTokenBalance)

    let _userData = await VotingEscrow_nm.methods.userData(address).call()
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount']    
    let _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    
    let _veISSBalanceWei = _userData['_balanceVeISS']
    let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    let _lockDate = _userData['_lockedBalance']['end']
    let _totalVeISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    
    setVeISSBalance(_veISSBalance)
    setLockDate(_lockDate)
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    setTotalVeISSSupply(_totalVeISSSupply)

    
    
    return true
  }

  const updateAssetBalanceWithAddress = async(_address) =>{
    console.log("Loading new Asset Balance");
    if (_address === GovernanceToken_Address) {
      await updateISSData()
      return
    }
    let tickerData = await getTickerForAddress(_address)
    console.log(tickerData)
    let symbol = tickerData[0]
    if (symbol !== ''){
      await updateAssetBalance(symbol)
      return
    }
    else{
      console.log("Symbol not found")
      return
    }
  }
  
  const updateLPPair = async(_tokenAddress) =>{
    console.log(_tokenAddress)
    console.log(USDC_Address)
    let pair = await MarketFactory_nm.methods.getPair(_tokenAddress,USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let reserves = await MarketPair.methods.getReserves().call();
    let userBalance = await MarketPair.methods.balanceOf(address).call()
    let totalSupply = await MarketPair.methods.totalSupply().call();
    let token0 = await MarketPair.methods.token0().call();
    let USDReserves;
    let tokenReserves;
    if (token0 === USDC_Address) {
          USDReserves = parseInt(reserves[0])
          tokenReserves = parseInt(reserves[1])
          
    }
    else{
          USDReserves = parseInt(reserves[1])
          tokenReserves = parseInt(reserves[0])
          
    }
    let _pools = pools;
    console.log(_pools)
    let selector
    for (var i = 0, size = _pools.length; i < size ; i++) {
      if (_pools[i][1] === pair){
        selector = i
      }
    }
    let poolData = _pools[selector]
    poolData[2] = USDReserves*2 / 10**(USDDecimals);
    poolData[4] = parseInt(userBalance)
    poolData[5] = parseInt(totalSupply)
    poolData[7] = USDReserves
    poolData[8] = tokenReserves

    _pools[selector] = poolData
    setPools(_pools)
    
  }
  const updateLPPairWithAddress = async(pair) =>{
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let reserves = await MarketPair.methods.getReserves().call();
    let userBalance = await MarketPair.methods.balanceOf(address).call()
    let totalSupply = await MarketPair.methods.totalSupply().call();
    let token0 = await MarketPair.methods.token0().call();
    let USDReserves;
    let tokenReserves;
    if (token0 === USDC_Address) {
          USDReserves = parseInt(reserves[0])
          tokenReserves = parseInt(reserves[1])
          
    }
    else{
          USDReserves = parseInt(reserves[1])
          tokenReserves = parseInt(reserves[0])
          
    }
    let pools = pools;
    let selector
    for (var i = 0, size = pools.length; i < size ; i++) {
      if (pools[i][1] === pair){
        selector = i
      }
    }
    let poolData = pools[selector]
    poolData[2] = USDReserves*2 / 10**(USDDecimals);
    poolData[4] = userBalance
    poolData[5] = totalSupply
    poolData[7] = USDReserves
    poolData[8] = tokenReserves
    pools[selector] = poolData

    this.setState({pools})
    
  }

  const checkRewards = async() => {
    
    let _openRewards = await RewardsMachine_nm.methods.getRewards(address).call();
    let _nextRewardsPayment = await RewardsMachine_nm.methods.nextRewardsPayment().call();
    var _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call()
    var _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    setGovernanceTokenBalance(_GovernanceTokenBalance)
    var userData = await VotingEscrow_nm.methods.userData(address).call()
    let _GovernanceTokenStakeBalanceWei = userData['_lockedBalance']['amount']
    var _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    let _veISSBalanceWei = userData['_balanceVeISS']
    var _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    setVeISSBalance(_veISSBalance)
    let _lockDate = userData['_lockedBalance']['end']
    setLockDate(_lockDate)
    
    var _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    
  }

  const updatePortfolioValue = async() =>{
    let updatedAssetValue = 0
    let updatedLPValue = 0
    let updatedTotalValue = 0
    try {
      updatedAssetValue += (GovernanceTokenTotalBalance * ISSPrice) + parseFloat(USDCBalance)
      }
    catch{
      updatedAssetValue += parseFloat(USDCBalance)
    }

   
   
    let _pools = pools;
    
    for (let i = 0, size = pools.length; i < size ; i++) {
      updatedLPValue = updatedLPValue + ((parseFloat(_pools[i][4])/parseFloat(_pools[i][5]))*parseFloat(_pools[i][2])/1e18)
      }
    let _assetDetails = assetDetails;
    
    for (let s in assetDetails) {
      updatedAssetValue += _assetDetails[s]['tokenBalance1'] * _assetDetails[s]['priceLong']
      updatedAssetValue += _assetDetails[s]['tokenBalance2'] * _assetDetails[s]['priceShort']

    }
    updatedTotalValue = updatedAssetValue + updatedLPValue

    await loadAssetBalances() 
    
    return
  }

 

const updateISSPrice = async() => {
  let ISSData = await getPrice(GovernanceToken_Address)
  setISSPrice(ISSData[0])
}

const updatePriceWithAddress = async(_address) => {
  let priceData = await getPrice(_address)
  console.log(priceData)
  if (GovernanceToken_Address === _address){
    setISSPrice(priceData[0])
  }
  else {
    console.log("updating price data")
    let _assetDetails = { ...assetDetails };
    // FIND out which asset this is
    let tickerData = await getTickerForAddress(_address)
    console.log(tickerData)
    if (tickerData[1] === 'long'){
      console.log("Long identified")
      _assetDetails[tickerData[0]]['priceLong'] = priceData[0]
    }
    if (tickerData[1] === 'short'){
      console.log("Short identified")
      _assetDetails[tickerData[0]]['priceShort'] = priceData[0]
    }
    console.log(_assetDetails)
    setAssetDetails(_assetDetails)
  }
}
  
async function getTickerForAddress(_address) {
  for (const key of Object.keys(assetDetails)) {
    if (assetDetails[key]['Token1'] === _address) {return([key,'long'])}
    if (assetDetails[key]['Token2'] === _address) {return([key,'short'])}
  }
  return(['',''])   
}
    return (
      
      <div className="mainArea">
        <div className="backgroundImageNew">
          <div className="layer" >
          <SidebarElement
            activePage = {activePage} 
            address={address}
            changeView ={changeView}
            chainName = {chainName}
            fullProtocolIntegration = {fullProtocolIntegration}
            showAccount = {showAccount}
            GovernanceToken = {GovernanceToken}
            GovernanceTokenBalance = {GovernanceTokenBalance}
            GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
            IDTBalance = {IDTBalance}
            loginWeb3 = {loginWeb3}
            outputNumber = {outputNumber}
            sleep = {sleep}
            GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
          />
            <div className="w-100 m-0 p-0">


              <Navbar
                activePage = {activePage} 
                address={address}
                changeView ={changeView}
                chainName = {chainName}
                showAccount = {showAccount}
                GovernanceToken = {GovernanceToken}
                GovernanceTokenBalance = {GovernanceTokenBalance}
                GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                IDTBalanceWei = {IDTBalanceWei}
                loginWeb3 = {loginWeb3}
                outputNumber = {outputNumber}
                sleep = {sleep}
                GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
              />
              <div className="contentContainer">
              <Routes>
                  <Route path="/" element={
                    fullProtocolIntegration && loggedIn && pageLoadedMainpage?
                        <Mainpage
                          address = {address}
                          assets = {assets}
                          assetDetails={assetDetails}
                          assetFactory = {assetFactory}
                          closeMessageBox = {closeMessageBox}
                          ERC20_ABI = {ERC20_ABI}
                          GovernanceToken_Address = {GovernanceToken_Address}
                          GovernanceTokenBalance = {GovernanceTokenBalance} 
                          GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                          GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                          GovernanceToken = {GovernanceToken}
                          ISSPrice = {ISSPrice}
                          ISSMarketCap = {ISSMarketCap}
                          ISSMarketCapCurrent = {ISSMarketCapCurrent}
                          openMessageBox = {openMessageBox}
                          outputNumber = {outputNumber}
                          pools = {pools}
                          sleep = {sleep}
                          stableCoinName = {stableCoinName}
                          totalLockedValue = {totalLockedValue}
                          web3 = {web3_nm}
                          />
                          :
                          loggedIn?
                            <ChangeChain />  
                            :
                            ''
                          

                        } />
                        <Route path="/portfolio" element={
                          fullProtocolIntegration && loggedIn?
                            <Portfolio
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory}
                              assetValue = {assetValue}
                              blockchainDataLoaded = {blockchainDataLoaded}
                              changeView ={changeView}
                              GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                              ISSPrice = {ISSPrice}
                              loadingBlockchainData = {loadingBlockchainData}    
                              LPValue = {LPValue}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}               
                              outputNumber = {outputNumber}     
                              pools = {pools} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}  
                              timeStampToDate = {timeStampToDate}
                              USDC_Address = {USDC_Address}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              web3 = {web3_nm}
                            />
                            :
                                loggedIn?
                                  <ChangeChain />
                                  :
                                  ''
                          } 
                        />
                        <Route path="/points" element={
                          fullProtocolIntegration && loggedIn?
                            <Points
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory}
                              assetValue = {assetValue}
                              blockchainDataLoaded = {blockchainDataLoaded}
                              GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                              ISSPrice = {ISSPrice}
                              loadingBlockchainData = {loadingBlockchainData}    
                              LPValue = {LPValue}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}               
                              outputNumber = {outputNumber}     
                              pools = {pools} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}  
                              timeStampToDate = {timeStampToDate}
                              USDC_Address = {USDC_Address}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              web3 = {web3_nm}
                            />
                            :
                                loggedIn?
                                  <ChangeChain />
                                  :
                                  ''
                          } 
                        />
                        <Route path="/pool/*" element={
                          fullProtocolIntegration && loggedIn?
                            <Pool
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI} 
                              expiredAssets = {expiredAssets}
                              GovernanceToken_Address = {GovernanceToken_Address}
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              loadUSDBalance = {loadUSDBalance}
                              liveAssets = {liveAssets}
                              location = {location}
                              MarketRouter = {MarketRouter_nm}
                              MarketRouter_ABI = {MarketRouter_ABI}
                              MarketRouter_Address = {MarketRouter_Address}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              pools = {pools}
                              saveSlippagePreference = {saveSlippagePreference}
                              saveTrxTimePreference = {saveTrxTimePreference}
                              sleep = {sleep}
                              slippage = {slippage}
                              stableCoinName = {stableCoinName}
                              trxTime = {trxTime}
                              updateAssetBalance = {updateAssetBalance}
                              updateLPPair = {updateLPPair}
                              updateLPPairWithAddress = {updateLPPairWithAddress}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updatePortfolioValue = {updatePortfolioValue}
                              USDC = {USDC_nm}
                              USDC_Address = {USDC_Address}
                              USDCAllowance = {USDCAllowance}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              web3 = {web3_nm}
                            />
                            :
                                loggedIn?
                                  <ChangeChain />
                                  :
                                  ''
                        } 
                        />
                        
                        <Route path="/mint" element={
                          fullProtocolIntegration && loggedIn?
                            <Factory 
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              assetFactory_Address = {assetFactory_Address}
                              assetFactory_ABI = {assetFactory_ABI}
                              checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI}
                              expiredAssets = {expiredAssets}
                              liveAssets = {liveAssets}
                              loadUSDBalance = {loadUSDBalance}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}               
                              outputNumber = {outputNumber}     
                              pools = {pools}
                              sleep = {sleep} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}
                              updateAssetBalance = {updateAssetBalance}
                              USDC_Address = {USDC_Address}
                              USDCAllowance = {USDCAllowance} 
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              USDC = {USDC_nm}   
                              web3 = {web3_nm}
                              />
                              :
                              loggedIn?
                                <ChangeChain />
                                :
                                ''                
                            
                        } 
                        />
                        <Route path="/trade/*" element={
                          fullProtocolIntegration && loggedIn?
                            <Market
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI} 
                              GovernanceToken_Address = {GovernanceToken_Address}          
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              loadUSDBalance = {loadUSDBalance}
                              location = {location}
                              MarketRouter = {MarketRouter_nm}
                              MarketRouter_Address = {MarketRouter_Address}
                              MarketRouter_ABI = {MarketRouter_ABI}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              saveSlippagePreference = {saveSlippagePreference}
                              saveTrxTimePreference = {saveTrxTimePreference}
                              slippage = {slippage}
                              trxTime = {trxTime}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updateISSData = {updateISSData}
                              updateISSPrice = {updateISSPrice}
                              updatePortfolioValue = {updatePortfolioValue}
                              updatePriceWithAddress = {updatePriceWithAddress}
                              updateUserData = {updateUserData}
                              USDCBalance = {USDCBalance}
                              USDC_Address = {USDC_Address}
                              USDDecimals = {USDDecimals}
                              USDT = {USDC_nm}
                              web3 = {web3_nm}
                            /> 
                            :
                            loggedIn?
                                <ChangeChain />
                                :
                                ''
                        } 
                        />
                        <Route path="/redeem" element={
                          fullProtocolIntegration && loggedIn?
                            <BurnAssets
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              assetFactory_Address = {assetFactory_Address}
                              assetFactory_ABI = {assetFactory_ABI}
                              ERC20_ABI = {ERC20_ABI} 
                              loadUSDBalance = {loadUSDBalance}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updatePortfolioValue = {updatePortfolioValue}
                              loadLPBalances = {loadLPBalances}
                              updateAssetBalance = {updateAssetBalance}
                              web3 = {web3_nm}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />
                        <Route path="/governance" element={
                          fullProtocolIntegration && loggedIn?
                            <Governance
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              chain = {chainName}
                              checkRewards = {checkRewards}
                              closeMessageBox = {closeMessageBox}
                              DAO = {DAO_nm}
                              DAO_Address = {DAO_Address}
                              DAO_ABI = {DAO_ABI}
                              GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                              ERC20_ABI = {ERC20_ABI} 
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              stableCoinName = {stableCoinName}
                              timeStampToDate = {timeStampToDate}
                              timeStampToDateAndTime = {timeStampToDateAndTime}
                              sleep = {sleep}
                              Upgrader = {Upgrader_nm}
                              Upgrader_Address = {Upgrader_Address}
                              Upgrader_ABI = {Upgrader_ABI}
                              updateExpiryVotes = {updateExpiryVotes}
                              updateFreezeVotes = {updateFreezeVotes}
                              VoteMachine = {VoteMachine_nm}
                              VoteMachine_Address = {VoteMachine_Address}
                              VoteMachine_ABI = {VoteMachine_ABI}
                              web3 = {web3_nm}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              null
                        } 
                        />
                        <Route path="/ISS" element={
                          fullProtocolIntegration && loggedIn?
                            <GovernanceTokenPage
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory}
                              chain = {chainName}
                              checkRewards = {checkRewards}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI}
                              GovernanceToken_ABI = {GovernanceToken_ABI}
                              GovernanceToken_Address = {GovernanceToken_Address}
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                              GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                              GovernanceToken = {GovernanceToken_nm}
                              GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
                              IDT = {IDT_nm}
                              IDTBalanceWei = {IDTBalanceWei}  
                              ISSSupply = {ISSSupply}
                              ISSSupplyCurrent = {ISSSupplyCurrent}
                              ISSPrice = {ISSPrice}
                              lockDate = {lockDate}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              RewardsMachine = {RewardsMachine_nm}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              testnet = {testnet}
                              timeStampToDate = {timeStampToDate}
                              timeStampToDateAndTime = {timeStampToDateAndTime}
                              totalVeISSSupply = {TotalVeISSSupply}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updateISSData = {updateISSData}
                              veISSBalance = {veISSBalance}
                              VotingEscrow = {VotingEscrow_nm}
                              VotingEscrow_Address = {VotingEscrow_Address}
                              VotingEscrow_ABI = {VotingEscrow_ABI}
                              web3 = {web3_nm}                         
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />
                        <Route path="/IDT" element={
                          fullProtocolIntegration && loggedIn?
                            <IDTConversion
                              address = {address}  
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              chain = {chainName}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI}
                              GovernanceToken_ABI = {GovernanceToken_ABI}
                              GovernanceToken_Address = {GovernanceToken_Address}
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                              GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                              GovernanceToken = {GovernanceToken_nm}
                              GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
                              IDT = {IDT_nm}
                              IDTBalanceWei = {IDTBalanceWei}  
                              ISSSupply = {ISSSupply}
                              ISSPrice = {ISSPrice}
                              loadLimitedBlockchainData = {loadLimitedBlockchainData}
                              lockDate = {lockDate}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              RewardsMachine = {RewardsMachine_nm}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              testnet = {testnet}
                              timeStampToDate = {timeStampToDate}
                              updateISSData = {updateISSData}
                              totalVeISSSupply = {TotalVeISSSupply}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updatePortfolioValue ={updatePortfolioValue}
                              veISSBalance = {veISSBalance}
                              VotingEscrow = {VotingEscrow_nm}
                              VotingEscrow_ABI = {VotingEscrow_ABI}
                              web3 = {web3_nm}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />

                        <Route path="/test" element={
                          fullProtocolIntegration && loggedIn?
                            <Test
                              address = {address}
                              USDT = {USDC_nm}
                              openMessageBox = {openMessageBox}
                              closeMessageBox = {closeMessageBox}
                              sleep = {sleep}
                              loadUSDBalance = {loadUSDBalance}
                              chain = {chainName}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />

                        

              </Routes>
              <Footer />
              </div>
              {messageBoxVisible ? <MessageBox content={messageBoxContent} header={messageBoxHeader} footer={messageBoxFooter}/> : ''}
              
            </div>
            </div>
        </div>
        
        
 
        <Modal 
          dialogClassName="loadingBlockchainModalOuter" show={loadingBlockchainData} centered
        >
          <div 
          className="loadingBlockchainModal">
            <div className="row">
              <div className="col-12 center pt-4">
                <img className="center" src={logo} alt="Logo" width=""/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 h5 text-center">
                  <div className="spinner-border text-white text-center mt-4" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
              </div>
                <div className="col-12 pt-2 pb-3 mr-3 text-center">
                  Loading Blockchain data...
                </div>
            </div>
          </div>
        </Modal>
        
        <Modal className = "rounded" show={wrongNetworkMessage}>
          <div className="p-3 bg-dark text-light border border-accent rounded">
            <div className="row m-4"></div>
            <div className="row">
              <div className="col text-center">
                <p>Wrong Network selected.</p>
                Please switch to Berachain or Berachain Artio Testnet.
              </div>
            </div>
            <div className="row m-4"></div>
          </div>
        </Modal>
      </div>
      
    )
}

export default App;
