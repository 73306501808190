import '../styles/sidebar.css';

import { Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import logoWide from '../img/graphics/TWIN_Logo_wide.png';
import panda from '../img/graphics/PandaWithHoney.png';
import bridge from '../img/icons/Bridge.png';
import farm from '../img/icons/Farm.png';
import github from '../img/github_white.png';
import discord from '../img/discord_white.png';
import twitter from '../img/twitter_white.png';
import telegram from '../img/telegram_white.png';
import parachute from '../img/icons/Parachute.png';
import TagIcon from '@mui/icons-material/Tag';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CopyrightIcon from '@mui/icons-material/Copyright';
import PolicyIcon from '@mui/icons-material/Policy';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ParaglidingOutlinedIcon from '@mui/icons-material/ParaglidingOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
                        
import React, {useState} from 'react';
import { Link } from 'react-router-dom';


const showPoints = false
const showBridge = false

const SidebarElement = (props) => {
    //console.log(props)
    const [collapsed, setCollapsed] = useState(false);
    const [disclaimerModalOpen,setDisclaimerModalOpen] = useState(false);
    
    
    
    const handleChangeView = (_page) => {
      props.changeView(_page);
    };

    const openDisclaimerModal = () => {
        setDisclaimerModalOpen(true)
    }
    const closeDisclaimerModal = () => {
        setDisclaimerModalOpen(false)
    }
  
    const collapseSidebar= () =>{
        if (collapsed === false) {
            setCollapsed(true)
        }
        else{
            setCollapsed(false)
        }
        
    }
    return (
    <div className="" style={{ display: 'flex', minHeight: '100vh' }}>
        <Modal show={disclaimerModalOpen} onHide={closeDisclaimerModal}>
            <Modal.Header className="" closeButton>
                <Modal.Title>Legal notice</Modal.Title> 
            </Modal.Header>
            
            <Modal.Body className="" style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
             }} 
            >
                <div>
                  This website provides information and content of general nature about TWIN as a decentralized finance protocol (TWIN protocol) and as a decentralized autonomous organization (TWIN DAO). 
                    
                </div>
                <div>
                  The information and the content of this website may be wrong, subject to change or incomplete and may not be updated. You should not rely on this website or on any of the content therein for any kind of investment decision or for legal advice, financial advice, investment advice, or any kind of advice. 
                    
                </div>
                <div>
                  You always act at your own risk with respect to the content of this website and the TWIN protocol. In no way are the owners of, or contributors to the TWIN protocol, the TWIN DAO and to this website responsible for the actions, decisions, or other behavior taken or not taken by you in connection with this website or your potential interaction with the TWIN protocol. 
                    
                </div>
                <div>
                  Please note that the TWIN protocol is operated by the TWIN DAO and you are solely responsible for compliance with all laws that may apply to you and your use of the TWIN protocol and this website. 
                </div>
                <div>
                  Cryptocurrencies and blockchain technologies have been the subject of scrutiny by various regulatory bodies across the globe. 
                </div>
                <div>
                  TWIN DAO and its contributors make no representation regarding the application to your use of the TWIN protocol or this website of any laws, including, without limitation, those related to investments, tax, gaming, options, derivatives, or securities. Depending on the jurisdiction, the use of the TWIN protocol may be restricted. 
                  
                </div>
                <div>
                  You agree that TWIN DAO and its contributors are not responsible for determining whether or which laws may apply to you and your use of the TWIN protocol and this website. 
                </div>
                <div>
                  The TWIN DAO may restrict the use of the TWIN protocol to citizens and residents of certain jurisdictions. Particular restrictions apply for US residents who are strictly prohibited from purchasing, minting, farming or staking crypto assets using the TWIN protocol. 
                </div>
                <div>
                  Further, restricted persons should not interact with the TWIN protocol at any time. By interacting with any smart contract of the TWIN protocol you expressly and unconditionally affirm that you are not a resident of the US. 
                </div>
                <div>
                  All rights reserved. No guarantees, warranties or whatsoever given. <b>DeFi tools are not toys. Do your own research.</b>
                </div>

              </Modal.Body>
                        
        </Modal>
        
        <div className = "sidebar">
            <div className="sidebarLogo">
                <img className="" src={logoWide} alt="TWIN" width="163px"/>
            </div>
            <div className='sideBarMenu'>
                <Link to="/">
                    <div className="menuItem" type="button" >
                        <div className="" style={{}} ><GridViewOutlinedIcon/>&nbsp;&nbsp;Dashboard</div>
                    </div>
                </Link>
                <Link to="/portfolio">
                    <div className="menuItem" type="button">
                        <div className="" style={{}}><WorkOutlineOutlinedIcon/>&nbsp;&nbsp;Portfolio</div>
                    </div>
                </Link>
                    
                <Link to="/mint">
                <div className="menuItem" type="button">
                    <div className="" style={{}}>
                        <GavelRoundedIcon/>&nbsp;&nbsp;Mint
                    </div>
                </div>
                </Link>

            
                <Link to="/pool">
                    <div className="menuItem" type="button">
                        <div className="" style={{}}><PieChartRoundedIcon/>&nbsp;&nbsp;Pool</div>
                    </div>
                </Link>
                {showPoints ?
                    <Link to="/farm">
                        <div className="menuItem" type="button">
                            <div className="" style={{}}><AgricultureOutlinedIcon/>&nbsp;&nbsp;Farm</div>
                        </div>
                    </Link>
                :''}
                <Link to="/trade">
                    <div className="menuItem" type="button">
                        <div className="" style={{}}><AttachMoneyOutlinedIcon/>&nbsp;&nbsp;Trade</div>
                    </div>
                </Link>
                <Link to="/redeem">
                    <div className="menuItem" type="button">
                        <div><PaymentsOutlinedIcon/>&nbsp;&nbsp;Redeem</div>
                    </div>
                </Link>
                <Link to="/governance">
                    <div className="menuItem" type="button">
                        <div><AccountBalanceOutlinedIcon/>&nbsp;&nbsp;Governance</div>
                    </div>
                </Link>
                {showPoints ?
                    <Link to="/points">
                        <div className="menuItem" type="button">
                            <div><ParaglidingOutlinedIcon/>&nbsp;&nbsp;Points</div>
                        </div>
                    </Link>
                :''}
                <div className='menuItem'>
                    <a href="https://twin-finance.medium.com/" target="_blank" rel="noreferrer">
                        <ChatBubbleOutlineOutlinedIcon/>&nbsp;&nbsp;Blog 
                    </a>
                </div>
                    <div>&nbsp;</div>
                        
                        {props.chainName === 'Berachain BArtio1' & collapsed !== true ? 
                            <Link to="/test">
                                <div className="w-100 pr-4 pl-4 pb-2">
                                    <div className="w-100 menuButton">
                                        Test ETH / USD
                                    </div>
                                </div>
                            </Link>
                            :
                            ''
                        }
                        
                        {(props.chainName === 'Berachain' || props.chainName === 'Berachain BArtio')  & collapsed !== true ? 
                            <div className="w-100 pr-4 pl-4 pb-2">
                                <div type="button" className="w-100 menuButtonGreen">{props.chainName}</div>
                            </div>
                            :
                            ''
                        }

                        {(props.chainName === 'Berachain' || props.chainName === 'Berachain BArtio') && typeof props.GovernanceTokenTotalBalanceAllChains !== "undefined" && props.IDTBalance < 1 && collapsed !== true ?
                            <Link to="/ISS">
                                <div className="w-100 pr-4 pl-4 pb-2">
                                    <div className="menuButtonTWIN">
                                        <div className='menuButtonTWIN1'>$TWIN</div>
                                        <div className='menuButtonTWIN2'>{props.outputNumber(parseFloat(props.GovernanceTokenTotalBalanceAllChains),0)}</div>
                                        
                                    </div>
                                </div>
                            </Link>
                            :
                            ''
                        }
                        {(props.chainName === 'Berachain'  || props.chainName === 'Berachain BArtio') && typeof props.GovernanceTokenTotalBalanceAllChains !== "undefined" && props.IDTBalance > 0  & collapsed !== true ? 
                            <Link to="/IDT">
                                <div className="w-100 pr-4 pl-4 pb-2">
                                    <div className="menuButtonTWIND">     
                                        TWIND Conversion
                                    </div>
                                </div>
                            </Link>
                            
                            :
                            ''
                        }
                    <div className="sidebarPanda">
                        <img className="" src={panda} alt="Panda" width="170px"/>
                    </div>
            </div>
            <div className='sideBarMenuLow'>
                <div className="menuItem" type="button" onClick={() =>openDisclaimerModal()} >
                    <AccountBalanceOutlinedIcon/>&nbsp;&nbsp;Legal
                </div>
            </div>
            
                
                
            
        </div>
    </div>
    )
    }
    export default SidebarElement;