import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import {TransactionButton} from '../functions/Trx';
import '../styles/twind.css';



class IDTConversion extends Component {
    constructor (props) {
        super(props)
        this.state = {IDTModalOpen: false,
        };
    }

    async componentDidMount() {
        let now = new Date().getTime()/1000
        console.log(this.props.IDTBalanceWei)
        this.setState({
            GovernanceTokenBalance: this.props.GovernanceTokenBalance,
            GovernanceTokenStakeBalance: this.props.GovernanceTokenStakeBalance,
            now:now,
            VotingEscrowApproved: false,
                            
        });
        console.log(this.props.openRewards)
        await this.checkVotingEscrowAllowance()
        if (this.props.IDTBalanceWei > 0) {
            this.checkVotingEscrowAllowanceIDT()
        }
        this.props.loadLimitedBlockchainData()
    }
    
    checkVotingEscrowAllowance = async () => {
        if (typeof(this.props.VotingEscrow) != 'undefined'){ 
            // check if the the allowance has been set for the VotingEscrow contract to spend TWIN
            let addressToApprove = this.props.VotingEscrow._address
            let allowance = await this.props.GovernanceToken.methods.allowance(this.props.address, addressToApprove).call()
            let IDTBalance = await this.props.IDT.methods.balanceOf(this.props.address).call()
            if (allowance >= IDTBalance) {
            this.setState({VotingEscrowApproved: true}) 
          }
          else {
            this.setState({VotingEscrowApproved: false}) 
          } 
          console.log(this.state.VotingEscrowApproved)
      
        }
    };
    checkVotingEscrowAllowanceIDT = async () => {
        if (typeof(this.props.VotingEscrow) != 'undefined'){ 
            // check if the the allowance has been set for the VotingEscrow contract to spend IDT
            let addressToApprove = this.props.VotingEscrow._address
            let allowance = await this.props.IDT.methods.allowance(this.props.address, addressToApprove).call()
            let IDTBalance = await this.props.IDT.methods.balanceOf(this.props.address).call()
            if (allowance >= IDTBalance) {
            this.setState({VotingEscrowApprovedIDT: true}) 
          }
          else {
            this.setState({VotingEscrowApprovedIDT: false}) 
          } 
          console.log(this.state.VotingEscrowApprovedIDT)
      
        }
    };
    roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }

    


    approveVestingEscrow = async() =>{  
        console.log("Approving the VotingEscrow contract to spend TWIN",this)
        let message = "Approving the VotingEscrow contract to spend TWIN"
        this.props.openMessageBox(message)
        await this.props.approveToken(this.props.GovernanceToken_Address,this.props.VotingEscrow._address)
        this.checkVotingEscrowAllowanceIDT()
        this.checkVotingEscrowAllowance()
        return (true)
    };

    openIDTModal = () =>{
        this.setState({IDTModalOpen: true})
    }
    closeIDTModal = () =>{
        this.setState({IDTModalOpen: false})
        this.setState({showIDTCaluclation: false})
    }

    calculateVeISS = () =>{
        var e = document.getElementById("yearsToLock");
        var yearsToLock = e.options[e.selectedIndex].value;
        var lockedISSBalanceProjection = parseInt(this.props.IDTBalanceWei) * yearsToLock / 4
        this.setState({lockedISSBalanceProjection})
        this.setState({yearsToLock})
        this.setState({showIDTCaluclation: true})
    }

    onSuccessConvert = async() =>{
        this.closeIDTModal()
        await this.props.updateISSData()
        //await this.props.loadLimitedBlockchainData()
        await this.props.updatePortfolioValue()
    }

    onSuccessApprove = async() =>{  
        this.checkVotingEscrowAllowance()
        this.checkVotingEscrowAllowanceIDT()
    };

    
    render() { 
        const tooltip1 = props => (
            <Tooltip {...props}>
            TWIN tokens must be staked and locked in order to participate in governance votings.
            </Tooltip>
        );

        const tooltip3 = props => (
            <Tooltip {...props}>
            Balance of liquid (i.e. not locked) TWIN available for trading, providing liquidity, a.o.
            </Tooltip>
        );
        
        
        
        return ( 
            
            <div className="container-fluid w-100">
                <Modal show={this.state.IDTModalOpen} onHide={this.closeIDTModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Convert your TWIND tokens into veTWIN</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="yearsToLock">Number of years:</label>
                            </div>
                            <select className="form-control border" id='yearsToLock' onChange={this.calculateVeISS}>
                                <option vale="0" selected disabled hidden>Choose here</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        {this.state.showIDTCaluclation
                        ?
                        <div>
                            <div>
                                You will receive {this.props.outputNumber(this.state.lockedISSBalanceProjection/1e18,0)} TWIN tokens, which are locked for {this.state.yearsToLock} years. 
                                Your initial veTWIN Balance will amount to appr. {this.props.outputNumber(this.state.lockedISSBalanceProjection * this.state.yearsToLock / 4 / 1e18,0)} veTWIN.
                            </div>
                            <div className='pt-3'>This conversion is permanent and cannot be reverted. Thus please take your time and chose wisely.</div>
                        </div>
                        :
                        ''
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    {this.state.showIDTCaluclation
                        ?
                        <TransactionButton
                            contractAddress={this.props.VotingEscrow._address}
                            abi = {this.props.VotingEscrow_ABI}
                            functionName= 'createIDTLock'
                            args =  {[this.state.yearsToLock]} // Example, adjust as needed
                            text="Convert"
                            onSuccess={this.onSuccessConvert}
                        />
                        :
                        ''
                    }
                    </Modal.Footer>
                </Modal>
    
                    <div className='mainContainer'>
                        <div className="mainbox">
                           
                                
                                    <div className="subBox subBoxTWIND">
                                        
                                        <div className="TWINDFirstRow">
                                            1 TWIND token can be exchanged for up to 1 locked TWIN token. The conversion ratio depends on your lock period. 
                                        </div>
                                        <div className="TWINDLastRowWhite">
                                            If you decide to lock your tokens for the max period of 4 years, the ratio will be 1:1. if you lock for only 1 year, the conversion ratio will be 4:1.
                                        </div>
                                    </div>
                                    
                                    <div className="subBox subBoxTWIND">
                                        <div className="TWINDFirstRowGreen">
                                            <div className="">Your total TWIND balance:</div>
                                            <div className="">{this.props.outputNumber((parseInt(this.props.IDTBalanceWei)/1e18),0)}</div>                                             
                                        </div>
                                        {(this.props.IDTBalanceWei > 0 & this.state.VotingEscrowApproved === false
                                            ?
                                            <div className="TWINDButtonRow">
                                                <TransactionButton
                                                    contractAddress={this.props.GovernanceToken._address}
                                                    abi = {this.props.ERC20_ABI}
                                                    functionName= 'approve'
                                                    args =  {[this.props.VotingEscrow._address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                    text="Approve TWIN"
                                                    onSuccess={this.onSuccessApprove}
                                                />
                                            </div>
                                            :
                                            ''
                                        )}
                                        {(this.props.IDTBalanceWei > 0 & this.state.VotingEscrowApprovedIDT === false
                                            ?
                                            <div className="TWINDButtonRow">
                                                <TransactionButton
                                                    contractAddress={this.props.IDT._address}
                                                    abi = {this.props.ERC20_ABI}
                                                    functionName= 'approve'
                                                    args =  {[this.props.VotingEscrow._address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                    text="Approve TWIND"
                                                    onSuccess={this.onSuccessApprove}
                                                />
                                            </div>
                                            :
                                            ''
                                        )} 

                                        {(this.props.IDTBalanceWei > 0 & this.state.VotingEscrowApproved & this.state.VotingEscrowApprovedIDT
                                            ?
                                            <div className="TWINDButtonRowLast">
                                                <div role="button" className="w-100 issuaaButton" onClick={this.openIDTModal}>Convert {this.props.outputNumber(parseInt(this.props.IDTBalanceWei)/1e18,0)} TWIND into veTWIN</div>
                                            </div>
                                            :
                                            ''
                                        )}
                                        {(this.props.IDTBalanceWei > 0 & (this.state.VotingEscrowApproved === false || this.state.VotingEscrowApprovedIDT === false)
                                            ?
                                            <div className="TWINDButtonRowLast">
                                                <div disabled className="w-100 issuaaButtonDeactivated" onClick={this.openIDTModal}>Convert {this.props.outputNumber(parseInt(this.props.IDTBalanceWei)/1e18,0)} TWIND into veTWIN</div>
                                            </div>
                                            :
                                            ''
                                        )}         
                                    </div>
                                    

                                    <div className="subBox subBoxTWIND">
                                        <div className="TWINDFirstRowGreen">
                                            <div className="">Your total TWIN balance:</div>
                                            <div className="">{this.props.outputNumber((parseFloat(this.props.GovernanceTokenTotalBalanceAllChains)),0)}</div>                                             
                                        </div>
                                        <div className="TWINDMiddleRow">
                                            <div className="">
                                                thereof locked
                                            </div>
                                            <div className="">{this.props.outputNumber(this.props.GovernanceTokenStakeBalance,0)}</div>                                             
                                        </div>
                                        

                                        <div className="TWINDMiddleRow">
                                            <div className="">
                                                thereof liquid
                                            </div>
                                            <div className="">{this.props.outputNumber(this.props.GovernanceTokenTotalBalance - this.props.GovernanceTokenStakeBalance ,0)}</div>                                             
                                        </div>
                                    </div>
                                    
                                    <div className="subBox subBoxTWIND">
                                        <div className="TWINDFirstRowGreen">
                                            <div className="">Your veTWIN balance:</div>
                                            <div className="">{this.props.outputNumber(parseFloat(this.props.veISSBalance),0)}</div>                                             
                                        </div>
                                        <div className="TWINDMiddleRow">
                                            <div className="">
                                                Current lock ends on 
                                            </div>
                                            <div className="">
                                                {(this.props.lockDate   > this.state.now
                                                    ?
                                                    this.props.timeStampToDate(this.props.lockDate,0)
                                                    :
                                                    'n.a.'
                                                )}
                                            </div>                                             
                                        </div>
                                        <div className="TWINDLastRow">
                                            <div className="">Total veTWIN supply:</div>
                                            <div className="">{this.props.outputNumber(parseFloat(this.props.totalVeISSSupply),0)}</div>                                             
                                        </div>
                                    </div>                                
                                
                            
                            
                        </div>
                    </div>
                    
               
            </div>

            
         );
    }
}
 
export default IDTConversion;