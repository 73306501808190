function timeStampToDate(_timestamp) {
  let timestamp = parseInt(_timestamp)
  const date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric' };
  let formattedDate = date.toLocaleDateString(options);
  return(formattedDate);
  
};


function timeStampToDateAndTime(_timestamp) {
  let timestamp = parseInt(_timestamp)
  var date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric'}
  let formattedDate = date.toLocaleDateString(options)
  const optionsTime = {hour: '2-digit', minute: '2-digit'}
  let formattedTime
  formattedTime = date.toLocaleTimeString([],optionsTime)
  return(formattedDate+" "+formattedTime)
};

function outputNumber(number, digits){
  number = parseFloat(number) * (10**digits)
  number = Math.round(number) / (10**digits)
  let output = number.toLocaleString('en-US',{minimumFractionDigits: digits})
  return (output)
}

async function sleep(milliseconds) {
return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const roundDown = (n,d) => {
  n = Math.floor(n*(10**d))
  n = n/(10**d)
  return n
}

export {timeStampToDate, outputNumber, timeStampToDateAndTime,sleep,roundDown};
