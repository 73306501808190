import '../styles/navbar.css';
import React from 'react';
import ConnectButton from './ConnectButton';



const Navbar = (props) => {

    return (
        <div className="navbar">
          <div className="navbarHeadline">{props.activePage}</div>
          
          <div className='connectButton'>
            <ConnectButton />
          </div>
          
        </div>
    )
  }
  export default Navbar;