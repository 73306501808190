import Slide from '@successtar/react-reveal/Slide';
import {Component} from 'react';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

class MessageBox extends Component{







  
  constructor(props) {
    super(props);
    this.state = {  
      
    }
  }

  render(){
    return (
        <Slide right>
          <div className="messageBox">
            <div className='messageBoxHeader'>
              <div>{this.props.header}</div>
              <div className="spinner-border spinner-border-sm spinner-border-slow m-3" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div className='messageBoxBody'>
              {this.props.content}
            </div>
            <div className='messageBoxFooter'>
              {this.props.footer}
            </div>
            
              
            
          </div>
        </Slide>
    )
  } 
}

export default MessageBox;
